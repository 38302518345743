import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

import { sendPOSTRequest } from '../../adaptors/bff-requests';

const useStyles = makeStyles({
  background: {
    backgroundColor: '#EEE',
  },
});

const InformationCenter = () => {
  const classes = useStyles();

  const [visitors, setVisitors] = useState({});
  const [vehicles, setVehicles] = useState([]);

  const fetchPricingDetails = async () => {
    const visitorsResp = await sendPOSTRequest({ path: 'visitor/get-types' });

    if (visitorsResp && visitorsResp.data) {
      const visitorsObj = visitorsResp.data.reduce((acc, curr) => {
        const name = curr.type.replace('-', '');
        acc[name] = curr.cost;
        return acc;
      }, {});
      setVisitors(visitorsObj);
    }

    const vehicleResp = await sendPOSTRequest({ path: 'vehicle/get' });
    setVehicles(vehicleResp ? vehicleResp.data || [] : []);
  };

  useEffect(() => {
    fetchPricingDetails();
  }, []);

  return (
    <Box px="8vw" py="4rem" w="60%" className={classes.background}>
      <Typography
        variant="h2"
        sx={{
          fontSize: '4vh',
          fontFamily: 'revert',
          textAlign: 'center',
        }}
      >
        Information Center
      </Typography>
      <Typography
        variant="h4"
        sx={{
          fontSize: '3vh',
          fontFamily: 'revert',
          paddingTop: '4rem',
          textAlign: 'center',
        }}
      >
        Ticket Price per Person (LKR)
      </Typography>

      <TableContainer component={Paper} sx={{ marginTop: '2rem' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center" colSpan={2}>
                Locals
              </TableCell>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center" colSpan={2}>
                Tourists
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center">
                Kid
              </TableCell>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center">
                Adult
              </TableCell>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center">
                Kid
              </TableCell>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center">
                Adult
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center">
                {visitors.localkids}
              </TableCell>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center">
                {visitors.localadults}
              </TableCell>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center">
                {visitors.touristkids}
              </TableCell>
              <TableCell sx={{ fontSize: '1.5vh' }} align="center">
                {visitors.touristadults}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Typography
        variant="h4"
        sx={{
          fontSize: '3vh',
          fontFamily: 'revert',
          paddingTop: '4rem',
          textAlign: 'center',
        }}
      >
        Ticket Price per Vehicle (LKR)
      </Typography>

      <TableContainer component={Paper} sx={{ marginTop: '2rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              {vehicles.map((vehicle) => (
                <TableCell
                  sx={{ fontSize: '1.5vh', width: '20rem' }}
                  align="center"
                  key={`vtype-${vehicle.type}`}
                >
                  {vehicle.type}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              {vehicles.map((vehicle) => (
                <TableCell
                  sx={{ fontSize: '1.5vh', width: '20rem' }}
                  align="center"
                  key={`vtype-cost-${vehicle.type}`}
                >
                  {vehicle.cost}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export { InformationCenter };
