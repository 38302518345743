import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuth } from '../../../../context';

export default function TicketTableData(props) {
  const { token, authorizedUser } = useAuth();

  const { ticketList, rowCount, pageNum, isLoading, allowedActions } = props;

  const numColumnProps = { align: 'right', headerAlign: 'right' };

  const columns = [
    { field: 'reservationId', headerName: '#reservation Id', flex: 1, ...numColumnProps },
    { field: 'amount', headerName: 'Amount (LKR)', flex: 1, ...numColumnProps },
    { field: 'confirmationCode', headerName: 'Confirmation Code', flex: 1 },
    { field: 'reservationType', headerName: 'Type', flex: 1 },
    { field: 'paymentStatus', headerName: 'Payment Status', flex: 1 },
    { field: 'reservedDate', headerName: 'Reservation Date', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <>
          {allowedActions['view-sales-info'] && (
              <Tooltip title="View">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => props.onVisibleButtonClick(params.value)}
                >
                  <VisibilityIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {allowedActions['delete-ticket'] && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => props.onDeleteButtonClick(params.value)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const formatDate = (date) => {
    const mm = String(date.getMonth() + 1).padStart(2, '0'); 
    const dd = String(date.getDate()).padStart(2, '0'); 
    const yyyy = date.getFullYear(); 
    return `${mm}/${dd}/${yyyy}`; 
  };

  const getRows = () => {
    let fltTicketList = [];
    if (authorizedUser['custom:role'] == 'Guide') {
      fltTicketList = ticketList.filter(
        (rec) => rec.issuername == `${authorizedUser.given_name} ${authorizedUser.family_name}`
      );
    } else fltTicketList = ticketList;
    const rows = fltTicketList.map((e, i) => {
      const date = new Date(e.reservedDate);
      return {
        id: e.reservationId,
        reservationId: e.reservationId,
        confirmationCode: e.confirmationCode,
        amount: e.amount,
        reservationType: e.reservationType,
        paymentStatus: e.paymentStatus,
        reservedDate: formatDate(date),
        action: e,
      };
    });
    return rows;
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        marginTop: '10px',
      }}
    >
      <DataGrid
        style={{ color: 'green', fontSize: '12px'}}
        components={{ LoadingOverlay: LinearProgress }}
        getRowClassName={(params) => {
          return params.row.paymentStatus === 'pending' ? 'tablePendingRow' : '';
        }}
        loading={isLoading}
        rows={getRows()}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCount}
        page={pageNum}
        pagination={true}
        onPageChange={(e) => {
          props.onPageChangeHandler(e);
        }}
        onSortModelChange={(m, d) => {
          props.onSortChangeHandler(m, d);
        }}
      />
    </div>
  );
}
