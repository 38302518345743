import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';

const useStyles = makeStyles(() => ({
  columnItem: {
    flexGrow: 1,
    minWidth: 300,
    padding: 8,
    width: 'max(300px, 18vw)',
  },
  socialIcon: {
    marginRight: 24,
  },
}));

const iconStyle = {
  fontSize: 32,
};

const Footer = (props) => {
  const classes = useStyles();
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#252525',
        color: '#FFF',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          padding: '4vw',
        }}
      >
        <Box className={classes.columnItem}>
          <Typography variant="h6">Ambuluwawa</Typography>
          <Box my={2}>
            <Typography variant="caption">
              We know the climb to the top is a bit tough but then again you know hardest climbs
              always gives the best views. Some of the best views you can admire from this tower is,
              from South Sri Pada or Adams Peak, North Mountain range of Knuckles, West Bathalegala
              or Bible Rock, East Piduruthalagala Mountain
            </Typography>
          </Box>
          <Box my={2}>
            <TwitterIcon className={classes.socialIcon} sx={iconStyle}></TwitterIcon>
            <FacebookIcon className={classes.socialIcon} sx={iconStyle}></FacebookIcon>
            <LinkedInIcon className={classes.socialIcon} sx={iconStyle}></LinkedInIcon>
          </Box>
        </Box>
        <Box className={classes.columnItem}></Box>
        <Box className={classes.columnItem}>
          <Typography variant="h6">I’m a Tour guide</Typography>
          <Box my={2}>
            <Link
              component={RouterLink}
              to="/register-tour-guide"
              rel="noopener noreferrer"
              style={{ color: '#FFF' }}
            >
              Register as a Tour Guide
            </Link>
          </Box>
          <Box my={2}>
            <Link
              href="https://ui.ambuluwawa.com/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#FFF' }}
            >
              Agreement and Policies
            </Link>
          </Box>
        </Box>
        <Box className={classes.columnItem}>
          <Typography variant="h6">Useful Links</Typography>
          <Box my={2}>
            <Link
              href="https://siba.edu.lk/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#FFF' }}
            >
              Accadamy (SIBA)
            </Link>
          </Box>
          <Box my={2}>
            <Link
              href="https://ibm.sridaladamaligawa.lk/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#FFF' }}
            >
              International Buddhist Museum
            </Link>
          </Box>
          <Box my={2}>
            <Link
              href="https://sridaladamaligawa.lk/athgala/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#FFF' }}
            >
              Elephant Home (Athgala)
            </Link>
          </Box>
          <Box my={2}>
            <Link
              href="https://sridaladamaligawa.lk/daladawaruna/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#FFF' }}
            >
              Press (Dalada Waruna)
            </Link>
          </Box>
        </Box>
      </Box>
      <Box p={2}>
        <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
          2023 All Right Reserved
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
