import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

export default function ConfirmModal({ open, handleClose, item, handleConfirmation }) {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Update</DialogTitle>
      <DialogContent>
        <p>Are you sure you want to update the following item with cost {item?.cost}?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirmation}
          style={{
            backgroundColor: '#1E3932',
            color: 'white',
            padding: '8px 16px',
            borderRadius: '10px',
            cursor: 'pointer',
            border: 'none',
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
