import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  item: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    outline: 0,
  },
  itemImg: {
    height: '16vh',
    width: '16vh',
    borderRadius: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  heading: {
    fontFamily: 'fantasy',
    fontSize: '80vh',
  },
});

const list = [
  {
    img: 'https://i.pinimg.com/564x/26/5f/72/265f7225cca16402642238aa9b661a16.jpg',
    name: 'Lorem ipsum',
    title: 'Founder & CEO',
  },
  {
    img: 'https://i.pinimg.com/564x/26/5f/72/265f7225cca16402642238aa9b661a16.jpg ',
    name: 'Lorem ipsum',
    title: 'Founder & CEO',
  },
  {
    img: 'https://i.pinimg.com/564x/26/5f/72/265f7225cca16402642238aa9b661a16.jpg',
    name: 'Lorem ipsum',
    title: 'Founder & CEO',
  },
  {
    img: 'https://i.pinimg.com/564x/26/5f/72/265f7225cca16402642238aa9b661a16.jpg',
    name: 'Lorem ipsum',
    title: 'Founder & CEO',
  },
  {
    img: 'https://i.pinimg.com/564x/26/5f/72/265f7225cca16402642238aa9b661a16.jpg',
    name: 'Lorem ipsum',
    title: 'Founder & CEO',
  },
  {
    img: 'https://i.pinimg.com/564x/26/5f/72/265f7225cca16402642238aa9b661a16.jpg',
    name: 'Lorem ipsum',
    title: 'Founder & CEO',
  },
];

const BoardMembers = () => {
  const classes = useStyles();
  return (
    <Box pt="6rem">
      <Typography variant="h2" align="center" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
        Meet Our Board Memebers
      </Typography>

      <Box pt="4rem">
        <Grid container rowSpacing={8} columnSpacing={4}>
          {list.map((item) => (
            <Grid item key={item.key} xs={6} sm={6} md={4}>
              <Box className={classes.item}>
                <img className={classes.itemImg} alt={item.name} src={item.img} />

                <Box sx={{ flexGrow: 1 }}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h2"
                    sx={{
                      fontSize: '2vh',
                      fontFamily: 'revert',
                      marginTop: '2vw',
                      textAlign: 'center',
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1.5vh',
                      fontFamily: 'revert',
                      marginTop: '1vw',
                      textAlign: 'center',
                    }}
                  >
                    {item.title}
                  </Typography>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export { BoardMembers };
