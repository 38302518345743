import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { CustomizedAxisTick } from './custom-shared';

const defaultOptions = {
  responsive: {
    width: '100%',
    height: '100%',
  },
  lineChart: {
    width: 500,
    height: 300,
    margin: {
      top: 5,
      right: 8,
      left: 8,
      bottom: 5,
    },
  },
};

export default function CustomLineChart({
  data,
  xaxisKey,
  lines,
  lineChart = defaultOptions.lineChart,
  responsive = defaultOptions.responsive,
  customToolTip,
  legendPosition = 'top',
  showVerticalTick = false,
}) {
  return (
    <ResponsiveContainer width={responsive.width} height={responsive.height}>
      <LineChart
        width={lineChart.width}
        height={lineChart.height}
        data={data}
        margin={lineChart.margin}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xaxisKey}
          tick={showVerticalTick ? <CustomizedAxisTick /> : true}
          height={showVerticalTick ? 80 : undefined}
        />
        <YAxis />
        {customToolTip ? <Tooltip content={(options) => customToolTip(options)} /> : <Tooltip />}
        <Legend verticalAlign={legendPosition} />
        {(lines || []).map((line) => {
          return (
            <Line
              key={`key-${line.key}`}
              type="monotone"
              dataKey={line.key}
              stroke={line.color || '#8884d8'}
              activeDot={{ r: 8 }}
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
}
