import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress, IconButton, Switch } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useAuth } from '../../../../context';

export default function UserTable(props) {
  const {
    authorizedUser: { authKey },
  } = useAuth();

  const { userList, isLoading, allowedActions } = props;

  const numColumnProps = { align: 'right', headerAlign: 'right' };

  const columns = [
    { field: 'id', headerName: '#UserNo', ...numColumnProps },
    { field: 'firstName', headerName: 'Firstname', flex: 1 },
    { field: 'lastName', headerName: 'Lastname', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'identification', headerName: 'Identification', flex: 1 },
    { field: 'position', headerName: 'Position', flex: 1 },
    { field: 'role', headerName: 'Permission', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        const isSameUser = params.value.authkey === authKey;
        return (
          <>
            {allowedActions['update-user'] && (
              <Tooltip title="View/Update">
                <IconButton
                  aria-label="update"
                  size="small"
                  onClick={() => props.onUpdateButtonClick(params.value)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {allowedActions['delete-user'] && !isSameUser && (
              <Tooltip title={params.value.isactive ? 'Active' : 'In-Active'}>
                <Switch
                  size="small"
                  checked={params.value.isactive}
                  onChange={(e) => {
                    props.onToggleSwitchClick(params.value);
                  }}
                />
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const getRows = () => {
    const rows = userList.map((e, i) => {
      return {
        id: e.id,
        firstName: e.firstname,
        lastName: e.lastname,
        email: e.email,
        identification: e.identification,
        position: e.position,
        role: e.role,
        action: e,
      };
    });
    return rows;
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        marginTop: '10px',
      }}
    >
      <DataGrid
        style={{ color: 'green', fontSize: '12px' }}
        components={{ LoadingOverlay: LinearProgress }}
        loading={isLoading}
        rows={getRows()}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
      />
    </div>
  );
}
