import { Box, Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

const useStyles = makeStyles({
  main: {
    marginTop: '4rem',
  },
  imgs: {
    width: '100%',
    height: '100%',
  },
});

const list = [
  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'Samadhi Statue',
    desc: [
      'Ambuluawa is a unique place of construction within the Biodiversity Complex. The main purpose of constructing this unusual shaped building was to display a culturally valuable artwork, to educate school  children  about  environmental  conservation,  geographically  specific  issues,  and  to  provide space for holding conferences.',
      'At the time when the ethnic problems were severe in Sri Lanka, with the desire to eliminate the violent situation from the country, this stone Buddha was exhibited throughout Sri Lanka and was brought to Ambuluawa, pointing towards the northern direction and placed in this place. Devotees say that this samadhi statue is more powerful.',
      '(With the aim of establishing peace and prosperity in Sri Lanka, this statue of Samadhi was started to  establish  10  days  after  the  beginning  of  the  brutal  war  period,  and  the  brutal  war  in  Sri  Lanka ended within 08 days after the creation of the statue of Samadhi is the uniqueness of this statue of Samadhi. Also, the financial support for the creation and annual maintenance of the Samadhi statue placed in the Ambuluawa complex is done by Mr. J.M.S Bandara, a member of the Ambuluawa Board of Trustees.)',
    ],
  },
  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'Saman Devalaya',
    desc: [
      'There  is  an  infinite  connection  with  the  symbols  of  Sri  Saman  Divyaraja  (God),  Siripatula, Samanalakanda and Ambuluava. The tallest Saman Deva statue in Ceylon has been built in this place, facing the Samanala Mountain.',
      'This Saman statue, the tallest in Sri Lanka, was created with the financial donation of Mr. S.H.D. Bandula Karunaratne, the head of Art Decoration Group.',
    ],
  },
  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'Wishnu Devalaya',
    desc: [
      'The highest Vishnu Deva statue in Sri Lanka has been created for the belief, respect, and acceptance of deities in the Sri Lankan Buddhist community as a whole in the Ambuluawa campus, which was an all-religious center.',
      'In November 2017, it was started by the former Prime Minister, the founder of Ambuluawa religious center, Mr. D. Mu Jayaratne, but after his death, under the guidance of the present chairman of the Ambuluawa  complex,  Mr.  Anuradha  Jayaratne,  the  Honorable  State  Minister,  it  was  completed simultaneously with the dedication of the founder of Ambuluawa, Mr. D. Mu Jayaratne and opened on February 19, 2020.',
    ],
  },

  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'Jaya Wewa',
    desc: [
      'The lower lake embankment was built to a height of 45.0 feet in connection with the springs flowing through the natural forest above this lake and arrangements were made to collect a large amount of water. This provides water for paddy cultivation as well as other agricultural crops especially in the agricultural areas of Lower Galpaya, Velakanda, Godagama etc. Also, the springs will help the water consumption of the local people a lot.',
      'This Jaya Lake located in the Ambuluawa complex was completed in 2001 and 19/11/2020  along with the Ambuluawa renovation works, the helicopter yard, walkway around the lake etc. were created and decorated.',
    ],
  },

  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'Ambuluwawa Wewa',
    desc: [
      'A small tank built near the main water source that supplied water for consumption to the residents of Alugolla and Lantonhill colonies of Pahala Galpaya Grama Niladhari Division of Ganga Ihala Koralaya. This water project, which was started in 1968, is inoperable during periods of drought. But after the construction of this lake, it is special that there is no shortage of water for the concerned colonists at any time.',
      '(This lake was completed in 2001)',
    ],
  },
  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'Twin Ponds',
    desc: [
      'It is a unique creation that was introduced to Sri Lanka as well as the entire world community in the 20th  century.  Using  current  technology  and  relying  only  on  natural  rain  water,  this  design  will  be  great help to emerging historians.',
    ],
  },
  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'View Point',
    desc: [
      'The building is constructed in such a way that visitors to the Ambuluawa Centre, the only isolated mountain in the Central Highlands, can view the geographical charm of the Central Highlands.',
      'The building is known as the observation studio because the  Piduruthalagala mountain as well as Kikilaya Mahakanda from the east direction, Haguranketha range and Deltota mountain range which is  one  of  the  boundaries  of  the  Central  Province  from  the  north-east,    Hantana  mountain  and Knuckles mountain range from the north, Alagalla mountain and Hunnasgiri mountain range from the  north-west,  Utuwankanda  from  the  north-west,  Bathalegala  mountain  from  the  west  and  the Daraniyagala mountain range are visible from the south side.',
    ],
  },
  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'Summer Huts',
    desc: [
      'From the entrance road, from the third bend to near the tower, 08 rest houses of different sizes and shapes have been constructed. As most of the visitors who come to Ambulua, climb the mountain on foot,  these  rest  houses  have  been  built  to  stop  and  relieve  fatigue  especially  in  cases  of  heavy sunshine  and to protect from the rain.',
    ],
  },
  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'Helipad',
    desc: [
      'There will be a large influx of foreign tourists for upcoming projects in Ambuluawa. This helicopter yard was built for the convenience of the tourists who come and the lake is located nearby, which is a very beautiful sight. Helicopters have been landing at this place since the beginning of Ambuluawa.',
    ],
  },
  {
    img: [
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
      'https://ambuluwawa.com/images/gallery2.jpg',
    ],
    name: 'Childrens Park',
    desc: [
      "Along with the large number of  people who come to visit Ambuluwa, there are many children. For those children, this children's garden and many unique sports items have been established in this.",
    ],
  },
];

const AttractionsDetails = () => {
  const classes = useStyles();
  const params = useParams();
  const place = list.filter((p) => p.name.includes(params.place));
  console.log(place[0].name);
  return (
    <>
      {/* {place.map((p) => (
        <Box px="8vw" py="4rem">
          <Typography
            variant="h2"
            sx={{ fontSize: '4vh', fontFamily: 'revert', textAlign: 'center' }}
          >
            {p.name}
          </Typography>
          <Grid container spacing={4} pt="4em">
            <Grid item xs={12} sm={12} md={8}>
              <Carousel>
                {p.img.map((i) => (
                  <img className={classes.imgs} src={i} alt={p.name} loading="lazy" />
                ))}
              </Carousel>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              {p.desc.map((d) => (
                <Typography
                  sx={{
                    fontSize: '1.8vh',
                    fontFamily: 'revert',
                    fontWeight: '400',
                    textAlign: 'justify',
                    marginBottom: '1.5rem',
                  }}
                  my="auto"
                >
                  {d}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Box>
      ))} */}
      {place.map((p) => (
        <Box px="8vw" py="4rem" w="60%">
          <Typography
            variant="h2"
            sx={{
              fontSize: '4vh',
              fontFamily: 'revert',
              paddingTop: '4rem',
              paddingBottom: '2rem',
              textAlign: 'center',
            }}
          >
            {p.name}
          </Typography>
          <Divider
            sx={{
              marginBottom: '4rem',
            }}
          />
          {p.desc.map((d) => (
            <Typography
              sx={{
                fontSize: '2vh',
                fontFamily: 'revert',
                fontWeight: '400',
                textAlign: 'justify',
                marginBottom: '2rem',
              }}
            >
              {d}
            </Typography>
          ))}
          <Divider
            sx={{
              marginTop: '4rem',
              marginBottom: '8rem',
            }}
          />
        </Box>
      ))}
    </>
  );
};

export { AttractionsDetails };
