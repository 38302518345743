import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import styles from '../style.css';
import { LinearProgress, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useAuth } from '../../../../context';

export default function TicketTableData(props) {
  const { token, authorizedUser } = useAuth();

  const { ticketList, rowCount, pageNum, isLoading, allowedActions } = props;

  const numColumnProps = { align: 'right', headerAlign: 'right' };

  const columns = [
    { field: 'serialnumber', headerName: '#Serial', flex: 1, ...numColumnProps },
    { field: 'amount', headerName: 'Amount (LKR)', flex: 1, ...numColumnProps },
    { field: 'tickettype', headerName: 'Type', flex: 1 },
    { field: 'paymentstatus', headerName: 'Payment Status', flex: 1 },
    { field: 'guidename', headerName: 'Guide', flex: 1 },
    { field: 'issueddate', headerName: 'Issued On', flex: 1 },
    { field: 'issuername', headerName: 'Issued By', flex: 1 },
    {
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <>
            {allowedActions['view-ticket'] && (
              <Tooltip title="View/Edit">
                <IconButton
                  aria-label="view/edit"
                  size="small"
                  onClick={() => props.onViewEditClickHandler(params.value)}
                  disabled={isLoading}
                >
                  {params.value.paymentstatus === 'pending' && !props.isGuide ? (
                    <EditIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
            )}
            {allowedActions['delete-ticket'] && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => props.onDeleteButtonClick(params.value)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const getRows = () => {
    let fltTicketList = [];
    // if (authorizedUser['custom:role'] == 'Guide') {
    //   fltTicketList = ticketList.filter(
    //     (rec) => rec.issuername == `${authorizedUser.given_name} ${authorizedUser.family_name}`
    //   );
    // } else 
    fltTicketList = ticketList;
    const rows = fltTicketList.map((e, i) => {
      const date = new Date(e.createdat);
      return {
        id: e.ticketid,
        serialnumber: e.serialnumber,
        amount: e.amount,
        tickettype: e.tickettype,
        paymentstatus: e.paymentstatus,
        guidename: e.guidename,
        issueddate: date.toLocaleDateString('en-US') + ' - ' + date.toLocaleTimeString('en-US'),
        issuername: e.issuername,
        action: e,
      };
    });
    return rows;
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        marginTop: '10px',
      }}
    >
      <DataGrid
        style={{ color: 'green', fontSize: '12px' }}
        components={{ LoadingOverlay: LinearProgress }}
        getRowClassName={(params) => {
          return params.row.paymentstatus === 'pending' ? 'tablePendingRow' : '';
        }}
        loading={isLoading}
        rows={getRows()}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        disableColumnFilter
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCount}
        page={pageNum}
        pagination={true}
        onPageChange={(e) => {
          props.onPageChangeHandler(e);
        }}
        onSortModelChange={(m, d) => {
          props.onSortChangeHandler(m, d);
        }}
      />
    </div>
  );
}
