import React, { useState } from 'react';
import { Button, TextField, Paper, Typography } from '@mui/material';
import PrintComponent from '../../Admin/analytics/shared/printGenerator';
import '../DetailsTicketingIP.css';

const DetailsTicketingIP = ({ changeShow, ticket }) => {
  console.log('this is ticket', ticket);
  const { ticketDetails, vehicleDetails, visitorDetails } = ticket;

  return (
    <div className='details-container' id='printTicketIP'>
      <div className='details-row'>
        <Paper elevation={3} className='details-paper'>
          <Typography variant='h5' gutterBottom>
            Ticket Details
          </Typography>
          <div className='details-field'>
            <Typography variant='subtitle1'>Balance:</Typography>
            <TextField
              variant='outlined'
              size='small'
              value={ticketDetails.amount}
              disabled
              fullWidth
            />
          </div>
          <div className='details-field'>
            <Typography variant='subtitle1'>Ticket ID:</Typography>
            <TextField
              variant='outlined'
              size='small'
              value={ticketDetails.ticketId}
              disabled
              fullWidth
            />
          </div>
          {/* Add more ticket details as needed */}
        </Paper>
      </div>

      {vehicleDetails?.length>0  && <div className='details-row'>
        <Paper elevation={3} className='details-paper'>
          <Typography variant='h5' gutterBottom>
            Vehicle Details
          </Typography>
          {vehicleDetails?.length>0 && vehicleDetails.map((vehicle, index) => (
            <div key={index} className='details-field'>
              <Typography variant='subtitle1'>
                {vehicle.name}:
              </Typography>
              <TextField
                variant='outlined'
                size='small'
                value={` Count: ${vehicle.count}, Total Cost: ${vehicle.totalCost}`}
                disabled
                fullWidth
              />
            </div>
          ))}
        </Paper>
      </div> }

      <div className='details-row'>
        <Paper elevation={3} className='details-paper'>
          <Typography variant='h5' gutterBottom>
            Visitor Details
          </Typography>
          {visitorDetails.map((visitor, index) => (
            <div key={index} className='details-field'>
              <Typography variant='subtitle1'>
              {visitor.visitorType}:
              </Typography>
              <TextField
                variant='outlined'
                size='small'
                value={` Count: ${visitor.count}, Total Cost: ${visitor.totalCost}`}
                disabled
                fullWidth
              />
            </div>
          ))}
        </Paper>
      </div>

      <div className='buttons-container'>
        <Button variant='contained' onClick={() => changeShow()}>
          Back
        </Button>
        <PrintComponent divId='printTicketIP' />
      </div>
    </div>
  );
};

export { DetailsTicketingIP };