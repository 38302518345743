import React, { useState } from 'react';
import { Button, Icon, Input, InputLabel, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useAuth } from '../../../context';



export default function Event () {
  const [uploadedImage, setUploadedImage] = useState(null);
  const { token } = useAuth();
  const [eventsPerYear, setEventsPerYear] = useState(0);
  const currentYear = new Date().getFullYear();
  const [errors, setErrors] = useState({});
  const [formFields, setFormFields] = useState({
    name: '',
    contact_no: '',
    publish_date: '',
    tender_opening_date: '',
    tender_opening_time: '',
    tender_closing_date: '',
    tender_closing_time: '',
    further_information: '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`${name}: ${value}`); 
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const validateForm = () => {
    let tempErrors = {};
    let isValid = true;
    for (const [key, value] of Object.entries(formFields)) {
      if (!value.trim()) {
        tempErrors[key] = 'This field is required';
        isValid = false;
      } else {
        tempErrors[key] = '';
      }
    }
    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Entered values:", formFields);

    const payload = {
      name: formFields.name,
      contact_no: formFields.contact_no,
      publish_date: formFields.publish_date,
      tender_opening_date: formFields.tender_opening_date,
      tender_opening_time: formFields.tender_opening_time,
      tender_closing_date: formFields.tender_closing_date,
      tender_closing_time: formFields.tender_closing_time,
      further_information: formFields.further_information,
    };

    try {
      const response = await sendPOSTRequest({ path: 'announcement-type2/new', payload }, token);
      if (response.status === 'success') {
        Swal.fire('Success', 'Announcement added successfully', 'success');
        setFormFields({
          name: '',
          contact_no: '',
          publish_date: '',
          tender_opening_date: '',
          tender_opening_time: '',
          tender_closing_date: '',
          tender_closing_time: '',
          further_information: '',
        });
        setErrors({});
      } else {
        Swal.fire('Error', 'There was an issue adding the announcement', 'error');
      }
    } catch (err) {
      console.error('Error submitting form', err);
      Swal.fire('Error', 'There was an issue adding the announcement', 'error');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
      <div style={{ marginTop: '20px' }}>
          <TextField
            label="Name"
            name="name"
            value={formFields.name}
            onChange={handleInputChange}
            fullWidth
            required
            error={!!errors.name}
            helperText={errors.name}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
        <TextField
            label="Contract No"
            name="contact_no"
            value={formFields.contact_no}
            onChange={handleInputChange}
            fullWidth
            required
            error={!!errors.contact_no}
            helperText={errors.contact_no}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
        <TextField
            label="Publish Date"
            name="publish_date"
            type="date"
            value={formFields.publish_date}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
            error={!!errors.publish_date}
            helperText={errors.publish_date}
          />
        </div>
        <div style={{ marginTop: '25px' }}>
          <TextField
            label="Tender Opening Date"
            name="tender_opening_date"
            type="date"
            value={formFields.tender_opening_date}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
            error={!!errors.tender_opening_date}
            helperText={errors.tender_opening_date}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="Tender Opening Time"
            name="tender_opening_time"
            type="time"
            value={formFields.tender_opening_time}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
            error={!!errors.tender_opening_time}
            helperText={errors.tender_opening_time}
          />
        </div>
        <div style={{ marginTop: '25px' }}>
        <TextField
            label="Tender Closing Date"
            name="tender_closing_date"
            type="date"
            value={formFields.tender_closing_date}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
            error={!!errors.tender_closing_date}
            helperText={errors.tender_closing_date}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
        <TextField
            label="Tender Closing Time"
            name="tender_closing_time"
            type="time"
            value={formFields.tender_closing_time}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
            error={!!errors.tender_closing_time}
            helperText={errors.tender_closing_time}
          />
        </div>
      
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="Further Information"
            name="further_information"
            value={formFields.further_information}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            required
            error={!!errors.further_information}
            helperText={errors.further_information}
          />
        </div>
        {/* <Button variant='contained' type="submit" style={{ width: '10em', padding: '8px 0', fontSize: '1em', marginTop:'20px'}}>
      Add News
    </Button> */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            type="submit"
            style={{ width: '10em', padding: '8px 0', fontSize: '1em', marginTop: '20px' }}
           
          >
            Add Announcement
          </Button>
        </div>
      </form>
    </div>
  );
}

