import React, { useState,useEffect } from 'react';
const RegistrationDetails=({registrationDetails})=> {

  const[ticketInfo,setTicketInfoValue]=useState(registrationDetails.ticketDetails);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: '100px',
      height: '100%',
      width: '100%',
    }}>
    <form id='payatgatephotores' className="reservation-form" >
    <div className="reservation-form__left">
        <label>
        Reservation Id :
          <input type="text" value={ticketInfo?.ticketId} readOnly />
        </label>
        <br />
        <label>
          Confirmation Code :
          <input type="text" value={ticketInfo?.confirmationCode} readOnly />
        </label>
        <br />
        <label>
          First Name :
          <input type="text" value={ticketInfo?.firstName} readOnly />
        </label>
        <br />
        <label>
          Last Name :
          <input type="text" value={ticketInfo?.lastName} readOnly  />
        </label>
        <br />
        <label >
          Contact Number :
          <input className='spaceleft' type="tel" value={ticketInfo?.phoneNumber} readOnly/>
        </label>
        <br />
        <label>
          Email Address :
          <input type="email" value={ticketInfo?.email} readOnly />
        </label>
        <br />
        <label>
          Reservation Date :
          <input type="date" value={ticketInfo?.createdAt} readOnly />
        </label>
        <br />
        <label>
          Number of People :
          <input value={ticketInfo?.noOfPeople} readOnly />
          
        </label>
        <br />
        <label>
          Payment Method :
          <input value={ticketInfo?.reservationType} readOnly />
          
        </label>
        <br />
        <label>
          Created Date :
          <input value={ticketInfo?.createdAt} readOnly />
        </label>
        <br />
      </div>
      <div className="reservation-form__right">
     
        <label>
        Created By :
          <input value={ticketInfo?.createdBy} readOnly />
        </label>
        <br />
        <label>
        creator Name :
          <input value={ticketInfo?.creatorName} readOnly />
        </label>
        <br />
        <label>
        Issued Date :
          <input value={ticketInfo?.issuedDate} readOnly />
        </label>
        <br />
        <label>
        Issued By :
          <input value={ticketInfo?.issuedBy} readOnly />
        </label>
        <br />
        <label>
        Issued Name :
          <input value={ticketInfo?.issuerName} readOnly />
        </label>
        <br />
        <label>
        Amount:
          <input value={ticketInfo?.amount} readOnly />
        </label>
        <br />
        <label>
        Payment Status:
          <input value={ticketInfo?.paymentStatus} readOnly />
        </label>
        <br />
        <label>
        Payment Type:
          <input value={ticketInfo?.paymentType} readOnly />
        </label>
        <br />
        <label>
        Payment Id:
          <input value={ticketInfo?.paymentId} readOnly />
        </label>
        <br />
    </div>
       </form>
     </div>
     );
}

export {RegistrationDetails};