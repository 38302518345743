import React from 'react';
import { makeStyles } from '@mui/styles';
import { Slide } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { SpinnerComponent } from '../../../Shared';

const useStyles = makeStyles({});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ToggleStatusModal(props) {
  const classes = useStyles();
  const {
    open,
    isLoading,
    details: { title, message, callBackData },
    closeHandler,
    confirmHandler,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      TransitionComponent={Transition}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={closeHandler} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant="h5" sx={{ fontFamily: 'revert' }}>
          {message}
        </Typography>
        {isLoading && <SpinnerComponent />}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeHandler}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isLoading}
          onClick={() => confirmHandler(callBackData)}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
