import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CustomLineChart from '../shared/line-chart';

const useStyles = makeStyles({
  chartBlock: {
    height: '50vh',
    display: 'flex',
    marginTop: '24px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chartMargin: {
    top: 12,
    right: 8,
    left: 8,
    bottom: 4,
  },
  chartToolTipParent: {
    padding: '4px',
    background: '#eaeaea',
    borderRadius: '8px',
  },
});

export default function Itperson(props) {
  const classes = useStyles();

  const data = props.data;
  var vehicleStructuredData=[];
  var visitorStructuredData=[];

  const lineChartOptions = {
    margin: {
      top: 20,
      right: 8,
      left: 16,
      bottom: 4,
    },
  };

  const transformedData = {};
  if (data != {} && data.vehicles) {
    data.vehicles.forEach((vehicle) => {
      const startDate = vehicle.effective_start_date;
      const vehicleType = vehicle.type;
      const price = vehicle.price;
      if (!transformedData[startDate]) {
        transformedData[startDate] = {};
      }
      transformedData[startDate][vehicleType] = price;
    });
    const dataArray = Object.entries(transformedData);
    dataArray.sort((a, b) => {
      const dateA = new Date(a[0]);
      const dateB = new Date(b[0]);
      return dateA - dateB;
    });
    const sortedData = Object.fromEntries(dataArray);
    console.log(sortedData);
    const previousVehiclePrice = {
      Bike: null,
      Car: null,
      Bus: null,
     'Three-Wheeler': null,
      Van: null,
    };
    vehicleStructuredData = Object.keys(sortedData).map((startDate) => {
      const startDateData = { effective_start_date: startDate };
      const vehicleTypes = ['Bike', 'Car', 'Bus', 'Three-Wheeler', 'Van'];

      vehicleTypes.forEach((vehicleType) => {
        startDateData[vehicleType] =
        sortedData[startDate][vehicleType] || previousVehiclePrice[vehicleType];
        previousVehiclePrice[vehicleType] = startDateData[vehicleType];
      });
      return startDateData;
    });
    vehicleStructuredData.sort((a, b) => {
      const dateA = new Date(a.effective_start_date);
      const dateB = new Date(b.effective_start_date);
      return dateA - dateB;
    });
  }


  const visitorsTransformedData = {};
  if (data != {} && data.visitors) {
    data.visitors.forEach((visitor) => {
      const startDate = visitor.effective_start_date;
      const visitorType = visitor.type;
      const price = visitor.price;
      if (!visitorsTransformedData[startDate]) {
        visitorsTransformedData[startDate] = {};
      }
      visitorsTransformedData[startDate][visitorType] = price;
    });
    const dataArray = Object.entries(visitorsTransformedData);
    dataArray.sort((a, b) => {
      const dateA = new Date(a[0]);
      const dateB = new Date(b[0]);
      return dateA - dateB;
    });
    const sortedData = Object.fromEntries(dataArray);
    console.log(sortedData);
    const previousVisitorPrice = {
      "local-kids": null,
      "local-adults": null,
      "tourist-adults": null,
      "tourist-kids": null,
    };
    visitorStructuredData = Object.keys(sortedData).map((startDate) => {
      const startDateData = { effective_start_date: startDate };
      const visitorTypes = ["local-kids", "local-adults", "tourist-adults", "tourist-kids"];

      visitorTypes.forEach((visitorType) => {
        startDateData[visitorType] =
        sortedData[startDate][visitorType] || previousVisitorPrice[visitorType];
        previousVisitorPrice[visitorType] = startDateData[visitorType];
      });
      return startDateData;
    });
    visitorStructuredData.sort((a, b) => {
      const dateA = new Date(a.effective_start_date);
      const dateB = new Date(b.effective_start_date);
      return dateA - dateB;
    });
  }
  return (
    <>
      <div className={classes.chartBlock}>
        <Typography variant="h1" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
          Historical Rate of Vehicles
        </Typography>
        {vehicleStructuredData?.length?
        <CustomLineChart
          data={vehicleStructuredData}
          xaxisKey={'effective_start_date'}
          lines={[
            {
              key: 'Bike',
              color: '#870404',
            },
            {
              key: 'Car',
              color: '#e0b404',
            },
            {
              key: 'Bus',
              color: '#09b022',
            },
            {
              key: 'Three-Wheeler',
              color: '#2307ad',
            },
            {
              key: 'Van',
              color: '#ff1cca',
            },
          ]}
          lineChart={{ ...lineChartOptions }}
        />:<div>
          <Typography variant="h3" sx={{ fontSize: '3vh', marginTop:'30px' ,fontFamily: 'revert' }}>
          No rate change between given date range
        </Typography>
        </div>}
      </div>
      <div className={classes.chartBlock}>
        <Typography variant="h1" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
          Historical Rate of Visitors
        </Typography>
        {visitorStructuredData ?
        <CustomLineChart
          data={visitorStructuredData}
          xaxisKey={'effective_start_date'}
          lines={[
            {
              key: 'local-kids',
              color: '#870404',
            },
            {
              key: 'local-adults',
              color: '#e0b404',
            },
            {
              key: 'tourist-adults',
              color: '#09b022',
            },
            {
              key: 'tourist-kids',
              color: '#2307ad',
            },
          ]}
          lineChart={{ ...lineChartOptions }}
        />:<div>
          <Typography variant="h1" sx={{ fontSize: '3vh', marginTop:'30px', fontFamily: 'revert' }}>
          No rate change between given date range
        </Typography>
        </div>
        }
      </div>
      <div className={classes.chartBlock}>
        <Typography variant="h3" sx={{ fontSize: '3vh', fontFamily: 'revert' }}>
          Historical Rate of Pre-Shoot/Photo-Shoot
        </Typography>
        {data != {} && data.preShoot?.length ?
        <CustomLineChart
          data={data.preShoot}
          xaxisKey={'effective_start_date'}
          lines={[
            {
              key: 'price',
              color: '#FF5733',
            },
          ]}
          lineChart={{ ...lineChartOptions }}
        />:
        <div>
          <Typography variant="h1" sx={{ fontSize: '3vh', marginTop:'30px', fontFamily: 'revert' }}>
          No rate change between given date range
        </Typography>
        </div>
        }
      </div>      
    </>
  );
}
