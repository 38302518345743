import axios from 'axios';

const pathMap = (path) => {
  const publicPaths = {
    'vehicle/get': 'public',
    'visitor/get-types': 'public',
    'nationality/get': 'public',
    'user/guide/register': 'public',
    'post': 'public',
    'ticket/new-online' : 'public',
    'pre-shoot/price' : 'public',
    'pre-shoot/delete':'private',
    'pre-shoot/get-by-confirmation':'private',
    'donation/new' : 'public',
    'bungalow/post-visitor-details' : 'public',
    'bungalow/post-reservation' : 'public',
    'bungalow/get-availability':'public',
    'get':'private',
    'sales-pre-shoot':'private',
    'get-all-prices':'private',
    'pre-shoot/get-by-id':'private',
    'pre-shoot/new':'public',
    'analytics' : 'private',
    'sales-bungalows':'public',
    'bungalow/get': 'public',
    'bungalow/get-bungalow-details':'public',
    'bungalow/post-booking-details':'public',
    'init' : 'public',
    'complete' : 'public',
    'pre-shoot/new-onsite': 'private',
    'pre-shoot/get':'private',
    'pre-shoot/confirm-reservation':'private',
    'bungalow/get-room-details': 'public',
    'latest':'public',
    'get-by-id':'private',
    'update':'private',
    'delete' :'private',
    "ticket/get" :"private",
    "new": "private",
    'ticket/new-onsite':'private',
    'announcement/type1':'private',
    'announcement/type2':'private',
    'announcement-type-2/get': 'public',
    'announcement-type-1/get': 'public',
    'announcement-type1/new':'private',
    'announcement-type2/new':'private'
   
  }
  return publicPaths[path] || 'private';
};

const BFF_ENDPOINT = process.env.REACT_APP_BFF_HOST;
const API_KEY = process.env.REACT_APP_API_KEY;

export const sendPOSTRequest = async ({ path, payload = {}, queryParams = undefined }, token, route) => {
  try {
    if(route){

    }else{
      route = pathMap(path);
    }

    const res = await axios({
      method: 'post',
      url: `${BFF_ENDPOINT}/${route}${queryParams ? `${queryParams}` : ''}`,
      headers: {
        'Content-Type': 'application/json',
        path: path,
        'X-Api-Key': route === 'public' ? API_KEY : '',
        Authorization: route === 'public' ? '' : `Bearer ${token}`,
      },
      data: payload,
    });

    if (res.data.status === 'warning') {
      console.warn('Warning response', res.data);
    }

    return res.data;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      window.location.reload();
    }
    console.log('Failed to get response', err);
    return { status: 'error', data: undefined };
  }
};
