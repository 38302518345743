import {
  TextField,
  Typography,
  CircularProgress,
  Switch,
  FormControlLabel,
  Autocomplete,
  Popper,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

export const TicketGuideComponent = (props) => {
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [isChecked, setIsChecked] = useState(false);

  const onAutocompleteChange = (value) => {
    setInputValue(value);
    props.onAutoCompleteChange(value);
  };

  const onAutoCompleteSelect = (value) => {
    setSelectedValue(value);
    props.onAutoCompleteSelect(value);
  };

  const onSwitchChange = (checked) => {
    if (!checked) {
      setInputValue('');
      setSelectedValue('');
      props.onAutoCompleteSelect(undefined);
      setOpen(false);
    }
    setIsChecked(checked);
    props.onGuideSwitch(checked);
  };

  const OptionsPopper = function (props) {
    return <Popper {...props} style={{ maxWidth: 300, minWidth: 208 }} placement="bottom-start" />;
  };

  useEffect(() => {
    setSelectedValue({ usercode: props.guideCode });
  }, [props.guideCode]);

  return (
    <div hidden style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-evenly' }}>
      <FormControlLabel
        disabled={props.isEditMode}
        control={
          <Switch
            checked={isChecked}
            onChange={(e) => {
              onSwitchChange(e.target.checked);
            }}
          />
        }
        label="Choose Guide"
        sx={{ width: '208px' }}
      />
      <Autocomplete
        PopperComponent={OptionsPopper}
        disabled={!isChecked}
        sx={{ width: '208px' }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        renderOption={(props, option, { selected }) => (
          <li {...props} style={{ borderBottom: '1px solid #cdcdcd' }}>
            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
              }}
              fontSize={'1em'}
            >
              {option.firstname} {option.lastname} ({option.usercode})
            </Typography>
          </li>
        )}
        options={props.listOfGuides}
        getOptionLabel={(option) => option.usercode || ''}
        isOptionEqualToValue={(option, value) => true}
        filterOptions={(x) => x}
        value={selectedValue}
        onChange={(e, v) => onAutoCompleteSelect(v)}
        inputValue={inputValue}
        onInputChange={(e, v) => onAutocompleteChange(v)}
        loading={props.autocompleteLoading}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Type 3 characters"
            label="Guide"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.autocompleteLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};
