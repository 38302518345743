import React, { useState,useEffect } from 'react';
const PaymentDetails=({changeCurrentPage})=> {
const [showQr, setShowQr] = useState(false);
const [isChecked, setIsChecked] = useState(false);
const handleSubmit = (event) => {
    event.preventDefault();
    changeCurrentPage(5);  
};
const handleCheckboxChange = () => {
  setIsChecked(!isChecked);
};
const current = new Date().toISOString().split('T')[0];

return (
    <div>
    <div className="reservation-form">
   { !showQr && 
    <form className="reservation-form-middle" onSubmit={handleSubmit}>
         <div className="terms-container">
      <h1>Terms and Conditions for Ambuluwawa.com</h1>
      <h2>Introduction</h2>
      <p>
        Welcome to Ambuluwawa.com. These terms and conditions outline the rules and regulations for the use of our website and services. By accessing this website, we assume you accept these terms and conditions. Do not continue to use Ambuluwawa.com if you do not agree to all of the terms and conditions stated on this page.
      </p>
      <h2>Payment Options</h2>
      <p>
        We accept a variety of payment methods for your convenience:
      </p>
      <ol>
        <li>Cash: You can pay in cash at our physical ticket counters.</li>
        <li>Credit Cards: We accept all major credit cards. Your credit card information will be encrypted during transmission for your security.</li>
        <li>Bank Transfers: You can also make payments via bank transfers. Please contact our support team for more information.</li>
      </ol>
      <h2>Refund Policy</h2>
      <p>
        We understand that plans can change, and we have a flexible refund policy in place:
      </p>
      <ol>
        <li>Ticket Cancellations: You can cancel your tickets up to 3 days in advance of your planned visit. The full amount will be refunded to your original method of payment.</li>
        <li>Credit Card Refunds: If you paid by credit card, the refund will be sent to the card-issuing bank within 7 business days of receipt of the cancellation request. Please contact the card-issuing bank with questions about when the credit will be posted to your account.</li>
        <li>Refund Requests: To request a refund, please contact our support team at <a href="mailto:info@ambuluwawa.com">info@ambuluwawa.com</a> or call us at <a href="tel:+94712724724">+94 71 272 4724</a>.</li>
      </ol>
      <h2>Customer Satisfaction Guarantee</h2>
      <p>
        At Ambuluwawa.com, we strive to provide an enjoyable and memorable experience for all our visitors. If you are not satisfied with any part of your visit, please let us know. We will do our best to address your concerns and ensure your satisfaction.
      </p>
      <h2>Contact Us</h2>
      <p>
        If you have any questions about these terms and conditions, or any complaints or suggestions, please contact us at:
      </p>
      <p>Email: <a href="mailto:info@ambuluwawa.com">info@ambuluwawa.com</a></p>
      <p>Phone: <a href="tel:+94712724724">+94 71 272 4724</a></p>
      <p>We are always ready to assist you and make your experience with us as pleasant as possible.</p>
      <h2>Changes to Terms and Conditions</h2>
      <p>
        We reserve the right to modify these terms and conditions at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
      </p>
      <p>
        <strong>Agreement</strong><br/>
                By using our website, you hereby consent to our terms and conditions and agree to its terms.
      </p>
      <p>
        Thank you for choosing Ambuluwawa.com. We look forward to welcoming you to our park.
      </p>
      <p>Last updated: {current}</p>
    </div>
    <div className='checkboxInput'>
    <input type="checkbox" checked={isChecked}  onChange={handleCheckboxChange} />&nbsp;&nbsp;&nbsp;&nbsp;
    <p style={{"text-wrap": "nowrap", "display": "contents", "font-weight": 600}}>I agree to the terms and conditions</p>
    </div>
    
    
    <input className={`proceed-btn ${isChecked === false ? 'disabled' : ''}`} disabled={isChecked === false} type="submit" value="Proceed to Payment"/>
       </form>
       }
       </div>
       <button className="button back-button" onClick={()=>{changeCurrentPage(2)}} style={{margin:"10px"}}> Back </button>
     </div>
     
     );
}
export {PaymentDetails};