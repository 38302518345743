import React, { useState } from 'react';
import { Alert, TextField, Stack } from '@mui/material';
import SelectDistric from '../components/distric';
import SelectProvince from '../components/province';
import SelectCountry from '../components/country';
import SaveIcon from '@mui/icons-material/Save';
import { Button, Typography } from '@mui/material';
import { isNumeric, IsPassword } from '../../../../shared/functions';

export default function CreateNewGuide(props) {
  const tourGuide = props.tourGuide;
  const onSubmit = props.onSubmit;
  const onUpdate = props.onUpdate;
  const setTourGuide = props.setTourGuide;
  const isError = props.isError;
  const setIsError = props.setIsError;
  const isUpdate = props.isUpdate;
  const [firstName, setFirstName] = useState(tourGuide.firstName);
  const [lastName, setLastName] = useState(tourGuide.lastName);
  const handleLastNameChange = (e) => {
    const regex = /^[a-zA-Z]*$/;
    const input = e.target.value.replace(/[^a-zA-Z]/g, '');
    if (regex.test(input)) {
        setLastName(input);
        setTourGuide({
            ...tourGuide,
            lastName: e.target.value,
        });
    }
};
const handleFirstNameChange = (e) => {
  const regex = /^[a-zA-Z]*$/;
  const input = e.target.value.replace(/[^a-zA-Z]/g, '');
  if (regex.test(input)) {
    setFirstName(input);
      setTourGuide({
          ...tourGuide,
          firstName: e.target.value,
      });
  }
};
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '70px',
        padding: '10px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>
          <h3 className="heading">{isUpdate ? 'Update Tour Guide' : 'Register New Tour Guide'}</h3>
        </div>
        <div style={{ display: 'flex', marginTop: '-20px' }}>
          <div className="rootUser">
            <div style={{ marginTop: '30px', marginBottom: '50px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  marginTop: '20px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid',
                    borderRadius: '20px',
                    padding: '15px',
                    marginTop: '20px',
                  }}
                >
                  <Typography className="subHeading">Personal Details</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: '25px',
                    }}
                  >
                    <TextField
                      style={{ width: '32%' }}
                      label="First Name"
                      // value={tourGuide.firstName}
                      // error={!firstName}
                      type="text"
                      value={firstName}
                      // error={isErrorField('firstName')}
                      onChange={handleFirstNameChange}
                      variant="outlined"
                    />
                    <TextField
                      style={{ width: '33%' }}
                      label="Last Name"
                      // value={tourGuide.lastName}
                      // error={!lastName}
                      value={lastName}
                      onChange={handleLastNameChange}
                      variant="outlined"
                    />
                    <TextField
                      style={{ width: '33%' }}
                      label="Date Of Birth"
                      type="date"
                      variant="outlined"
                      max="9999-12-31"
                      InputLabelProps={{ shrink: true }}
                      value={tourGuide.dob.split('T')[0]}
                      // error={!tourGuide.dob}
                      onChange={(e) => {
                        setTourGuide({ ...tourGuide, dob: e.target.value });
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                    }}
                  >
                    <TextField
                      style={{ width: '23%' }}
                      label="Email Address"
                      type="email"
                      value={tourGuide.email}
                      // error={!tourGuide.email}
                      onChange={(e) => {
                        setTourGuide({ ...tourGuide, email: e.target.value });
                      }}
                      variant="outlined"
                    />
                    <TextField
                      style={{ width: '25%' }}
                      label="Username"
                      type="text"
                      value={tourGuide.username}
                      // error={!tourGuide.username}
                      onChange={(e) => {
                        setTourGuide({
                          ...tourGuide,
                          username: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                    <TextField
                      style={{ width: '25%' }}
                      label="Phone Number"
                      type="tel"
                      value={tourGuide.phoneNumber}
                      // error={!tourGuide.phoneNumber}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (isNumeric(val) || e.nativeEvent.inputType === 'deleteContentBackward') {
                          if (val.length < 11) {
                            setTourGuide({ ...tourGuide, phoneNumber: val });
                          }
                        }
                      }}
                      variant="outlined"
                    />
                    <TextField
                      style={{ width: '25%' }}
                      label="NIC/Passport Number"
                      value={tourGuide.identification}
                      // error={!tourGuide.identification}
                      onChange={(e) => {
                        setTourGuide({
                          ...tourGuide,
                          identification: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '30px',
                    border: '1px solid',
                    borderRadius: '20px',
                    padding: '15px',
                  }}
                >
                  <Typography className="subHeading">Residential Details</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                    }}
                  >
                    <TextField
                      style={{ width: '32%' }}
                      label="Street Address"
                      value={tourGuide.addressLine1}
                      // error={!tourGuide.addressLine1}
                      onChange={(e) => {
                        setTourGuide({
                          ...tourGuide,
                          addressLine1: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                    <TextField
                      style={{ width: '33%' }}
                      label="Street Address Line 2"
                      value={tourGuide.addressLine2}
                      // error={!tourGuide.addressLine2}
                      onChange={(e) => {
                        setTourGuide({
                          ...tourGuide,
                          addressLine2: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                    <TextField
                      style={{ width: '33%' }}
                      label="City"
                      value={tourGuide.city}
                      // error={!tourGuide.city}
                      onChange={(e) => {
                        setTourGuide({ ...tourGuide, city: e.target.value });
                      }}
                      variant="outlined"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: '20px',
                    }}
                  >
                    <SelectProvince
                      width="25%"
                      province={tourGuide.province}
                      setProvince={(province) => {
                        setTourGuide({ ...tourGuide, province: province });
                      }}
                    />
                    <SelectDistric
                      width="22%"
                      distric={tourGuide.district}
                      province={tourGuide.province}
                      setDistric={(district) => {
                        setTourGuide({ ...tourGuide, district: district });
                      }}
                    />
                    <TextField
                      style={{ width: '25%' }}
                      label="Postal Code"
                      value={tourGuide.postalCode}
                      // error={!tourGuide.postalCode}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (isNumeric(val) || e.nativeEvent.inputType === 'deleteContentBackward') {
                          if (val.length < 6) {
                            setTourGuide({
                              ...tourGuide,
                              postalCode: e.target.value,
                            });
                          }
                        }
                      }}
                      variant="outlined"
                    />
                    <SelectCountry />
                  </div>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '30px',
                    border: '1px solid',
                    borderRadius: '20px',
                    padding: '15px',
                  }}
                >
                  <Typography className="subHeading">Bank Details</Typography>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: '25px',
                    }}
                  >
                    <TextField
                      style={{ width: '48%' }}
                      label="Name as in Bank Account"
                      value={tourGuide.bankAccountName}
                      // error={!tourGuide.bankAccountName}
                      onChange={(e) => {
                        setTourGuide({
                          ...tourGuide,
                          bankAccountName: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                    <TextField
                      style={{ width: '50%' }}
                      label="Bank Account Number"
                      value={tourGuide.bankAccountNumber}
                      // error={!tourGuide.bankAccountNumber}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (isNumeric(val) || e.nativeEvent.inputType === 'deleteContentBackward') {
                          setTourGuide({
                            ...tourGuide,
                            bankAccountNumber: val,
                          });
                        }
                      }}
                      variant="outlined"
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'space-between',
                      marginTop: '25px',
                    }}
                  >
                    <TextField
                      style={{ width: '48%' }}
                      label="Bank Name"
                      value={tourGuide.bankName}
                      // error={!tourGuide.bankName}
                      type="text"
                      onChange={(e) => {
                        setTourGuide({
                          ...tourGuide,
                          bankName: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                    <TextField
                      style={{ width: '50%' }}
                      label="Branch Name"
                      value={tourGuide.bankBranch}
                      // error={!tourGuide.bankBranch}
                      onChange={(e) => {
                        setTourGuide({
                          ...tourGuide,
                          bankBranch: e.target.value,
                        });
                      }}
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '30px',
                  border: '1px solid',
                  borderRadius: '20px',
                  padding: '15px',
                }}
              >
                <Typography className="subHeading">Password</Typography>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    marginTop: '25px',
                  }}
                >
                  <TextField
                    style={{ width: '48%' }}
                    label="New Password"
                    type="password"
                    value={tourGuide.newPassword}
                    // error={!tourGuide.newPassword}
                    onChange={(e) => {
                      const val = e.target.value;
                      setTourGuide({ ...tourGuide, newPassword: val });
                    }}
                    variant="outlined"
                  />
                  <TextField
                    style={{ width: '50%' }}
                    label="Confirm Password"
                    type="password"
                    value={tourGuide.password}
                    // error={!tourGuide.password}
                    onChange={(e) => {
                      const val = e.target.value;
                      setTourGuide({ ...tourGuide, password: val });
                    }}
                    variant="outlined"
                  />
                </div>
              </div>
              {isError.state && (
                <Stack
                  sx={{ width: '100%' }}
                  style={{ marginBottom: '10px', marginTop: '10px' }}
                  spacing={2}
                >
                  <Alert
                    onClose={() => {
                      setIsError({ state: false, message: '' });
                    }}
                    severity="error"
                  >
                    {isError.message}
                  </Alert>
                </Stack>
              )}

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  justifyContent: 'space-between',
                  marginTop: '25px',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <Button
                  style={{ width: '33%' }}
                  size="large"
                  color="primary"
                  startIcon={<SaveIcon />}
                  variant="contained"
                  onClick={() => {
                    isUpdate ? onUpdate() : onSubmit();
                  }}
                >
                  {isUpdate ? 'Update' : 'Save'}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
