import { PageWrapper } from '../components/Shared';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { GalleryImages } from '../components/Gallery';
import { NewsDetails } from '../components/NewsEvents/NewsDetails';

const useStyles = makeStyles({
  homeImage: {
    height: '60vh',
    width: '100vw',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
  },
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 100,
    height: '60vh',
  },
  homeTextStyles: {
    background:
      '-webkit-linear-gradient(360deg, rgba(0,0,0,0.7105435924369747) 0%, rgba(0,0,0,0.8477984943977591) 46%, rgba(0,0,0,1) 100%);',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
});

const ViewNewsDetails = () => {
  const classes = useStyles();
  return (
    <PageWrapper style={{ height: '100vh', width: '100wh', backgroundColor: 'red' }}>
      {' '}
      <img
        src="https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Home/home-image.jpg"
        className={classes.homeImage}
        alt="home"
      />
      <div className={classes.homeContainer}>
        
      </div> 
      <NewsDetails />
    </PageWrapper>
  );
};

export { ViewNewsDetails };
