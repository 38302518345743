import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, IconButton, Slide, Toolbar, Typography, TextField, Box } from '@mui/material';
import { SpinnerComponent } from '.';

const useStyles = makeStyles({});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function DeleteModal(props) {
  const classes = useStyles();
  const {
    open,
    isLoading,
    deleteDetails: { title, message, callBackData },
    closeHandler,
    deleteConfirmHandler,
  } = props;

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      TransitionComponent={Transition}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={closeHandler} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
            Delete {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Typography variant="h5" sx={{ fontFamily: 'revert' }}>
          {message}
        </Typography>
        {isLoading && <SpinnerComponent />}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={closeHandler}>
          Cancel
        </Button>
        <Button
          color="error"
          variant="contained"
          disabled={isLoading}
          onClick={() => deleteConfirmHandler(callBackData)}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
