import React, { useState, useEffect } from 'react';
import { sendPOSTRequest } from '../../../../../adaptors/bff-requests';
import AlertMessage from '../../../../Alerts/AlertMessage';
import { useAuth } from '../../../../../context';
import { PayAtGateDetails } from './PayAtGateDetails';

const PayAtGate = ({handleClickPayment,showAlertBox}) => {
  const [confirmation, setConfirmation] = useState();
  const [date, setDate] = useState();
  const [UserID, setUserID] = useState();
  const [showPayDetail, setShowPayDetail] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const [confirmationUserData, setConfirmationUserData] = useState({});
  const [reservationId, setReservationId] = useState(null); // Add state for reservationId

  const { token, authorizedUser: { authKey } } = useAuth();

  const handleConfirmationChange = (event) => {
    setConfirmation(event.target.value);
  };

  const changeShow = () => {
    setShowPayDetail(!showPayDetail);
  };

  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    setDate(dd + '-' + mm + '-' + yyyy);
    setUserID(101);
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      confirmationCode: confirmation
    };
    const result = await sendPOSTRequest({ path: 'pre-shoot/get-by-confirmation', payload: data }, token);
    console.log(JSON.stringify(result));
    if (result.status === 'error') {
      setErrorAlert(true);
      setAlertMessage("Please check the confirmation code.");
    } else if (result.status === 'success') {
      setReservationId(result.data.reservationDetail.reservationId); // Set the reservationId from the API response data
      setConfirmationUserData(result.data);
      changeShow();
    } else {
      setErrorAlert(true);
      setAlertMessage("Please try again later.");
    }
  };

  return (
    <div className='payatgate'>
      {showPayDetail && (
        <>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '100px',
            height: '100%',
            width: '100%',
          }}>
            <form className="reservation-form-middle" onSubmit={handleSubmit}>
              <label>
                Ticketing Counter:
                <input type="text" value={UserID} readOnly />
              </label>
              <br />
              <div>
                <label>
                  Date:
                  <input value={date} readOnly />
                </label>
                <br />
                <label>
                  Confirmation Number:
                  <input type="text" value={confirmation} onChange={handleConfirmationChange} maxLength={50} required />
                </label>
                <br />
                <input type="submit" />
              </div>
            </form>
          </div>
          <AlertMessage
            message={alertMessage}
            show={errorAlert}
            closeHandler={(value) => setErrorAlert(false)}
          />
        </>
      )}
      {!showPayDetail && <PayAtGateDetails changeShow={changeShow} confirmationUserData={confirmationUserData} reservationId={reservationId} handleClickPayment={handleClickPayment} showAlertBox={showAlertBox}/>}
    </div>
  );
};

export { PayAtGate };
