import { Button, TextField, Typography } from '@mui/material';
import React from 'react';

export default function UpdatePassword(props) {
  const { oldPassword, newPassword, confirmPassword } = props.passwordFields;
  return (
    <div style={{ marginTop: '2em' }}>
      <div>
        <Typography variant="h4" sx={{ fontFamily: 'revert' }}>
          Update password
        </Typography>
        <Typography
          fontWeight={'light'}
          fontStyle={'italic'}
          marginTop={1}
          sx={{ fontFamily: 'revert', fontSize: 'default' }}
        >
          New Password should contain minimum 6 characters including 1 number and 1 UPPER case
          letter
        </Typography>
      </div>
      <div
        style={{
          alignItems: 'end',
          margin: '16px 0',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <div className="userDetilasTxt">
          <p className="userHeading">Old Password</p>
          <TextField
            className="userData"
            hiddenLabel
            type="password"
            variant="filled"
            size="small"
            value={oldPassword}
            onChange={(e) => {
              props.onPasswordFieldsChange('oldPassword', e.target.value);
            }}
          />
        </div>
        <div className="userDetilasTxt">
          <p className="userHeading">New Password</p>
          <TextField
            className="userData"
            hiddenLabel
            type="password"
            variant="filled"
            size="small"
            value={newPassword}
            onChange={(e) => {
              props.onPasswordFieldsChange('newPassword', e.target.value);
            }}
          />
        </div>
        <div className="userDetilasTxt">
          <p className="userHeading">Confirm Password</p>
          <TextField
            className="userData"
            hiddenLabel
            type="password"
            variant="filled"
            size="small"
            value={confirmPassword}
            onChange={(e) => {
              props.onPasswordFieldsChange('confirmPassword', e.target.value);
            }}
          />
        </div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          disabled={props.isLoading}
          style={{ width: '200px' }}
          variant="contained"
          onClick={props.onChangePasswordClick}
        >
          Change password
        </Button>
      </div>
    </div>
  );
}
