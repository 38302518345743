import React, { useState,useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress } from '@mui/material';
import { useAuth } from '../../../../../context';
import { sendPOSTRequest } from '../../../../../adaptors/bff-requests'

export default function DonationTable(props) {
  const {
    token,
    authorizedUser: { authKey },
  } = useAuth();

  const [donationList, setDonationList] = useState([]);

  const numColumnProps = { align: 'right', headerAlign: 'right' };
 
  const [currentPage, setCurrentPage] = useState(1); // Track current page number
  const [totalRows, setTotalRows] = useState(0);
  console.log(currentPage)

  const fetchData = async (page) => {
    const adjustedPage = Math.max(1, page); // Ensure page is at least 1

    const payload = {
      page: adjustedPage - 1,
      pageLimit: 20,
      sortColumn: 'id',
      sortOrder: 'DESC',
      keyword: '',
    };

    const response = await sendPOSTRequest(
      { path: 'get', payload, queryParams: '/donation' },
      token
    );

    setDonationList(response.data.listOfDonations);
    setTotalRows(response.data.rowCount);
  };

  useEffect(() => {
    fetchData(currentPage);
  }, []);

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);


  const columns = [
    { field: 'donationId', headerName: '#ID', ...numColumnProps },
    { field: 'amount', headerName: 'Amount', ...numColumnProps },
    { field: 'isReceived', headerName: 'Is Received', flex: 1 },
    { field: 'firstName', headerName: 'Firstname', flex: 1 },
    { field: 'lastName', headerName: 'Lastname', flex: 1 },
    { field: 'createdDate', headerName: 'Date', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 1 },
    { field: 'remark', headerName: 'Remark', flex: 1 },
   
  ];

  const getRows = () => {
    const rows = donationList.map((e) => {
      return {
        id: e.donationId,
        donationId: e.donationId,
        amount: e.amount,
        isReceived:  e.isReceived ?"Yes":"No",
        firstName: e.firstName,
        lastName: e.lastName,
        createdDate: e.createdDate,
        email: e.email,
        remark: e.remark,
      };
    });
    return rows;
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        marginTop: '10px',
      }}
    >
      <DataGrid
        style={{ color: 'green', fontSize: '12px' }}
        components={{ LoadingOverlay: LinearProgress }}
        rows={getRows()}
        columns={columns}
        pageSize={20}
        rowsPerPageOptions={[20]}
        pagination
        onPageChange={(newPage) => setCurrentPage((newPage))}
        paginationMode="server" // Important for server-side pagination
        rowCount={totalRows}
      />
    </div>
  );
}
