import axios from 'axios';


const BFF_ENDPOINT = process.env.REACT_APP_COMMISSION_BFF_ENDPOINT;
const API_KEY = process.env.REACT_APP_COMMISSION_API_KEY;

export const sendRequestNewdb = async ({method="post" ,path, payload = {}, queryParams = undefined }) => {
  try {
    const queryString = queryParams ? `?${new URLSearchParams(queryParams).toString()}` : '';
    const res = await axios({
      method: method,
      url: `${BFF_ENDPOINT}/${path}${queryParams ? `${queryString}` : ''}`,
      headers: {
        'Content-Type': 'application/json',
        'x-api-key': API_KEY
      },
      data: payload,
    });

    if (res.data.status === 'warning') {
      console.warn('Warning response', res.data);
    }

    return res.data;
  } catch (err) {
    if (err.response && err.response.status === 401) {
      window.location.reload();
    }
    console.log('Failed to get response', err);
    return { status: 'error', data: undefined };
  }
};