const palette = {
  background: {
    default: '#ffffff',
  },
  primary: {
    main: '#1E3932',
    light: '#D4E9E2',
  },
  secondary: {
    main: '#00754A',
  },
  blue: {
    main: '#1E75FF',
    dark: '#44444F',
  },
  brightGreen: {
    main: '#3DD598',
  },
  text: {
    primary: '#171725',
    secondary: '#92929D',
  },
};

export default palette;
