import React, { useState, useEffect, useCallback } from 'react';

import * as XLSX from 'xlsx';

import { SpinnerComponent } from '../../Shared';
import ReportFilterSection from './shared/filter-section';
import SalesReport from './sales-report';
import VisitorReport from './visitor-report';
import EntranceReport from './entrance-report';
import DonationReport from './donation-report';
import TourGuideReport from './tourGuide-report';
import  PhotoReport from './photo-report';
import ITPersonReport from './IT-person';

import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useAuth } from '../../../context';
import { useAlertStore } from '../../../stores';
import { moduleIds } from '../../../utils/rolesAndPermissions';

const defaultDates = () => {
  const fromDate = new Date();
  fromDate.setDate(new Date().getDate() - 30);
  const toDate = new Date();
  return {
    fromDate: fromDate.toISOString().split('T')[0],
    toDate: toDate.toISOString().split('T')[0],
  };
};

const defaultFilters = {
  ...defaultDates(),
  frequency: 'daily',
  ticketType:'onsite',
};

export default function Analytics() {
  const { token, permissionsForAllowedModules } = useAuth();

  const allowedActions =
    (permissionsForAllowedModules && permissionsForAllowedModules[moduleIds.analytics]) || {};

  const fnShowAlert = useAlertStore((state) => state.showAlert);

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [reportType, setReportType] = useState('salesReport');
  const [filters, setFilters] = useState(defaultFilters);

  const showAlertBox = useCallback(
    (message, type = 'success') => {
      fnShowAlert({
        message: message,
        severity: type,
      });
    },
    [fnShowAlert]
  );

  const onReportTypeChanged = async (reportType) => {
    setData(undefined);
    setReportType(reportType);
  };

  const onFiltersChanged = (key, value) => {
    setFilters((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const onApplyHandler = async () => {
    const { fromDate, toDate } = filters;

    if (!validateDate({ fromDate, toDate })) {
      return;
    }

    await fetchReportData({
      ...filters,
      reportType,
    });
  };

  const validateDate = (dateRange) => {
    const { fromDate, toDate } = dateRange;
    if (!dateRange || !fromDate || !toDate) {
      showAlertBox('Please Select Valid Dates', 'warning');
      return;
    }

    if (new Date(fromDate) > new Date(toDate)) {
      showAlertBox('FromDate Should Be Less Than ToDate', 'warning');
      return;
    }

    const diffTime = new Date(toDate).getTime() - new Date(fromDate).getTime();
    const diffInDays = diffTime / (1000 * 3600 * 24);
    if (diffInDays > 365) {
      showAlertBox('Please Select Within 1 Year', 'warning');
      return;
    }
    return true;
  };

  const fetchReportData = useCallback(
    async ({ fromDate, toDate, frequency, reportType, ticketType }) => {
      let url = ``;
      switch (reportType) {
        case 'salesReport':
          url = `sales-report-data`;
          break;
        case 'visitorReport':
          url = `visitor-report-data`;
          break;
        case 'tourGuideReport':
          url = `sales-by-guide`;
          break;
        case 'entranceTicketingReport':
          url = 'sales-by-ticket-type';
          break;
        case 'donationReport':
          url = 'donations';  
          break;
        case 'photoReport':
          url = `sales-pre-shoot`;
          break;
        case 'Itperson':
          url = `price-history`;
          break;            
        default:
          break;
      }
  
      setIsLoading(true);
      const payload = {
        fromDate,
        toDate,
        frequency,
      };
  
      if (reportType === 'entranceTicketingReport') {
        payload.ticketType = ticketType;
      }
  
      const results = await sendPOSTRequest(
        {
          path: url,
          payload,
          queryParams: '/analytics',
        },
        token
      );
      setData(results ? results.data : undefined);
      setIsLoading(false);
    },
    [token]
  );

  const getDataForExport = useCallback(async () => {
    const { fromDate, toDate } = filters;

    const results = await sendPOSTRequest(
      {
        path: 'export-data',
        payload: {
          fromDate,
          toDate,
        },
        queryParams: '/analytics'
      },
      token
    );
    return results.data || [];
  }, [filters, token]);

  const exportToExcel = useCallback(async () => {
    setIsLoading(true);

    const allData = await getDataForExport();//this line should be uncommented to use live data
    // const allData = getDataForExport;
    let headerRow=[];
    if(reportType==='salesReport'){
     headerRow = [
      {
        amount: 'Total Amount',
        issueddate: 'Date',
        totalcount: 'Total Count'
      },
    ];
  }else if(reportType==='visitorReport'){
       headerRow = [
       {
         issueddate: 'Date',
         local_cost: 'Local Value (LKR)',
         local_count: 'No.Of Locals',
         tourist_cost: 'Tourist Value (LKR)',
         tourist_count: 'No.Of Tourists',
       },
     ];
   }else if(reportType==='photoReport'){
       headerRow = [
       {
        amount: 'Total Amount',
        issueddate: 'Date',
        totalcount: 'Total Count'
       },
     ];
   }

    const headerName = Object.keys(headerRow);

    const wb = XLSX.utils.book_new();
    for (const item of allData) {
      const dataToExport = [...headerRow, ...item.entries];
      const valueSheet = XLSX.utils.json_to_sheet(dataToExport, {
        header: headerName,
        skipHeader: true,
      });

      XLSX.utils.book_append_sheet(wb, valueSheet, item.month);
    }
    XLSX.writeFile(wb, reportType+'.xlsx');
    setIsLoading(false);
  }, [getDataForExport]);

  useEffect(() => {
    const apply = async () => {
      await onApplyHandler({ ...defaultFilters, reportType });
    };
    apply();
  }, [reportType]);

  useEffect(() => {
    const fetch = async () => {
      await fetchReportData({ ...defaultFilters, reportType: 'salesReport' });
    };
    fetch();
  }, [fetchReportData]);

  return (
    <div>
      <ReportFilterSection
        onApply={onApplyHandler}
        onReportTypeChanged={onReportTypeChanged}
        onFiltersChanged={onFiltersChanged}
        onExport={exportToExcel}
        allowedActions={allowedActions}
        reportType={reportType}
        filters={filters}
        isLoading={isLoading}
      />
      <div style={{ display: 'flex', flexDirection: 'column', marginTop: '24px' }}>
        {reportType === 'salesReport' ? (
          <SalesReport data={data || []} />
          ) : reportType === 'visitorReport' ? (
            <VisitorReport data={data || {}} />
          ) : reportType === 'entranceTicketingReport' ? (
            <EntranceReport data={data || []} />
          ) : reportType === 'tourGuideReport' ? (
            <TourGuideReport data={data || []} />
          )  : reportType === 'photoReport'?(
            <PhotoReport data={data || []} />
          )  : reportType === 'Itperson' ? (
            <ITPersonReport data={data || {}} /> 
          ): (
            <DonationReport data={data || []} />
          )}
        {isLoading && <SpinnerComponent style={{ top: '70%' }} />}
      </div>
    </div>
  );
}
