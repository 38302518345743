import React, { useCallback, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import UserDetails from './components/user-details';
import { SpinnerComponent } from '../../Shared';
import style from './style.css';
import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useAuth } from '../../../context';
import { useAlertStore } from '../../../stores';
import { userRoles } from '../../../utils/rolesAndPermissions';

export default function Profile() {
  const myProfileInitialState = {
    username: '',
    role: '',
    firstName: '',
    lastName: '',
    email: '',
    bankname: '',
    branchName: '',
    bankaccountnumber: '',
    bankaccountname: '',
    phoneNumber: '',
    identification: '',
    dob: '',
    position: '',
  };

  const { token, authorizedUser } = useAuth();

  const fnShowAlert = useAlertStore((state) => state.showAlert);

  const [profile, setMyProfile] = useState(myProfileInitialState);
  const [isLoading, setIsLoading] = useState(true);

  const showAlertBox = useCallback(
    (message, type = 'success') => {
      fnShowAlert({
        message: message,
        severity: type,
      });
    },
    [fnShowAlert]
  );

  const getMyProfileDetails = useCallback(async () => {
    const resp = await sendPOSTRequest({ path: 'user/my-profile/get' }, token);
    if (!resp || resp.status === 'warning') {
      if (resp.status === 'warning') {
        showAlertBox(resp.message, 'warning');
      }
      //setMyProfile(undefined);
      setIsLoading(false);
      return;
    }
    const data = resp.data;

    let dob = '';
    if (data.dob) {
      const date = new Date(data.dob).toLocaleDateString('en-US');
      const [month, day, year] = date.split('/');
      dob = year;
      dob += `-${month >= 10 ? month : `0${month}`}`;
      dob += `-${day >= 10 ? day : `0${day}`}`;
    }

    const profileDetails = {
      username: authorizedUser.username,
      role: data.role,
      firstName: data.firstname,
      lastName: data.lastname,
      email: data.email,
      phoneNumber: data.phonenumber || '',
      identification: data.identification || '',
      dob: dob,
    };

    if (authorizedUser.role !== userRoles.guide && authorizedUser.role !== userRoles.user) {
      profileDetails.position = data.position || '';
    }

    if (authorizedUser.role === userRoles.guide) {
      profileDetails.bankAccountName = data.bankaccountname || '';
      profileDetails.bankAccountNumber = data.bankaccountnumber || '';
      profileDetails.branchName = data.bankbranch || '';
      profileDetails.bankName = data.bankname || '';
    }

    setMyProfile(profileDetails);
    setIsLoading(false);
  }, [token, authorizedUser.username, showAlertBox]);

  const onUpdate = useCallback(
    async (user) => {
      const { username, role, ...detailsToUpdate } = user;
      setIsLoading(true);
      const resp = await sendPOSTRequest(
        { path: 'user/my-profile/update', payload: detailsToUpdate },
        token
      );
      if (!resp) {
        showAlertBox('Error updating profile', 'error');
      } else {
        const isWarning = resp && resp.status === 'warning';
        showAlertBox(
          isWarning ? resp.message : 'Successfully Updated Details',
          isWarning ? 'warning' : 'success'
        );
      }
      setIsLoading(false);
    },
    [showAlertBox, token]
  );

  const onChangePassword = useCallback(
    async (oldPassword, newPassword) => {
      try {
        setIsLoading(true);
        const authenticatedUser = await Auth.currentAuthenticatedUser();
        await Auth.changePassword(authenticatedUser, oldPassword, newPassword);
        showAlertBox('Successfully Changed');
        setIsLoading(false);
        return true;
      } catch (error) {
        console.log(error);
        showAlertBox(error.message, 'warning');
        setIsLoading(false);
        return false;
      }
    },
    [showAlertBox]
  );

  useEffect(() => {
    const fetchProfile = async () => {
      await getMyProfileDetails();
    };
    fetchProfile();
  }, [getMyProfileDetails]);

  return (
    <div
      style={{
        width: '100%',
        height: '85vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: '10px',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <UserDetails
            user={profile}
            onUpdate={onUpdate}
            onChangePassword={onChangePassword}
            showAlertBox={showAlertBox}
            isLoading={isLoading}
          />
        </div>
      </div>
      {isLoading && <SpinnerComponent />}
    </div>
  );
}
