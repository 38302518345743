/* eslint-disable no-useless-escape */
import React, { useState, useCallback } from 'react';
import { useRef } from 'react';
import { getAllowedAdminModulesForRole, inheritedRole } from '../utils/rolesAndPermissions';
import { sendPOSTRequest } from '../adaptors/bff-requests';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [isAuthReady, setIsAuthReady] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authorizedUser, setUser] = useState(undefined);
  const [allowedAdminModules, setAllowedAdminModules] = useState([]);
  const [permissionsForAllowedModules, setPermissionsForAllowedModules] = useState({});
  const [token, setToken] = useState(undefined);

  const lastMouseDownTime = useRef(Date.now());

  const signIn = useCallback(async (user, initialMetadata) => {
    //setAccessToken(jwt);
    //setMetadata(initialMetadata);
    const role = user.attributes['custom:role'];
    const modules = getAllowedAdminModulesForRole(role);
    const permissionsAndActions = getPermissionsAndActionsForAllowedModules(modules, role);

    const userDetails = await getUserDetails(user.signInUserSession.idToken.jwtToken);

    setToken(user.signInUserSession.idToken.jwtToken);
    setUser({
      username: user.username,
      ...user.attributes,
      authKey: user.attributes.sub,
      role: role,
      loggedInUserId: userDetails.id,
    });
    setAllowedAdminModules(modules || []);
    setPermissionsForAllowedModules(permissionsAndActions);
    setIsAuthenticated(true);
    setIsAuthReady(true);
  }, []);

  const signOut = useCallback(() => {
    setIsAuthReady(true);
    //clearAuthData();
    setIsAuthenticated(false);
    setUser(undefined);
    setToken(undefined);
    //window.location.href = '/login'
  }, []);

  const getUserDetails = useCallback(async (tok) => {
    const response = await sendPOSTRequest({ path: 'user/logged-user/get' }, tok);
    return response.data;
  }, []);

  const getPermissionsAndActionsForAllowedModules = (allowedModules, role) => {
    if (allowedModules && allowedModules.length > 0) {
      const permissions = allowedModules.reduce((acc, cur) => {
        if (!acc[cur['id']]) {
          acc[cur['id']] = {};
        }

        cur.subModules.forEach((module) => {
          acc[cur['id']][module.id] = module.allowedRoles.some(
            (item) => item === inheritedRole || item === role
          );
        });
        return acc;
      }, {});

      return permissions;
    }
    return undefined;
  };

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        isAuthReady,
        isAuthenticated,
        authorizedUser,
        allowedAdminModules,
        permissionsForAllowedModules,
        token,
      }}
    >
      {isAuthReady ? children : children}
    </AuthContext.Provider>
  );
};

const useAuth = () => React.useContext(AuthContext);

export { AuthProvider, useAuth, AuthContext };
