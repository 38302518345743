import React, { useState,useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import AlertMessage from '../../../../Alerts/AlertMessage';
import { sendPOSTRequest } from '../../../../../adaptors/bff-requests';
import { useAuth } from '../../../../../context';
const PayAtGateDetails=({changeShow,confirmationUserData, reservationId,handleClickPayment,showAlertBox })=> {

  const [emailError, setEmailError] = useState('');
  const [cashPayment, setCashPayment] = useState(true);
  const [cardPayment, setCardPayment] = useState(false);
  const [date, setDate] = useState();
  const[ticketInfo,setTicketInfoValue]=useState(confirmationUserData.reservationDetail);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity,setSeverity]=useState('error');
  const [showAlert, setShowAlert] = useState(false);
  const setAlertMessageInfo = (Severity,message)=>{
    setSeverity(Severity);
    setAlertMessage(message);
    setShowAlert(true);
  }
  const setTicketInfo = (key, value) => {
    setTicketInfoValue((prev) => {
     if(key==='cashReceived')
      {
        return {
          ...prev,
          [key]: value,
          'balance':value-ticketInfo.amount
        };
      }
      return { ...prev, [key]: value };
    });
  };
  const handleCashPaymentChange = (event) => {
    setCashPayment(event.target.checked);
    setCardPayment(!event.target.checked);
    
  };

  const handleCardPaymentChange = (event) => {
    setCardPayment(event.target.checked);
    setCashPayment(!event.target.checked);
    if(event.target.checked)
    {
      setTicketInfo('cashReceived', 0);
      setTicketInfoValue((prev) => {
        return { ...prev, 'balance': 0 };
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () =>  document.getElementById("payatgatephotores")
  });
  const {
    token,
    authorizedUser: { authKey },
  } = useAuth();
  const bookPhotoshootTicket = async (e) => {
    const data = {
      "reservationId": reservationId ,
      "onlinePayment": false
    };
  
    let result;
      result = await sendPOSTRequest({ path: 'pre-shoot/confirm-reservation', payload: data }, token);
      if (result.status === 'error') {
        setAlertMessageInfo('error', 'Please try again later');
      } else if (result.status === 'success') {
        showAlertBox('Reservation Successful');
        handleClickPayment();
      } else {
        setAlertMessageInfo('error', 'Please try again later');
      }

  };
  
  const handleSubmitForm = (event) => {
    event.preventDefault();
    if (cashPayment) {
      setTicketInfoValue((prev) => {
        return { ...prev, 'paymentMethod': 'cash' };
      });
    } else {
      setTicketInfoValue((prev) => {
        return { ...prev, 'paymentMethod': 'card' };
      });
    }
    bookPhotoshootTicket();
  };
  useEffect(() => {
   
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    setDate(dd + '-' + mm + '-' + yyyy)
  },[]);
  
  const validateEmail = (email) => { 
    const isValidEmail = /\S+@\S+\.\S+/.test(email);
    setEmailError(isValidEmail ? '' : 'Invalid email address'); 
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      marginTop: '100px',
      height: '100%',
      width: '100%',
    }}>
    <form id='payatgatephotores' className="reservation-form" onSubmit={handleSubmitForm}>
    <div className="reservation-form__left">
        <label>
          Date :
          <input   value={date} readOnly/>
        </label>
        <br />
        <label>
          First Name :
          <input type="text" value={ticketInfo.firstName} readOnly />
        </label>
        <br />
        <label>
          Last Name :
          <input type="text" value={ticketInfo.lastName} readOnly  />
        </label>
        <br />
        <label >
          Contact Number :
          <input  type="tel" value={ticketInfo.phoneNumber} readOnly/>
        </label>
        <br />
        <label>
          Email Address :
          <input type="email" value={ticketInfo.email} 
          onChange={(e) => {
            setTicketInfo('emailAddress', e.target.value);
            validateEmail(e.target.value); // Validate email address
        }} />
        </label>
        <br />
        <label>
          Reservation Date :
          <input type="date" value={ticketInfo.reservedDate} readOnly />
        </label>
        <br />
        <label>
          Number of People :
          <input  value={ticketInfo.noOfPeople} readOnly />
        </label>
        <br />
      </div>
      <div className="reservation-form__right">
      
        <label>
          Reservation Fee :
          <input  value={ticketInfo.amount}  readOnly/>
        </label>
        <br/>
        <label>
          Cash Received :
          <input type="number" value={ticketInfo.cashReceived} onChange={(e) => setTicketInfo('cashReceived', e.target.value)} disabled={cardPayment} min="0" max="999999999999" required/>
        </label>
        <br/>
        <label>
          Balance :
          <input type="number" value={ticketInfo.balance} readOnly/>
        </label>
        <br/>
        {/* <div className='radioBtn'>
        <label className='radioInput'>
          Cash Payment 
          <input type="radio"  checked={cashPayment} onChange={handleCashPaymentChange} />
        </label>
        <br/>
        <label className='radioInput'>
          Card Payment 
           <input type="radio" checked={cardPayment} onChange={handleCardPaymentChange} />
        </label>
        <br />
        <br />
      </div> */}
      <input type="submit" value='Print' />
    </div>
       </form>
       <button className="button backbtn" onClick={()=>{changeShow()}}> Back </button>
       <AlertMessage
            message={alertMessage}
            show={showAlert}
            closeHandler={(value) => setShowAlert(false)}
            severity={severity}
          />
     </div>
     );
}

export {PayAtGateDetails};