import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper ,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import styles from './style.css';
import { TicketGuideComponent } from './ticket-guide';
import { isNumberOnly } from '../../../../../utils/functions';

export const PersonComponenet = (props) => {
  return (
    <div
      className="twoBoxes"
      style={{ marginLeft: '10px', borderLeft: '2px solid #000000', borderTopLeftRadius: '20px' }}
    >
      <div style={{ marginTop: '30px', marginBottom: '50px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h5">Person</Typography>
          </div>
          <div style={{ display: 'flex', marginTop: '20px', justifyContent: 'space-evenly' }}>
          <TextField
              id="outlined-textarea-two"
              label="No Of Adults"
              disabled={props.isEditMode}
              value={props.selectedNoAdults}
              autoComplete={false}
              type="number"
              inputProps={{ min: 0 }}
              onChange={(e) => {
                const isValid = isNumberOnly(e.target.value);
                props.setSelectedNoAdults(e.target.value);
              }}
            />
            <TextField
              id="outlined-textarea"
              label="No Of Kids"
              disabled={props.isEditMode}
              value={props.selectedNoKids}
              autoComplete={false}
              type="number"
              inputProps={{ min: 0 }}
              style={{ marginLeft: '10px' }}
              onChange={(e) => {
                const isValid = isNumberOnly(e.target.value);
                props.setSelectedNoKids(e.target.value);
              }}
            />
            <Box sx={{ minWidth: 200, marginLeft: '10px' }}>
              <FormControl fullWidth>
                <InputLabel>Nationality</InputLabel>
                <Select
                  disabled={props.isEditMode}
                  value={props.selectedNationality}
                  label="Nationality"
                  onChange={(e) => {
                    props.setSelectedNationality(e.target.value);
                  }}
                >
                  {props.listOfNationalities.map((item) => (
                    <MenuItem value={item.id}>{item.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <div
             style={{
              display:'flex',
              alignItems:'center'
             }}
            >
            <div
              style={{
                display:'flex',
                alignItems:'center',
                backgroundColor: '#1e3932',
                color: 'white',
                border: 'none',
                fontSize: '22px',
                padding: '5px 10px',
                borderRadius: '4px',
                cursor: 'pointer',
                marginLeft: '10px',
                height: '60%',
              }}
              onClick={(event) => {
                event.preventDefault();
                if(!props.isEditMode){
                props.handleAddRow(props.selectedNoAdults,props.selectedNoKids,props.selectedNationality);
                props.setSelectedNoAdults(0);
                props.setSelectedNoKids(0);
              }
              }}
            >
              +
            </div>
            </div>
          </div>
          <div>
          {props.rows?.length > 0 && (
            <div 
            style={{
             maxHeight: '150px',
             overflowY: 'auto'
            }}
           >
            <TableContainer component={Paper}>
              <Table>
              <TableHead>
                  <TableRow>
                    <TableCell>Nationality</TableCell>
                    <TableCell>No of Adults</TableCell>
                    <TableCell>No of Kids</TableCell>
                    <TableCell>Remove</TableCell>
                  </TableRow>
              </TableHead>
                <TableBody>
                  {props.rows?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.nationalityName}</TableCell>
                      <TableCell>{row.adults}</TableCell>
                      <TableCell>{row.kids}</TableCell>
                      <TableCell>
                        <div>
                        <div
                         disabled={props.isEditMode}
                          style={{
                            backgroundColor: '#1e3932',
                            color: 'white',
                            border: 'none',
                            fontSize: '20px',
                            padding: '5px 10px',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            marginLeft: '10px',
                            height: '50%',
                            maxWidth:'min-content',
                            lineHeight:'20px'
                          }}
                          onClick={(event) => {
                            event.preventDefault();
                            if(!props.isEditMode){
                            props.handleRemoveRow(index);}
                          }}
                        >
                          &ndash;
                        </div>
                        </div>
                      </TableCell>
                    </TableRow>
                   
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            </div>
            )}
          </div>
          {!props.isGuide && (
            <TicketGuideComponent
              autocompleteLoading={props.autocompleteLoading}
              listOfGuides={props.listOfGuides}
              onAutoCompleteChange={props.onAutoCompleteChange}
              onAutoCompleteSelect={props.onAutoCompleteSelect}
              onGuideSwitch={props.onGuideSwitch}
              guideCode={props.guideCode}
              isEditMode={props.isEditMode}
            />
          )}
        </div>
      </div>
    </div>
  );
};
