import { PageWrapper } from '../components/Shared';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { BookingWidget } from '../components/Cards';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { sendPOSTRequest } from '../adaptors/bff-requests';
import {
  ContactUs,
  Intro,
  BioDiversity,
  TowerStructure,
  TransportationModes,
  AttractivePlaces,
  Bungalows,
  SiteMap,
  Climate,
} from '../components/MainPageSections';
import { Attractions } from '../components/MainPageSections/Attractions';
import { InformationCenter } from '../components/MainPageSections/InformationCenter';

const useStyles = makeStyles({
  homeImage: {
    height: '95vh',
    width: '100vw',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
  },
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 100,
    height: '100vh',
  },
  homeTextStyles: {
    background:
      '-webkit-linear-gradient(360deg, rgba(0,0,0,0.7105435924369747) 0%, rgba(0,0,0,0.8477984943977591) 46%, rgba(0,0,0,1) 100%);',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
});

const ViewHome = () => {
  const classes = useStyles();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const clientRef = searchParams.get('clientRef');
    const reqid = searchParams.get('reqid');
    console.log('Url Data');
    console.log(clientRef);
    const showPaymentStatus = async () => {
      const paymentPayload = {
        reqId: reqid,
        clientRef: clientRef,
      };


      const paymentResponse = await sendPOSTRequest({
        path: 'complete',
        payload: paymentPayload,
        queryParams: '/payments',
      });
      console.log(paymentResponse);
      if (paymentResponse.status === 'success') {
        Swal.fire({
          icon: 'success',
          title: 'Payment Successful!',
          text: 'Thank you for your payment!',
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = process.env.REACT_APP_RETURN_URL;
          }
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Payment Unsuccessful!',
          text: 'Please try again!',
          confirmButtonText: 'Try again',
          showCancelButton:true
        }).then((result) => {
           if (result.isConfirmed) {
            const paymentPayloaddata = JSON.parse(localStorage.getItem("paymentPayload"));
            const paymentResponse = sendPOSTRequest({ path: 'init', payload: paymentPayloaddata,queryParams:"/payments" }).then((paymentRes)=>{
            console.log('testing', paymentRes);
            window.location.href = paymentRes.data.responseData.paymentPageUrl;
              });
          }else{
            window.location.href = process.env.REACT_APP_RETURN_URL;
          }
        });
      }
    };
    if (clientRef !== null) {
      showPaymentStatus();
    }
  }, []);


  return (
    <PageWrapper style={{ height: '100vh', width: '100wh', backgroundColor: 'red' }}>
      <video
        src="https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Videos/intro.mp4"
        autoplay="{true}"
        loop
        muted
        className={classes.homeImage}
      ></video>

      <div className={classes.homeContainer}>
        <Typography
          variant="h1"
          className={classes.homeTextStyles}
          fontWeight={700}
          zIndex={10}
          style={{ marginLeft: '4rem' }}
        >
          Book your Ambuluwawa
        </Typography>
        <Typography
          variant="h1"
          fontWeight={300}
          className={classes.homeTextStyles}
          zIndex={10}
          style={{ marginLeft: '4rem' }}
        >
          Tickets Online
        </Typography>
      </div>
      <BookingWidget />
      <Intro />
      <TowerStructure />
      <Climate />
      <BioDiversity />
      <Attractions />
      <SiteMap />
      <Bungalows />
      <TransportationModes />
      <ContactUs />
      <InformationCenter />
      <AttractivePlaces />
    </PageWrapper>
  );
};

export { ViewHome };
