import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CircularProgress, IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export default function ListTableData(props) {
  const rows = props.data;
  const allowedActions = props.allowedActions;

  const getRows = useCallback(() => {
    const arr = [];
    let rowArray = [];
    rows
      ?.filter((e) => {
        if (props.name && props.userID) {
          return e.firstname.includes(props.name) && e.id.toString().includes(props.userID);
        } else if (props.name) {
          return e.firstname.includes(props.name);
        } else if (props.userID) {
          return e.id.toString().includes(props.userID);
        }
        return e;
      })
      .map((row, index) => {
        Object.values(row).map((e, i) => {
          return rowArray.push(
            <StyledTableCell component="th" scope="row" align="left">
              {e}
            </StyledTableCell>
          );
        });
        rowArray.push(
          <StyledTableCell align="left" key={row.id}>
            {allowedActions['update-guide'] && ( <Tooltip title="View/Update">
              <IconButton
                aria-label="update"
                size="small"
                onClick={() => props?.onOptionSelected({ key: row.id, value: 'more' })}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>)}
          </StyledTableCell>
        );
        arr.push(<StyledTableRow key={index}>{rowArray}</StyledTableRow>);
        rowArray = [];
        return null;
      });
    return arr;
  }, [rows, props.name, props.userID]);

  const getHeaders = useCallback(() => {
    const arr = [];
    Object.keys(rows.length > 0 ? rows[0] : {}).map((e, i) => {
      arr.push(
        <StyledTableCell key={i} align="left">
          {e.toUpperCase()}
        </StyledTableCell>
      );
      return false;
    });
    arr.push(<StyledTableCell align="left">ACTIONS</StyledTableCell>);
    return arr;
  }, [rows]);

  if (rows) {
    console.log('ROW ', rows.length);
  }
  return (
    <div style={{ padding: '20px', width: '100%', height: '100%' }}>
      {!props.isFetchingList ? (
        <Paper sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
          <TableContainer>
            {rows && rows.length > 0 ? (
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>{getHeaders()}</TableRow>
                </TableHead>
                <TableBody>{getRows()}</TableBody>
              </Table>
            ) : (
              <label>No Data To Show</label>
            )}
          </TableContainer>
        </Paper>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
