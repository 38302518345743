export const userRoles = {
  admin: 'Admin',
  ticketing: 'Ticketing',
  siteManager: 'SiteManager',
  generalIt: 'GeneralIT',
  user: 'User',
  guide: 'Guide',
  entityAdmin:'EntityAdmin'
};

export const inheritedRole = 'INHERIT-PARENT';

export const moduleIds = {
  ticketing: 'ticketing',
  guide: 'guide',
  users: 'users',
  analytics: 'analytics',
  newsAndEvent: 'newsAndEvent',
  feedbacks: 'feedbacks',
  photoshootres: 'photoshootres',
  editpricing: 'editpricing',
  commission: 'commission',
  announcementAndEvent:'announcementAndEvent'
};

export const adminModulesAndPermissions = [
  {
    id: moduleIds.ticketing,
    order: 1,
    displayName: 'Entrance Ticketing',
    subModules: [
      {
        id: 'add-ticket',
        allowedRoles: [inheritedRole],
      },
      {
        id: 'print-ticket',
        allowedRoles: [userRoles.admin, userRoles.generalIt,userRoles.ticketing,userRoles.siteManager],
      },
      {
        id: 'print-ticket-online',
        allowedRoles: [userRoles.admin, userRoles.generalIt,userRoles.ticketing,userRoles.siteManager],
      },
      {
        id: 'view-ticket',
        allowedRoles: [inheritedRole],
      },
      {
        id: 'delete-ticket',
        allowedRoles: [userRoles.admin, userRoles.generalIt],
      },
      {
        id: 'view-sales-info',
        allowedRoles: [userRoles.admin, userRoles.generalIt,userRoles.ticketing,userRoles.siteManager],
      },
    ],
    allowedRoles: [
      userRoles.admin,
      userRoles.generalIt,
      userRoles.ticketing,
      userRoles.siteManager
    ],
  },
  {
    id: moduleIds.photoshootres,
    order: 2,
    displayName: 'PhotoShoot Reservation',
    subModules: [
      {
        id: 'add-ticket',
        allowedRoles: [inheritedRole],
      },

      {
        id: 'view-ticket',
        allowedRoles: [inheritedRole],
      },
      {
        id: 'delete-ticket',
        allowedRoles: [userRoles.admin, userRoles.generalIt],
      },
      {
        id: 'view-sales-info',
        allowedRoles: [inheritedRole],
      },
    ],
    allowedRoles: [userRoles.admin, userRoles.ticketing, userRoles.siteManager],
  },
  {
    id: moduleIds.guide,
    order: 3,
    displayName: 'Tour Guides',
    subModules: [
      {
        id: 'add-guide',
        allowedRoles: [inheritedRole],
      },
      {
        id: 'update-guide',
        allowedRoles: [inheritedRole],
      },
      {
        id: 'delete-guide',
        allowedRoles: [userRoles.admin, userRoles.generalIt],
      },
    ],
    allowedRoles: [
      userRoles.admin,
      userRoles.generalIt,
      userRoles.ticketing,
      userRoles.siteManager,
    ],
  },
  {
    id: moduleIds.users,
    order: 4,
    displayName: 'Users',
    subModules: [
      {
        id: 'add-user',
        allowedRoles: [inheritedRole],
      },
      {
        id: 'update-user',
        allowedRoles: [inheritedRole],
      },
      {
        id: 'delete-user',
        allowedRoles: [userRoles.admin, userRoles.generalIt],
      },
    ],
    allowedRoles: [userRoles.admin, userRoles.generalIt, userRoles.siteManager],
  },
  {
    id: moduleIds.analytics,
    order: 5,
    displayName: 'Analytics',
    subModules: [
      {
        id: 'export',
        allowedRoles: [inheritedRole],
      },
    ],
    allowedRoles: [userRoles.admin, userRoles.generalIt, userRoles.siteManager],
  },
  {
    id: moduleIds.newsAndEvent,
    order: 6,
    displayName: 'News/Event',
    subModules: [],
    allowedRoles: [userRoles.admin, userRoles.mediaPerson],
  },
  {
    id: moduleIds.feedbacks,
    order: 7,
    displayName: 'Feedbacks',
    subModules: [
      {
        id: 'view-feedbacks',
        allowedRoles: [userRoles.admin, userRoles.generalIt, userRoles.siteManager],
      },
      {
        id: 'edit-feedbacks',
        allowedRoles: [userRoles.admin, userRoles.generalIt],
      },
      {
        id: 'delete-feedbacks',
        allowedRoles: [userRoles.admin, userRoles.generalIt],
      },
      {
        id: 'download-feedbacks',
        allowedRoles: [userRoles.admin, userRoles.generalIt],
      },
    ],
    allowedRoles: [userRoles.admin, userRoles.siteManager],
  },
  {
    id: moduleIds.editpricing,
    order: 8,
    displayName: 'Edit Pricing',
    subModules: [],
    allowedRoles: [userRoles.generalIt, userRoles.admin],
  },
  {
    id: moduleIds.commission,
    order: 8,
    displayName: 'Guide Expenses',
    subModules: [
      {
        id: 'select-entity',
        allowedRoles: [userRoles.admin, userRoles.generalIt, userRoles.siteManager,userRoles.ticketing,userRoles.guide],
      },
      {
        id: 'select-guide',
        allowedRoles: [userRoles.admin, userRoles.generalIt, userRoles.siteManager,userRoles.ticketing,userRoles.entityAdmin],
      },
      {
        id: 'give-commission',
        allowedRoles: [userRoles.admin, userRoles.generalIt, userRoles.siteManager,userRoles.ticketing,userRoles.entityAdmin],
      }
    ],
    allowedRoles: [userRoles.admin, userRoles.generalIt, userRoles.siteManager,userRoles.ticketing,userRoles.entityAdmin,userRoles.guide],
  },
  {
    id: moduleIds.announcementAndEvent,
    order: 9,
    displayName: 'Announcements',
    subModules: [],
    allowedRoles: [userRoles.admin, userRoles.mediaPerson],
  },
];

export function getAllowedAdminModulesForRole(role) {
  const allowedModulesForUser = adminModulesAndPermissions.filter((module) =>
    module.allowedRoles.some((r) => r === role)
  );
  allowedModulesForUser.sort((a, b) => a.order - b.order);
  return allowedModulesForUser;
}
