import React, { useCallback, useEffect, useState } from 'react';
import TourGuide from './components/create-new/tour-guide-registration';
import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useAuth } from '../../../context';
import { moduleIds } from '../../../utils/rolesAndPermissions';

export default function RegistraionScreen() {
  const { token, permissionsForAllowedModules } = useAuth();

  const allowedActions =
    (permissionsForAllowedModules && permissionsForAllowedModules[moduleIds.guide]) || {};

  const [tourGuideList, setTourGuideList] = useState();
  const [isFetchingList, setIsFetchingList] = useState(true);

  const listTourGuides = useCallback(async () => {
    setIsFetchingList(true);
    const resp = await sendPOSTRequest({ path: 'user/guide/get', payload: { page: 1 } }, token);
    if (resp.status === 'success') {
      setTourGuideList(resp.data);
      setIsFetchingList(false);
    } else {
      setIsFetchingList(false);
      alert('Failed to fetch tour guides');
    }
  }, [token]);

  useEffect(() => {
    listTourGuides();
  }, [listTourGuides]);

  return (
    <div style={{ height: '85vh', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <TourGuide
            tourGuideList={tourGuideList}
            listTourGuides={listTourGuides}
            isFetchingList={isFetchingList}
            allowedActions={allowedActions}
          />
        </div>
      </div>
    </div>
  );
}
