import { CssBaseline, ThemeProvider } from '@mui/material';
import { AuthProvider } from './context';
import Routes from './Routes';
import { theme } from './theme';
import 'react-datepicker/dist/react-datepicker.css';
import Amplify from 'aws-amplify';
import AlertMessage from './components/Alerts/AlertMessage';

import { useAlertStore } from './stores';

//Configure this when we use cognito
const config = {
  Auth: {
    region: process.env.REACT_APP_USER_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
};

Amplify.configure(config);

const App = () => {
  const fnOnHide = useAlertStore((state) => state.onHide);
  const showAlert = useAlertStore((state) => state.show);
  const payload = useAlertStore((state) => state.payload);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Routes />
        <AlertMessage
          message={payload.message}
          severity={payload.severity}
          autoHideDuration={payload.duration}
          show={showAlert}
          closeHandler={(value) => fnOnHide()}
        />
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
