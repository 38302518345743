import React, { useState, useEffect } from 'react';
import { Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';

export default function TourGuide(props) {
  const [tourGuideList, setTourGuideList] = useState([]);

  useEffect(() => {
    setTourGuideList(props.data);
    console.log(props.data);
  }, [props.data]);

  const [filteredData, setFilteredData] = useState([]);
  const [rankedData, setRankedData] = useState([]);

  useEffect(() => {
    const sortedData = [...tourGuideList].sort((a, b) => b.totalsales - a.totalsales);
    const rankedData = sortedData.map((tourGuide, index) => ({ ...tourGuide, rank: index + 1 }));
    setFilteredData(rankedData);
    setRankedData(rankedData);
  }, [tourGuideList]);

  const handleSearch = (e) => {
    const query = e.target.value;
    const filtered = rankedData.filter((tourGuide) => tourGuide.userid.toString().includes(query));
    setFilteredData(filtered);
  };

  const thStyle = {
    background: '#212121',
    fontFamily: 'revert',
    padding: '12px',
    color: 'white',
    fontSize: '16px',
  };

  return (
    <Box sx={{ margin: '14px' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h1 style={{ fontFamily: 'revert' }}>Tour Guides Ranking</h1>
      </div>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          onChange={handleSearch}
          label="Search By User Id"
          variant="standard"
          sx={{ width: '15%' }}
        />
      </Box>
      <TableContainer sx={{ marginTop: '24px' }}>
        <Table>
          <TableHead>
            <TableRow style={thStyle}>
              <TableCell
                align="left"
                style={Object.assign({}, thStyle, { borderTopLeftRadius: '10px' }, { color: '#fff' })}
              >
                Rank
              </TableCell>
              <TableCell align="right" style={Object.assign({}, thStyle, { color: '#fff' })}>
                User Id
              </TableCell>
              <TableCell align="right" style={Object.assign({}, thStyle, { color: '#fff' })}>
                Total Income
              </TableCell>
              <TableCell
                align="right"
                style={Object.assign({}, thStyle, { borderTopRightRadius: '10px' }, { color: '#fff' })}
              >
                Total Sales
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.length === 0 ? (
              <TableRow>
                <TableCell align="center" colSpan={5}>Guide id not found</TableCell>
              </TableRow>
              ) : (
            filteredData.map((tourGuide) => (
              <TableRow key={tourGuide.userid}>
                <TableCell align="left">{tourGuide.rank}</TableCell>
                <TableCell align="right">{tourGuide.userid}</TableCell>
                <TableCell align="right">{tourGuide.totalincome}</TableCell>
                <TableCell align="right">{tourGuide.totalsales}</TableCell>
              </TableRow>
            ))
          )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};