import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';

const useStyles = makeStyles({
  background: {
    backgroundColor: '#EBFFEE',
    paddingTop: '3rem',
    paddingBottom: '2rem',
  },
  main: {
    display: 'flex',
    overflowX: 'scroll',
  },
});

const list = [
  {
    img: 'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Home/CB2.jpg',
    name: 'Chairmen Bunglow',
    desc: 'As the Ambuluawa complex is a place that attracts more tourists, this tourist Bungalow No. 02 or CB-02 has now been able to create the most luxurious style. As this has  located in a breathtaking location on Ambuluawa Peak, this bungalow is known as the “Chairman Bungalow”.',
  },
  {
    img: 'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Home/CB3.jpg',
    name: 'Arbour Boutique Hotel',
    desc: 'This tourist bungalow number 03 belonging to the Ambuluawa complex has now become a place where more people come to get accommodation facilities. Since this has located in a place with a natural beauty, this bungalow has always attracted the attention of tourists. The location of the helipad and the proximity to the Ambulu lake has made this bungalow look even more beautiful. And this bungalow is currently known as “The Arbor”.',
  },

  {
    img: 'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Home/CB4.jpg',
    name: 'Circuit Bungalow',
    desc: 'Bungalow No. 04 is another tourist bungalow of the Ambuluawa complex and it has situated in a beautiful location surrounded by beautiful pines. The fact that this bungalow has been modernized with all the necessary facilities for accommodation has become a major reason for the tourist attention to be drawn to this place in recent times. A scenic environment where natural beauty stands out is found around here.',
  },
];

const Bungalows = () => {
  const classes = useStyles();

  return (
    <Box pt="6rem">
      <Box px="2rem" className={classes.background}>
        <Typography
          variant="h2"
          sx={{
            fontSize: '4vh',
            fontFamily: 'revert',
            paddingBottom: '1rem',
            textAlign: 'center',
          }}
        >
          Bungalow Reservation
        </Typography>
        <Box py="2rem" className={classes.main}>
          {list.map((item) => (
            <Box pl="4rem">
              <Card sx={{ width: '30rem', height: '28rem' }}>
                <CardMedia component="img" alt={item.name} height="220rem" image={item.img} />
                <CardContent>
                  <Typography gutterBottom variant="h5">
                    {item.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.desc}
                    {/* {item.desc.length > 180
                      ? item.desc.substring(0, 180) + '...'
                      : item.desc.substring(0, 180)} */}
                  </Typography>
                </CardContent>

                {/* <Box align="center" py="2rem">
                  <Button size="large" variant="contained" backgroundColor="#FFFFFF">
                    Reserve
                  </Button>
                </Box> */}
              </Card>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export { Bungalows };
