import React, { useState, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import { isNumeric } from '../../../shared/functions';

export const PriceComponent = (props) => {
  const showButton = !props.isEditMode || (props.isEditMode && props.isPaymentPending && !props.isGuide);
  const [barcode, setBarcode] = useState('');
  const barcodeInputRef = useRef(null);

  const handleAddBarcode = () => {
    if (barcode.length > 4) {
      props.setScannedBarcodes((prevBarcodes) => [...prevBarcodes, barcode]);
      setBarcode('');
      barcodeInputRef.current.focus();
    }
  };

  const handleRemoveBarcode = (index) => {
    props.setScannedBarcodes((prevBarcodes) => [
      ...prevBarcodes.slice(0, index),
      ...prevBarcodes.slice(index + 1),
    ]);
  };
  return (
    <div class="price" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          width: '100%',
          marginTop: '30px',
        }}
      >
        {!props.isGuide && (
          <div style={{ width: '50%', display: 'flex', justifyContent: 'space-evenly' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                style={{ marginBottom: '20px' }}
                id="outlined-textarea-subTotal"
                value={props.subTotal}
                placeholder="Amount to be paid"
              />
              <TextField
                style={{ marginBottom: '20px' }}
                id="outlined-textarea-paid-amount"
                label="Cash Received"
                type="text"
                value={props.paidAmount}
                disabled={!props.subTotal || !props.isPaymentPending}
                onChange={(e) => {
                  const val = e.target.value;
                  if (isNumeric(val) || e.nativeEvent.inputType === 'deleteContentBackward') {
                    props.setPaidAmount(e.target.value);
                  }
                }}
              />
              <TextField
                style={{ marginBottom: '20px' }}
                id="outlined-textarea-balance"
                disabled={!props.isPaymentPending}
                value={props.balance}
                placeholder="Balance"
              />
            </div>
            <div>
              <Box sx={{ minWidth: 200 }}>
                <FormControl fullWidth>
                  <InputLabel>Payment Method</InputLabel>
                  <Select
                    disabled
                    value={props.nationality}
                    label="Payment Method"
                    defaultValue={props.paymentMethod}
                    onChange={(e) => {
                      props.setPaymentMethod(e.target.value);
                    }}
                  >
                    {/* <MenuItem value="cash">
                    Online
                  </MenuItem> */}
                    <MenuItem value="cash" selected>
                      Pay at Gate
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

            </div>
            <Box>
              <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <TextField
                  id="outlined-textarea-barcode"
                  label="Barcode"
                  value={barcode}
                  autoComplete={false}
                  type="text"
                  onChange={(e) => {
                    setBarcode(e.target.value);
                  }}
                  inputRef={barcodeInputRef}
                />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#1e3932',
                    color: 'white',
                    border: 'none',
                    fontSize: '22px',
                    padding: '5px 10px',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    marginLeft: '10px',
                    height: '60%',
                  }}
                  onClick={handleAddBarcode}
                >
                  +
                </div>
              </div>
              {props.scannedBarcodes.length > 0 && (
                <div style={{ maxHeight: '150px', overflowY: 'auto' }}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Barcode</TableCell>
                          <TableCell>Remove</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {props.scannedBarcodes.map((barcode, index) => (
                          <TableRow key={index}>
                            <TableCell>{barcode}</TableCell>
                            <TableCell>
                              <div
                                style={{
                                  backgroundColor: '#1e3932',
                                  color: 'white',
                                  border: 'none',
                                  fontSize: '20px',
                                  padding: '5px 10px',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  marginLeft: '10px',
                                  height: '50%',
                                  maxWidth: 'min-content',
                                  lineHeight: '20px',
                                }}
                                onClick={() => handleRemoveBarcode(index)}
                              >
                                &ndash;
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </Box>
          </div>
        )}
        <div style={{ width: '50%' }}>
          <div
            style={{
              border: '2px solid #000000',
              borderRadius: '20px',
              padding: '20px',
              margin: '20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h3">LKR {props.subTotal}.00</Typography>
                <Typography variant="h5">TOTAL</Typography>
              </div>
              {showButton && (
                <Button
                  style={{ width: '200px', marginTop: '30px' }}
                  variant="contained"
                  disabled={!props.subTotal || props.isLoading}
                  onClick={props.onPrint}
                >
                  {!props.isGuide ? 'Print' : 'Reserve'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
