import React, { useState,useEffect } from 'react';
import { DetailsTicketing } from './DetailsTicketing-PAG';
import { sendPOSTRequest } from '../../adaptors/bff-requests'
import { useAuth } from '../../context';
import AlertMessage from '../Alerts/AlertMessage';
const TicketingPayAtGate=()=> {
  const {
    token,
    authorizedUser: { authKey },
  } = useAuth();
  const [alertMessage, setAlertMessage] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const [ticket, setTicket] = useState();
  const [confirmation, setConfirmation] = useState();
  const [date, setDate] = useState();
  const [UserID, setUserID] = useState();
  const [showPayDetail, setShowPayDetail] = useState(true);
  const handleConfirmationChange = (event) => {
    setConfirmation(event.target.value);
  };
  const changeShow =()=>
  {
    setShowPayDetail(!showPayDetail)
  }
  useEffect(() => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    setDate(dd + '-' + mm + '-' + yyyy);
    setUserID(101);
  }, []);
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.preventDefault();
    const payload={
      "ticketId":confirmation
    }
    const response = await sendPOSTRequest({ path: 'ticket/get-by-id', payload},token)
    console.log("this is response",response);
    if(response.status==='success')
    {
      if(response.data.ticketDetails.paymentStatus==="completed")
      {
        setErrorAlert(true);
        setAlertMessage('Payment is already done');
        return null;
      }
      else
      {
      setTicket(response.data);
      changeShow();
      }
    }
    else
    {
      setErrorAlert(true);
      setAlertMessage('Please check your confirmation number');
      return null;
    }
  };

  return (
    <div className='payatgate'>
    {showPayDetail && ( 
    <>
    <div  style={{ marginTop:'130px'}} className='.reservation-form'>
    <form className="reservation-form-middle" onSubmit={handleSubmit}>
          <label>
            Ticketing Counter :
            <input type="text" value={UserID} readOnly />
          </label>
          <br />
          <div>
            <label>
              Date :
              <input value={date} readOnly />
            </label>
            <br />
            <label>
              Confirmation Number :
              <input type="text" value={confirmation} onChange={handleConfirmationChange} required />
            </label>
            <br />

            <input type="submit" />
          </div>
        </form>
        </div>
        </>
      )}
    {!showPayDetail && <DetailsTicketing changeShow={changeShow} ticket={ticket}/>}
    <AlertMessage
            message={alertMessage}
            show={errorAlert}
            closeHandler={(value) => setErrorAlert(false)}
          />
     </div>
     );
}
export { TicketingPayAtGate };