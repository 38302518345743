import { Box, Link } from '@mui/material';
import React from 'react';

export default function Nav(props) {
  return (
    <Box className="sideBar">
      {props.listOfModules.map((module) => {
        return (
          <Link
            key={`adnav-${module.id}`}
            onClick={() => props.setSelectedComponenet(module.id)}
            underline="none"
            className={props.selectedComponenet === module.id ? 'activeLink' : 'sideBarButton'}
          >
            {module.displayName}
          </Link>
        );
      })}
      <Link
        onClick={() => props.setSelectedComponenet('profile')}
        underline="none"
        className={props.selectedComponenet === 'profile' ? 'activeLink' : 'sideBarButton'}
      >
        Profile
      </Link>
    </Box>
  );
}
