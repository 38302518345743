import { CircularProgress } from '@mui/material';

const SpinnerComponent = ({ style = {} }) => {
  const defaultStyle = { position: 'absolute', top: '50%', left: '50%' };
  return (
    <div style={{ ...defaultStyle, ...style }}>
      <CircularProgress color="inherit" />
    </div>
  );
};

export { SpinnerComponent };
