import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material';

export default function ConfirmModal({
  title,
  message,
  open,
  handleClose,
  handleConfirmation,
  showTextInput=false,
}) {
  const [textInputValue, setTextInputValue] = useState('');

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <p>{message}</p>
        {showTextInput && (
          <TextField
            label="Enter Value"
            value={textInputValue}
            onChange={(e) => setTextInputValue(e.target.value)}
            fullWidth
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          onClick={() => handleConfirmation(textInputValue)}
          style={{
            backgroundColor: '#1E3932',
            color: 'white',
            padding: '8px 16px',
            borderRadius: '10px',
            cursor: 'pointer',
            border: 'none',
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
