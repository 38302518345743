export function isNumeric(str) {
  if (typeof str != 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export function isValideEmail(email) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

export function IsPhoneNumber(num) {
  const re = /^\d{8,10}$/;
  return re.test(num);
}

export function IsPassword(pass) {
  const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;
  return re.test(pass);
}

export function IsAccountNum(num) {
  const re = /^[0-9]{9,18}$/;
  return re.test(num);
}
