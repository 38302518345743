import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Ticketing from '../components/Admin/onsite-tickets';
import { PageWrapper } from '../components/Shared';
import styles from './styles.css';
import Nav from '../components/Admin/layout/nav';
import RegistraionScreen from '../components/Admin/tour-guide';
import Profile from '../components/Admin/profile';
import Users from '../components/Admin/users';
import Analytics from '../components/Admin/analytics';
import NewsAndEvent from '../components/Admin/newsAndEvent';
import Feedbacks from '../components/Admin/feedbacks';
import { PhotoShootTicketing } from '../components/Admin/photoshootReservation/OnsiteBook/index';
import EditPricing from '../components/Admin/edit-pricing';
import Commission from '../components/Admin/commission';
import Announcement from '../components/Admin/announcementAndEvent/Announcement';

import { useAuth } from '../context';

const AdminPage = () => {
  const { authorizedUser, allowedAdminModules } = useAuth();

  const [selectedComponenet, setSelectedComponenet] = useState('');

  const getView = () => {
    switch (selectedComponenet) {
      case 'ticketing':
        return <Ticketing />;
      case 'guide':
        return <RegistraionScreen />;
      case 'users':
        return <Users />;
      case 'analytics':
        return <Analytics />;
      case 'profile':
        return <Profile />;
      case 'newsAndEvent':
        return <NewsAndEvent /> ;
      case 'feedbacks':
        return <Feedbacks />;    
      case 'photoshootres':
        return <PhotoShootTicketing />;
      case 'editpricing':
        return <EditPricing />  
      case 'commission' :
        return <Commission />;    
        case 'announcementAndEvent':
          return <Announcement/>        
      default:
        break;
    }
  };

  useEffect(() => {
    if (authorizedUser) {
      const mainModule =
        allowedAdminModules && allowedAdminModules.length > 0
          ? allowedAdminModules[0].id
          : 'profile';
      setSelectedComponenet(mainModule);
    }
  }, [authorizedUser, allowedAdminModules]);

  return (
    <PageWrapper
      props={{
        hideFooter: true,
      }}
    >
      <Box className="main">
        <Nav
          listOfModules={allowedAdminModules}
          selectedComponenet={selectedComponenet}
          setSelectedComponenet={setSelectedComponenet}
        />
        <Box className="body">{getView()}</Box>
      </Box>
    </PageWrapper>
  );
};

export { AdminPage };
