import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const defaultOptions = {
  responsive: {
    width: '100%',
    height: '100%',
  },
  pieChart: {
    width: 500,
    height: 500,
    margin: {
      top: 5,
      right: 8,
      left: 8,
      bottom: 5,
    },
  },
};

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#C96CF1'];

export default function CustomPieChart({
  data,
  dataKey,
  nameKey,
  dimensionProps = defaultOptions.pieChart,
  responsive = defaultOptions.responsive,
  paddingAngle = 0,
  customToolTip,
  customLabel,
  legendPosition = 'bottom',
  labelLine = false,
}) {
  return (
    <ResponsiveContainer width={responsive.width} height={responsive.height}>
      <PieChart
        width={dimensionProps.width}
        height={dimensionProps.height}
        margin={dimensionProps.margin}
      >
        {customToolTip ? <Tooltip content={(options) => customToolTip(options)} /> : <Tooltip />}
        <Legend verticalAlign={legendPosition} />
        <Pie
          data={data}
          nameKey={nameKey}
          cx="50%"
          cy="50%"
          paddingAngle={paddingAngle}
          labelLine={labelLine}
          outerRadius={100}
          dataKey={dataKey}
          label={customLabel ? customLabel : true}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
      </PieChart>
    </ResponsiveContainer>
  );
}
