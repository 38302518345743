import { Button, Input, InputLabel, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { useState, useEffect } from 'react';
import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useAuth } from '../../../context';

export function News() {
  const [uploadedImage, setUploadedImage] = useState(null);
  const { token } = useAuth();
  const [formFields, setFormFields] = useState({
    image: '',
    status: '',
    title: '',
    date: '',
    description: '',
  });
  const [eventsPerYear, setEventsPerYear] = useState(0);
  const currentYear = new Date().getFullYear();

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (files && files[0]) {
      setUploadedImage(files[0]);
    }
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const statusTemp = formFields.status === 'Upcoming' ? false : true;

    if (1) {
      setFormFields({
        image: '',
        status: '',
        title: '',
        date: '',
        description: '',
      });

      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result;
        const createPayload = {
          "isNews": true,
          "title": formFields.title,
          "description": formFields.description,
          "isOngoing": statusTemp,
          "effectiveDate": formFields.date,
          "files": [base64Image.toString(),base64Image.toString()],
        };
        const createResponse = await sendPOSTRequest(
          {
            path: 'new',
            payload: createPayload,
            queryParams: '/news-event',
          },
          token
        );

        console.log('The create response is:');
        console.log(createResponse);

        if (createResponse.status === 'success') {
          Swal.fire('Created!', 'The news has been created.', 'success');
          //window.location.reload();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Internal Server Error',
            text: 'The news was not created. Please try again later.',
          });
        }
      };

      reader.readAsDataURL(uploadedImage);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Limit Reached',
        text: 'You have reached the maximum number of events for this year.',
      });
    }
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: '15px' }}>
          <InputLabel htmlFor="image">Image *</InputLabel>
          <Input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleInputChange}
            required
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="Status"
            name="status"
            value={formFields.status}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="News Title"
            name="title"
            value={formFields.title}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </div>
        <div style={{ marginTop: '25px' }}>
          <TextField
            label="News Date"
            name="date"
            type="date"
            value={formFields.date}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="Description"
            name="description"
            value={formFields.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            required
          />
        </div>
        {/* <Button variant='contained' type="submit" style={{ width: '10em', padding: '8px 0', fontSize: '1em', marginTop:'20px'}}>
      Add News
    </Button> */}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            type="submit"
            style={{ width: '10em', padding: '8px 0', fontSize: '1em', marginTop: '20px' }}
          >
            Add News
          </Button>
        </div>
      </form>
    </div>
  );
}
