import React, { useState, useEffect, useCallback } from 'react';
import { EntranceTicket } from './EntranceTicket';
import { EntranceTicketWithVisitors } from './EntranceTicketWithVisitors';
import { PaymentDetails } from './PaymentDetails';
import { EntranceResSuccess } from './EntranceResSuccess';
import dayjs from 'dayjs'
import {sendPOSTRequest} from '../../../adaptors/bff-requests'
import './entranceTicket.css';
const OnlineEntranceRes =  ({bookingDate, people, nationality}) => {
  const [ticketInfo, setTicketInfoValue] = useState({ 'reservationFee': 1000, 'numberOfPeople': 1, 'numberofAdults': 1, 'numberofKids': 0, 'paymentMethod': 'card', 'currency': 'LKR', 'date': '', 'car': 0, 'bike': 0, 'threewheel': 0, 'jeep': 0, 'van': 0, 'bus': 0, "countryCode": "+94"}); const [paymentDetails, setPaymentDetails] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [visitorCostDetails,setTicketPriceData ]=useState(); 
  const [vehicleCostDetails,setVechiePriceData] =useState();  
  const [listOfNationalities, setNationalities] = useState([]);
  const [subTotal, setSubTotal] = useState();
  const [apiResponse ,setApiResponse ] = useState();
  const [rows, setRows] = useState([]);
  const [selectedNoAdults, setSelectedNoAdults] = useState(1);
  const [selectedNoKids, setSelectedNoKids] = useState(0);
  const [selectedNationality, setSelectedNationality] = useState('212');
  const setTicketInfo = (key, value) => {
    setTicketInfoValue(prevState => {
      if (prevState[key] === value) {
        return prevState;
      }
      if (key === 'numberOfPeople') {
        return {
          ...prevState,
          [key]: value
        };
      }
      if (key === 'numberofAdults') {
        return {
          ...prevState,
          [key]: value, ['reservationFee']: value * 1000 + ticketInfo.numberofKids * 500
        };
      }
      if (key === 'paymentMethod') {
        return {
          ...prevState,
          [key]: value
        };
      }
      if (key === 'numberofKids') {
        return {
          ...prevState,
          [key]: value, ['reservationFee']: value * 500 + ticketInfo.numberofAdults * 1000
        };
      }
      if (key === 'visitortype') {
        if (value == 'local') {
          return {
            ...prevState,
            [key]: value, ['country']:212
          };
        }
        else{
          return {
            ...prevState,
            [key]: value,['country']:1
          };
        }
      }
      return {
        ...prevState,
        [key]: value
      };
    });
  };
  const getCostPerVehicle = useCallback(
    (vehicle, countIs) => {
      return vehicleCostDetails
        .filter((e) => {
          return e.type === vehicle;
        })
        .map((e) => {
          return parseInt(e.cost) * parseInt(countIs);
        });
    },
    [vehicleCostDetails]
  );

  const getPersonCost = useCallback(
    (type, countIs) => {
      return visitorCostDetails
        .filter((e) => {
          return e.type === type;
        })
        .map((e) => {
          return parseInt(e.cost) * parseInt(countIs);
        });
    },
    [visitorCostDetails]
  );
  const getNationalities = useCallback(async () => {
    const nationalityResp = await sendPOSTRequest({ path: 'nationality/get' });
    if (nationalityResp) {
      const topTenNationalites = nationalityResp.data.topTenCountries || [];
      const nationalities = nationalityResp.data.allCountries || [];
      const topTenCountryIds = topTenNationalites.map(country => country.id);
      const filteredNationalities = nationalities.filter(country => !topTenCountryIds.includes(country.id));
      setNationalities(topTenNationalites.concat(filteredNationalities));
    }
  },[]);
  const calculate = useCallback(() => {
    try {
      let kidsTotal = 0;
      let adultTotal = 0; 
      let bikesTotal = 0;
      let threewheelTotal = 0;
      let vanTotal = 0;
      let carTotal = 0;
      let busTotal = 0;

      if (selectedNationality==212) {
        if (selectedNoAdults >0) {
          adultTotal += getPersonCost(`local-adults`, selectedNoAdults)[0]
        }
        if (selectedNoKids >0) {
          kidsTotal += getPersonCost(`local-kids`, selectedNoKids)[0]
        }
      }else{
        if (selectedNoAdults >0) {
          adultTotal += getPersonCost(`tourist-adults`, selectedNoAdults)[0]
        }
        if (selectedNoKids >0) {
          kidsTotal += getPersonCost(`tourist-kids`, selectedNoKids)[0]
        }
      }

      for (let i = 0; i < rows.length; i++) {
        const element = rows[i];
        if (element.nationality==212) {
          if (element.adults >0) {
            adultTotal += getPersonCost(`local-adults`, element.adults)[0]
          }
          if (element.kids >0) {
            kidsTotal += getPersonCost(`local-kids`, element.kids)[0]
          }
        }else{
          if (element.adults >0) {
            adultTotal += getPersonCost(`tourist-adults`, element.adults)[0]
          }
          if (element.kids >0) {
            kidsTotal += getPersonCost(`tourist-kids`, element.kids)[0]
          }
        }
      }

      if (ticketInfo.bike > 0) {
        bikesTotal = getCostPerVehicle('Bike',ticketInfo.bike)[0];
      }
      if (ticketInfo.threewheel > 0) {
        threewheelTotal = getCostPerVehicle('Three-Wheeler', ticketInfo.threewheel)[0];
      }
      if (ticketInfo.van > 0) {
        vanTotal = getCostPerVehicle('Van', ticketInfo.van)[0];
      }
      if (ticketInfo.car > 0) {
        carTotal = getCostPerVehicle('Car',ticketInfo.car)[0];
      }
      if (ticketInfo.bus > 0) {
        busTotal = getCostPerVehicle('Bus', ticketInfo.bus)[0];
      }
      const tot =
        kidsTotal + adultTotal + bikesTotal + threewheelTotal + vanTotal + carTotal + busTotal;
      if(ticketInfo.currency==='USD')
      {
        setSubTotal((tot/320).toFixed(2));
      }
      else
      {
        setSubTotal(tot);
      }
    } catch (er) {}
  }, [getCostPerVehicle, getPersonCost, ticketInfo.bike, ticketInfo.bus, ticketInfo.car, ticketInfo.threewheel, ticketInfo.van,ticketInfo.currency,rows,selectedNoAdults, selectedNoKids, selectedNationality]);
 
  function getCountryNameById(id)  {
    id = parseInt(id);
    const foundObject = listOfNationalities.find(item => item.id === id);
    return foundObject ? foundObject.name : null;
  }
  const handleAddRow = useCallback((selectedNoAdults, selectedNoKids, selectedNationality) => {
    if(selectedNoAdults=="0"&& selectedNoKids=="0"){
      return;
    }
    const nationalityName = getCountryNameById(selectedNationality);
    console.log(typeof selectedNationality);
    const newRow = { adults: selectedNoAdults, kids: selectedNoKids, nationality: selectedNationality, nationalityName: nationalityName };
  
    const existingRowIndex = rows.findIndex(row => row.nationality === selectedNationality);
  
    if (existingRowIndex !== -1) {
      const updatedRows = [...rows];
      updatedRows[existingRowIndex] = newRow;
      setRows(updatedRows);
    } else {
      setRows([...rows, newRow]);
    }
  },[rows]);

  useEffect(()=>{

  },[rows]);

  const handleRemoveRow = (index ) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };
  const changeCurrentPage = async (page) => {
    
    if(page < 4){
      setCurrentPage(page);
    }
    // setCurrentPage(page);

    const vehicles = [];
  
      
    if (ticketInfo.bike > 0) {
      vehicles.push({
        vehicleId: 25,
        count: ticketInfo.bike
      });
    }
    
    if (ticketInfo.threewheel > 0) {
      vehicles.push({
        vehicleId: 26,
        count: ticketInfo.threewheel
      });
    }
    
    if (ticketInfo.car > 0) {
      vehicles.push({
        vehicleId: 23,
        count: ticketInfo.car
      });
    }
    
    if (ticketInfo.van > 0) {
      vehicles.push({
        vehicleId: 24,
        count: ticketInfo.van
      });
    }
    
    if (ticketInfo.bus > 0) {
      vehicles.push({
        vehicleId: 27,
        count: ticketInfo.bus
      });
    }

    const visitors= [];

    rows.forEach(i => {
        let visitor= {};
    
        if (i.nationality == '212') {
          visitor = {
              'local-kids': String(i.kids),
              'local-adults': String(i.adults),
              nationalityId: String(i.nationality),
          };
        } else {
          visitor = {
              'tourist-kids': String(i.kids),
              'tourist-adults': String(i.adults),
              nationalityId: String(i.nationality),
          };
        }
    
        visitors.push(visitor);
    });
    
    if(page===4){
      const paymentMode = ticketInfo.paymentMethod === 'cash'? false : true;


      const payload= {
        "reservation": {
            "firstName": ticketInfo.firstName,    //optional
            "lastName": ticketInfo.lastName,   //optional
            "mobile": ticketInfo.countryCode+ ticketInfo.phoneNumber,  //mandatory
            "email": ticketInfo.emailAddress,  //optional
            "isNightOpertion": ticketInfo.nightOpsEnabled,  //optional - we consider defult as false
            "dateOfArrival": ticketInfo.date,//mandatory if night operation
            ...(ticketInfo.nightOpsEnabled && { exitTime: ticketInfo.visitTimeTo })
        },
        "visitors": visitors,
        "vehicles":vehicles,
        "onlinePayment": paymentMode
    }
    
      const response = await sendPOSTRequest({ path: 'ticket/new-online', payload: payload }).then((res)=>{
        setApiResponse(res);
        setCurrentPage(page);
      });
      console.log(response)
    
    }
    if(page===5){

      const paymentMode = ticketInfo.paymentMethod === 'cash'? false : true;
      console.log("in payment function")


      const payload= {
        "reservation": {
            "firstName": ticketInfo.firstName,    //optional
            "lastName": ticketInfo.lastName,   //optional
            "mobile": ticketInfo.countryCode+ ticketInfo.phoneNumber,  //mandatory
            "email": ticketInfo.emailAddress,  //optional
            "isNightOpertion": ticketInfo.nightOpsEnabled,  //optional - we consider defult as false
            "dateOfArrival": ticketInfo.date,
            ...(ticketInfo.nightOpsEnabled && { exitTime: ticketInfo.visitTimeTo })
        },
        "visitors": visitors,
        "vehicles": vehicles,
        "onlinePayment": paymentMode
    }
      console.log("In newonline")
      const response = sendPOSTRequest({ path: 'ticket/new-online', payload: payload }).then((res)=>{
        console.log(res);

        const confirmationId = res.data.ticketId;
        
        
        const paymentPayload = {
          "amount": subTotal,
          "currency": ticketInfo.currency,
          "returnUrl": process.env.REACT_APP_RETURN_URL,
          "paymentType": "TICKET",
          "comment": "test",
          "confirmationId": confirmationId
        }
        localStorage.setItem('payload', JSON.stringify(payload));
        localStorage.setItem('paymentPayload', JSON.stringify(paymentPayload));

        if (confirmationId) {
          const paymentResponse = sendPOSTRequest({ path: 'init', payload: paymentPayload,queryParams:"/payments" }).then((paymentRes)=>{
            console.log('testing onlineres', paymentRes);
            window.location.href = paymentRes.data.responseData.paymentPageUrl;
          });
        }
      });
    }
  }
  useEffect( () => {
    async function fetchData() {
      const visitorCostResp = await sendPOSTRequest({ path: 'visitor/get-types' });
      setTicketPriceData(visitorCostResp ? visitorCostResp.data || [] : []);
    const vehicleCostResp = await sendPOSTRequest({ path: 'vehicle/get' });
    setVechiePriceData(vehicleCostResp ? vehicleCostResp.data || [] : []);
    getNationalities();
    }
    fetchData();
    setTicketInfo('visitortype',nationality);
    setTicketInfoValue((prev) => {
      return { ...prev,"numberofAdults":people ,'visitortype':nationality,'date':bookingDate};
    });
  }, []);
  useEffect(()=>{
    calculate();
  },[calculate,ticketInfo,rows,selectedNoAdults, selectedNoKids, selectedNationality]);
  return (
    <div>
      <div class="button-container">
        <button class="button" > 1 </button>
        <button class="button" disabled={currentPage < 2}>  2 </button>
        <button class="button" disabled={currentPage < 3}>  3 </button>
        <button class="button" disabled={currentPage < 4}>  4 </button>
      </div>
      {currentPage === 1 && (
        <EntranceTicket
          ticketInfo={ticketInfo}
          setTicketInfo={setTicketInfo}
          changeCurrentPage={changeCurrentPage}
        />
      )}
      {currentPage === 2 && <EntranceTicketWithVisitors
        selectedNoAdults={selectedNoAdults}
        selectedNoKids={selectedNoKids}
        selectedNationality={selectedNationality}
        setSelectedNoAdults={setSelectedNoAdults}
        setSelectedNoKids={setSelectedNoKids}
        setSelectedNationality={setSelectedNationality}
        ticketInfo={ticketInfo}
        setTicketInfo={setTicketInfo}
        changeCurrentPage={changeCurrentPage}
        totalFee={subTotal}
        listOfNationalities={listOfNationalities}
        rows={rows}
        handleAddRow={handleAddRow}
        handleRemoveRow={handleRemoveRow}
      />
      }
      {
        currentPage === 3 && <PaymentDetails
          changeCurrentPage={changeCurrentPage}
        />
      }
      {currentPage === 4 && <EntranceResSuccess
        paymentMethod={ticketInfo.paymentMethod}
        apiResponse = {apiResponse}
      />
      }
    </div>
  );
}
export { OnlineEntranceRes };