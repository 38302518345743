import { PageWrapper } from "../Shared";
import { Margin } from "@mui/icons-material";
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useState, useEffect } from "react";
import { sendPOSTRequest } from "../../adaptors/bff-requests";
// Creating use states
const useStyles = makeStyles({
  background: {
    backgroundColor: '#EBFFEE',
    paddingTop: '3rem',
    paddingBottom: '2rem',
  },
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    // overflowX: 'scroll',
  },
});

const PublishEvents = () => {
  const classes = useStyles();
  const [allEventList, setAllEventList] = useState([]);
  useEffect(() => {
    const getAllEvents = async () =>{

      const newsPayload= {
        limit :2
      }
      const eventResopnse = await sendPOSTRequest({
        path: 'latest',payload: newsPayload, queryParams :'/news-event'
      });
      console.log("event Resoponse")
      console.log(eventResopnse);
      const arrayOngoing = eventResopnse.data.events.ongoing;
      const arrayUpcoming = eventResopnse.data.events.upcoming; 
      //setAllNewsList(newsResopnse.data.news.ongoing)
      setAllEventList(arrayOngoing.concat(arrayUpcoming));
    }
    getAllEvents();
   }, []);
  return (
    <PageWrapper>
      <div className="newsAndEvents_Container">
        {/*    ambuluwawa_events      */}
        <div className="ambuluwawa_events">
          <Box pt="6rem">
            <Box px="2rem" className={classes.background}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '4vh',
                  fontFamily: 'revert',
                  paddingBottom: '1rem',
                  textAlign: 'center',
                }}
              >
                Events
              </Typography>
              <Box py="2rem" className={classes.main}>
                {allEventList.map((item) => (
                    
                  <Box pl="2rem" pt="2rem">
                    
                    <Card sx={{ width: '20rem'}}>
                      <CardMedia component="img" alt={item.name} height="220rem" image={item.images[0].imageUrl} />
                      <CardContent>
                        <Typography variant="h6" color="#1E3932">
                            {item.date}
                        </Typography>
                        <Typography gutterBottom variant="h4">
                          {item.title}
                        </Typography>
                       
                        <Typography variant="h6" color="#1E3932" align="center" backgroundColor="#4db398" marginBottom="1rem">
                            {item.status}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {item.description.length > 60
                          ? item.description.substring(0, 60) + '...'
                          : item.description.substring(0, 60)}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small" href={'/events/details/' + item.id}>
                            View More <KeyboardArrowRightOutlinedIcon color="primary" fontSize="small" />
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </PageWrapper>
    );
  };
  export { PublishEvents };
  