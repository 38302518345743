import React, { useState,useEffect } from 'react';
import { PhotoShootTicket } from './PhotoShootTicket';
import { PaymentDetails } from './PaymentDetails';
import { PhotoShootResSuccess } from './PhotoShootResSuccess';
import { sendPOSTRequest } from '../../../../adaptors/bff-requests';
import  styles from './photoshoot.css';
import { useAuth } from '../../../../context';
const OnlinePhotoShootRes=({bookingDate, people, nationality})=> {
  const[ticketInfo,setTicketInfoValue]=useState({'reservationFee':'','numberOfPeople':1,'paymentMethod':'card','currency':'LKR','date':'','countryCode':'+94'});
  const[paymentDetails,setPaymentDetails]=useState({});
  const[reservationFeePerPerson,setReservationFeePerPerson]=useState()
  const[currentPage,setCurrentPage]= useState(1);
  const [apiResponse ,setApiResponse ] = useState();

  const {
    token,
    // authorizedUser: { authKey },
  } = useAuth();
  useEffect( async () => {
  
 const reservationPrice = await sendPOSTRequest({ path: 'pre-shoot/price'});
 setReservationFeePerPerson(reservationPrice.data[0].cost)
 setTicketInfo('reservationFee',reservationPrice.data[0].cost);
  }, []);
  const setTicketInfo = (key, value) => {
    console.log(key)
    console.log(value)
    setTicketInfoValue(prevState => {
      if (prevState[key] === value) {
        return prevState;
      }
      return {
        ...prevState,
        [key]: value
      };
    });
  };
  const setPaymentDetailsInfo = (key, value) => {
    setPaymentDetails(prevState => {
      if (prevState[key] === value) {
        return prevState;
      }
      return {
        ...prevState,
        [key]: value
      };
    });
  };
  const changeCurrentPage=async (page)=>
  { 
    if(page<3){
      setCurrentPage(page);
      return;
    }
    if(page===5 ){
      const paymentMode = ticketInfo.paymentMethod === 'cash'? false : true;
      const payload= {
        "reservation": {
          "firstName": ticketInfo.firstName,
          "lastName": ticketInfo.lastName,
          "phoneNumber": ticketInfo.countryCode+ticketInfo.contactNumber,
          "email": ticketInfo.emailAddress,
          "reservedDate": ticketInfo.reservationDate,
          "noOfPeople": ticketInfo.numberOfPeople,
          "reservationType": "online"
      },
      "onlinePayment": paymentMode
    }
      const response = await sendPOSTRequest({ path: 'pre-shoot/new', payload: payload }).then((res)=>{
        console.log(res);

        const confirmationId = res.data.reservationId;
        
        const paymentPayload = {
          "amount":  ticketInfo.reservationFee,
          "currency": ticketInfo.currency,
          "returnUrl": process.env.REACT_APP_RETURN_URL,
          "paymentType": "PHOTO_SHOOT",
          "comment": "test",
          "confirmationId": confirmationId
        }
        localStorage.setItem('paymentPayload', JSON.stringify(paymentPayload));
        if (confirmationId) {
          const paymentResponse = sendPOSTRequest({ path: 'init', payload: paymentPayload,queryParams:"/payments" }).then((paymentRes)=>{
    
            window.location.href = paymentRes.data.responseData.paymentPageUrl;
          });
        }

        //setApiResponse(response);

      });

    }
    if(page===3 ){

      const paymentMode = ticketInfo.paymentMethod === 'cash'? false : true;
      const Data= {
        "reservation": {
          "firstName": ticketInfo.firstName,
          "lastName": ticketInfo.lastName,
          "phoneNumber": ticketInfo.countryCode+ticketInfo.contactNumber,
          "email": ticketInfo.emailAddress,
          "reservedDate": ticketInfo.reservationDate,
          "noOfPeople": ticketInfo.numberOfPeople,
          "reservationType": "online"
      },
      "onlinePayment": paymentMode
    }
      const response = await sendPOSTRequest({ path: 'pre-shoot/new', payload: Data },token);
      setApiResponse(response);
      console.log(response);
      // const confirmationId = response.data.reservationId
      // console.log(confirmationId);
       setCurrentPage(page);
    }
  }
  useEffect(() => {
   
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    let numPeople=people;
    if(numPeople>8)
    {
      numPeople=8;
    }
    setTicketInfoValue((prev) => {
      return { ...prev, 'date': dd+'-'+mm+'-'+yyyy,'numberOfPeople':numPeople,'reservationDate':bookingDate.toISOString().split('T')[0] };
    });
    

  },[]);

  return (
    <div>
    <div className="button-container">
    <button className="button" > 1 </button>
    <button className="button" disabled={currentPage<2}>  2 </button>
    <button className="button" disabled={currentPage<3}>  3 </button>
   </div>
    {currentPage === 1 && (
    <PhotoShootTicket
    ticketInfo={ticketInfo}
    setTicketInfo={setTicketInfo}
    changeCurrentPage={changeCurrentPage}
    />
    )}
    {currentPage===2 && <PaymentDetails
     paymentDetails={paymentDetails} 
     setPaymentDetailsInfo={setPaymentDetailsInfo} 
     paybleAmount={ticketInfo.reservationFee}
     currency={ticketInfo.currency}
    changeCurrentPage={changeCurrentPage}
      />
    }
    {currentPage===3 && <PhotoShootResSuccess
     apiResponse = {apiResponse}
     paymentMethod={ticketInfo.paymentMethod}
      />
    }
     </div> 
);
}
export {OnlinePhotoShootRes};