export function isNumeric(str) {
  if (typeof str != 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
}

export function isValideEmail(email) {
  var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(email);
}

export function IsPhoneNumber(num) {
  const re = /^\d{8,10}$/;
  return re.test(num);
}

export function isNumberOnly(num) {
  const re = /^\d+$/;
  return re.test(num);
}

export const randomColorGenerator = () =>
  '#' +
  Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0');

export function shuffleArray(arrayToShuffle) {
  return [...arrayToShuffle].sort(() => Math.random() - 0.5);
}

export const isValidPassword = (password) => {
  const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/gm;
  return passwordRegex.test(password);
};
