import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import { makeStyles } from '@mui/styles';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context';

const drawerWidth = 240;
const navItems = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Login',
    link: '/login',
  },
  {
    title: 'Announcements',
    link: '/announcements',
  },
  {
    title: 'Donation',
    link: '/donation',
  },
  {
    title: 'Gallery',
    link: '/gallery',
  },
  {
    title: 'Publish',
    link: '/publish',
  },
];
function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: {
      backgroundColor: trigger ? "white" : "transparent",
    },
  });
}
const useStyles = makeStyles({
  userDDLShow: {
    position: 'absolute !important',
    display: 'flex',
    flexDirection: 'column',
    right: '8px',
    background: '#ebebeb',
    width: '12em',
    padding: '4px',
    borderRadius: '10px',
    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 20%)',
  },
  userDDLHide: {
    display: 'none',
  },
});

export default function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [showDDL, setShowDDL] = React.useState(false);
  const { isAuthenticated, signOut, authorizedUser } = useAuth();
  const navigate = useNavigate();
  const classes = useStyles();

  const logOut = async () => {
    await Auth.signOut();
    signOut();
    navigate('/login');
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        AMBULUWAWA
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={`d-title-${item.title}`} disablePadding >
            <ListItemButton component="a" href={item.link} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <ElevationScroll>
      <AppBar position="fixed" style={{ background: 'none', color: 'black' }} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, color: '#171725'  }}>
            AMBULUWAWA
          </Typography>
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            {navItems.map((item) => (
              isAuthenticated && item.title === 'Login' ? null : (
                <Button
                  component="a"
                  href={item.link}
                  key={`title-${item.title}`}
                  style={{ marginLeft: '2rem' }}
                >
                  <Typography variant="subtitle1" fontWeight={'600'}>
                    {item.title}
                  </Typography>
                </Button>
              )
            ))}

            {isAuthenticated && (
              <Button style={{ marginLeft: '2rem' }} onClick={() => navigate('/admin')}>
                <Typography variant="subtitle1" fontWeight={'600'}>
                  Dashboard
                </Typography>
              </Button>
            )}
          </Box>
          {isAuthenticated && (
            <Box
              style={{ display: 'flex', alignItems: 'center' }}
              onMouseEnter={() => setShowDDL(true)}
              onMouseLeave={() => setShowDDL(false)}
            >
              <Box style={{ marginLeft: '2rem', display: 'flex' }}>
              <Typography variant="subtitle1" sx={{fontWeight:'normal',color: '#171725'}}>
                  Hello!&nbsp;
                </Typography>
                <Typography variant="h5" fontSize={'1em'}>
                  {authorizedUser.given_name.substring(0, 1)}.{authorizedUser.family_name}
                </Typography>
              </Box>
              <Box style={{ position: 'relative' }}>
                <IconButton aria-label="account" size="small" onClick={() => null}>
                  <AccountCircleIcon fontSize="medium" color="primary" />
                </IconButton>
                <List
                  className={showDDL ? classes.userDDLShow : classes.userDDLHide}
                  aria-label="account"
                >
                  <ListItem disablePadding>
                    <ListItemButton onClick={logOut}>
                      <ListItemIcon>
                        <LogoutRoundedIcon fontSize="small" />
                      </ListItemIcon>
                      <Typography variant="subtitle1" sx={{fontWeight:'normal',color: '#171725'}}>
                        Log Out
                      </Typography>
                    </ListItemButton>
                  </ListItem>
                </List>
              </Box>
            </Box>
          )}
        </Toolbar>
      </AppBar>
      </ElevationScroll>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}