import create from 'zustand';

export const useAlertStore = create((set) => ({
  show: false,
  payload: { message: '', severity: 'error', duration: 3000 },
  showAlert: (props) => {
    const message = props.message || '';
    const severity = props.severity || 'error';
    const duration = props.duration || 3000;
    set({
      payload: {
        message,
        severity,
        duration,
      },
      show: true,
    });
  },
  onHide: () => set((state) => ({ show: false, payload: { ...state.payload, message: '' } })),
}));
