import {
  AppBar,
  Button,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import { isNumeric, IsPhoneNumber } from '../../../../utils/functions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { makeStyles } from '@mui/styles';
import {sendPOSTRequest} from "../../../../adaptors/bff-requests";
import {useAuth} from "../../../../context";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  textParent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '16px',
    marginTop: '16px',
  },
});

const passwordReq = () => (
  <Typography
    fontWeight={'light'}
    fontStyle={'italic'}
    marginTop={1}
    sx={{ fontFamily: 'revert', fontSize: 'default' }}
  >
    * Password should contain minimum 6 characters including 1 number and 1 UPPER case letter
  </Typography>
);

export default function AddEditFeedback(props) {
  const classes = useStyles();

  const { open, isLoading, selectedFeedback } = props;

  const isEditMode = selectedFeedback && selectedFeedback.id > 0;

  const [errorFields, setErrorFields] = useState([]);
  const [feedback, setFeedback] = useState({});
  const [listOfNationalities, setNationalities] = useState([]);
  const { token } = useAuth();

  const setUserFields = (key, value) => {
    setFeedback((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const onSaveClick = async () => {
    await setUserFields('country', (listOfNationalities.find(country => country.name === feedback.country) || {}).id);
    if (!validateFields()) {
      return;
    }

    const response = await props.saveClickHandler(feedback);
    if (response) {
      setErrorFields([]);
    }
  };

  const isErrorField = (field) => {
    const index = errorFields.findIndex((x) => x === field);
    return index > -1;
  };

  const validateFields = () => {
    let emptyFields = [];

    Object.entries(feedback).map(([key, value]) => {
      if (isEditMode && ['id', 'username', 'password', 'phoneNumber'].includes(key)) {
        return null;
      }

      if (!value) {
        emptyFields.push(key);
      }
      return null;
    });

    setErrorFields(emptyFields);

    if (emptyFields.length > 0) {
      props.showAlertBox('All Fields Are Required', 'warning');
      return;
    }

    if (!IsPhoneNumber(feedback.phone_number)) {
      props.showAlertBox('Invalid phone Number', 'warning');
      setErrorFields([...['phoneNumber']]);
      return;
    }

    return true;
  };

  const closeHandler = () => {
    setErrorFields([]);
    setFeedback({});
    props.closeHandler();
  };

  const fetchContents = useCallback(async () => {
    const nationalityResp = await sendPOSTRequest({ path: 'nationality/get' });
    if (nationalityResp) {
      const topTenNationalites = nationalityResp.data.topTenCountries || [];
      const nationalities = nationalityResp.data.allCountries || [];
      const topTenCountryIds = topTenNationalites.map(country => country.id);
      const filteredNationalities = nationalities.filter(country => !topTenCountryIds.includes(country.id));
      setNationalities(topTenNationalites.concat(filteredNationalities));
    }
  }, [token]);

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  useEffect(() => {
    setFeedback(selectedFeedback);
  }, [selectedFeedback]);

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      TransitionComponent={Transition}
      maxWidth={'md'}
      fullWidth={true}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={closeHandler} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
            {!isEditMode ? 'Add User' : 'Update Feedback'}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div className={classes.textParent}>
          <TextField
            label="Name"
            value={feedback.name}
            error={isErrorField('name')}
            type="text"
            onChange={(e) => setUserFields('name', e.target.value)}
            variant="outlined"
          />
        </div>
        <div className={classes.textParent}>
          <TextField
            label="Phone Number"
            type="tel"
            value={feedback.phone_number}
            error={isErrorField('phone_number')}
            onChange={(e) => {
              const val = e.target.value;
              if (isNumeric(val) || e.nativeEvent.inputType === 'deleteContentBackward') {
                if (val.length < 13) {
                  setUserFields('phone_number', val);
                }
              }
            }}
            variant="outlined"
          />
          <Select
            value={(listOfNationalities.find(country => country.name === feedback.country) || {}).id || feedback.country}
            onChange={(e) => setUserFields('country', e.target.value)}
          >
            {listOfNationalities.map((item) => (
              <MenuItem value={item.id}>{item.name}</MenuItem>
            ))}
          </Select>
          <TextField
            label="Message"
            type="message"
            value={feedback.message}
            error={isErrorField('message')}
            onChange={(e) => setUserFields('message', e.target.value)}
            variant="outlined"
          />
        </div>

        {!isEditMode && passwordReq()}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            style={{ width: '200px' }}
            size="large"
            color="primary"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={onSaveClick}
            disabled={isLoading}
          >
            {feedback && !feedback.id ? 'Save Feedback' : 'Update Feedback'}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
