import React, { useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { Button } from '@mui/material';

const PrintComponent = (props) => {
const componentRef = useRef(null);

return (
<div>
<div ref={componentRef}>
{props.children}
</div>
<ReactToPrint
trigger={() => <Button style={{ width: '10em', padding: '8px 0', fontSize: '1em' }}
variant="contained">Print</Button>}
content={() => document.getElementById(props.divId)}
/>
</div>
);
};

export default PrintComponent;