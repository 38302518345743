import React, { useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Announce from './Announce';
import PublishAnnouncement from './PublishAnnouncement';
import PublishEvent from './PublishEvent';
import Event from './Event';

export default function Announcement(props) {
  const [selectedValue, setSelectedValue] = useState('Announce');

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const formStyle = {
    marginLeft: '15%',
    width: '70%',
    border: '1px solid',
    padding: '20px',
    borderRadius: '15px',
  };
  return (
    <>
      <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedValue}
              onChange={handleRadioChange}
            >
              <FormControlLabel value="Announce" control={<Radio />} label="Type 1 Announcement" />
              <FormControlLabel value="Event" control={<Radio />} label="Type 2 Announcement" />
            </RadioGroup>
          </FormControl>
        </div>
        <div style={{ marginTop: '20px' }}>
          {selectedValue === 'Announce' ? (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={formStyle}>
                  <Announce />
                </div>
              
              </div>
            </>
          ) : null}
          {selectedValue === 'Event' ? (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div style={formStyle}>
                  <Event />
                </div>
               
              </div>
            </>
          ) : null}
        </div>
      </div>

     
    </>
  );
}
