import { React, useState} from 'react';
import { PublishEvents } from './PublishEvent';
import { PublishNews } from './PublishNews';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Button } from '@mui/material';
import {News} from './News';
import  {Event}  from './Event';

export default function NewsAndEvent(props) {
const [selectedValue, setSelectedValue] = useState('News');
      
        const handleRadioChange = (event) => {
          setSelectedValue(event.target.value);
        };
const formStyle={marginLeft:'15%',width:'70%',border:'1px solid',padding:'20px',borderRadius:'15px'};
    return (
        
        <>
    <div >
      <div style={{display:'flex', justifyContent:'center'}}>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedValue}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="News" control={<Radio />} label="News" />
          <FormControlLabel value="Event" control={<Radio />} label="Event" />
        </RadioGroup>
      </FormControl>
      </div>
      <div style={{ marginTop:'20px'}}>
      {selectedValue === 'News' ?<><div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}><div style={formStyle}><News /></div><div> <PublishNews/></div></div></>  : null}
      {selectedValue === 'Event' ? <><div style={{display:'flex',flexDirection:'column',justifyContent:'center'}}><div style={formStyle}><Event /></div><div><PublishEvents/></div></div></>: null}
      </div>
    </div>


        {/* <div>
        <Button
         style={{ width: '10em', padding: '8px 0', fontSize: '1em' }}
         variant="outlined"
         disabled={props.isLoading}
         onClick={() => props.onExport()}
         
       >
         Add New/Event
       </Button>
        </div> */}
       </>
    );
  };