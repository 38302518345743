import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

import CloseIcon from '@mui/icons-material/Close';
import TicketTableData from './tableData';
import { DeleteModal } from '../../../Shared';
import TextField from '@mui/material/TextField';
import { AppBar, IconButton, Slide, Toolbar, Typography } from '@mui/material';

import { sendPOSTRequest } from '../../../../adaptors/bff-requests';
import { useAuth } from '../../../../context';
import { useAlertStore } from '../../../../stores';
import { moduleIds } from '../../../../utils/rolesAndPermissions';
import {PayAtGate} from './components/PayAtGate'
import { Onsite } from './components/Onsite';
import { RegistrationDetails } from './components/RegistrationDetails';
import styles from '../OnlineBook/photoshoot.css'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PhotoShootTicketing=()=> {
  const { token, permissionsForAllowedModules } = useAuth();

  const allowedActions =
    (permissionsForAllowedModules && permissionsForAllowedModules[moduleIds.ticketing]) || {};

  const fnShowAlert = useAlertStore((state) => state.showAlert);

  const [open, setOpen] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [selectedTicketingOption, setSelectedTicketingOption] = useState('');
  const [selectedTicketPrint, setSelectedTicketPrint] = useState('');
  const [SelectedPayment, setSelectedPayment] = useState('');
  const [isListLoading, setIsListLoading] = useState(true);
  const [ticketRowCount, setTicketRowCount] = useState(0);
  const [tableProps, setTableProps] = useState({
    pageNum: 0,
    sortColumn: undefined,
    sortOrder: undefined,
    searchKeyword: '',
    tempProp: true,
    pageLim:0,
    filter: false
  });

  const [ticketList, setTicketList] = useState([]);
  
  const [isticketInfoOpen,toggleTicketInfo]=useState(false);
  const [registrationDetails,setRegistrationDetails]=useState({});


  
  const [isDeleteModalOpen, toggleDelete] = useState(false);
  const [isDeleteLoading, toggleDeleteLoading] = useState(false);
  const [deleteDetails, setDeleteMessage] = useState({
    title: 'Ticket',
    message: '',
    callBackData: undefined,
  });

  const showAlertBox = (message, type = 'success') => {
    fnShowAlert({
      message: message,
      severity: type,
    });
  };

  const handleClickOpen = (option) => {
    setOpen(!open);
    if (option) {
      setSelectedTicketingOption(option);
    }
  };
  const handleClickPrint = (printticket) => {
    setOpenPrint(!openPrint);
    resetTable();
    if (printticket) {
      setSelectedTicketPrint(printticket);
    }
  };
  const handleClickPayment = (Payment) => {
    setOpenPayment(!openPayment);
    resetTable();
    if (Payment) {
      setSelectedPayment(Payment);
    }
  };

  const onChangeSearchQuery =(s)=>{
    resetTable(s);
  }
  const resetTable = (keyword='') => {
    setTableProps((state) => {
      return {
        pageNum: 0,
        sortColumn: undefined,
        sortOrder: undefined,
        searchKeyword: keyword,
        tempProp: !state.tempProp,
      };
    });
  };

  const getTicketList = useCallback(async () => {
    setIsListLoading(true);
    const resp = await sendPOSTRequest(
      {
        path: 'pre-shoot/get',
        payload: {
          page: tableProps.pageNum,
          pageLimit: tableProps.pageLim,
          sortColumn: tableProps.sortColumn,
          sortOrder: tableProps.sortOrder,
          keyword: tableProps.searchKeyword,
          filterByUser: tableProps.filter,
        },
      },
      token
    );
    console.log("result of pagination is",resp);
    const hasData = resp && resp.data;
    setTicketRowCount(hasData ? resp.data.rowCount : 0);
    setTicketList(hasData ? resp.data.listOfReservations: []);
    setIsListLoading(false);
  }, [token, tableProps]);

  const onPageChangeHandler = async (nextPageNum) => {
    setTableProps((previous) => {
      return { ...previous, pageNum: nextPageNum };
    });
  };

  const onSortChangeHandler = async (model, details) => {
    if (model) {
      setTableProps((previous) => {
        return { ...previous, sortColumn: model[0].field, sortOrder: model[0].sort };
      });
    }
  };

  
  const showHideDeleteDialog = async (show = false, data) => {
    toggleDelete(show);
    const message = show ? `Do You Wish To Delete The Ticket #${data.reservationId} ?` : '';
    const callBackData = show ? data.reservationId : undefined;
    setDeleteMessage((prev) => {
      return { ...prev, message, callBackData };
    });
  };

  const deleteConfirmHandler = async (selectedReservationId) => {
    toggleDeleteLoading(true);
    const deleteResponse = await sendPOSTRequest(
      {
        path: 'pre-shoot/delete',
        payload: {
          reservationId: selectedReservationId,
        },
      },
      token
    );
    
    if (deleteResponse.status === 'success') {
      showHideDeleteDialog(false);
      resetTable();
      showAlertBox('Successfully Deleted Ticket');
    } else {
      showAlertBox('Error Deleting Ticket, please try again', 'error');
    }
    toggleDeleteLoading(false);
  };
  const showRegisterationDetail=async (data) => {
    if(data.reservationId === undefined)
    {
      return showAlertBox('Error whhile getting details', 'error');
    }
    const Response = await sendPOSTRequest(
      {
        path: 'pre-shoot/get-by-id',
        payload: {
          reservationId: data.reservationId,
        },
      },
      token
    );
    if (Response.status === 'success') {
      setRegistrationDetails(Response.data);
      toggleTicketInfodetails();
    } else {
      showAlertBox('Error while getting details', 'error');
    }

  }
  const toggleTicketInfodetails=async () => {
    toggleTicketInfo(!isticketInfoOpen);
  };

  useEffect(() => {
    getTicketList();
  }, [getTicketList]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', height: '85vh' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <TextField
          id="filled-search"
          label="Search"
          type="search"
          variant="outlined"
          onChange={(e) => {
            onChangeSearchQuery(e.target.value)
          }}
        />
           {allowedActions['print-ticket'] && (
            <Button
              style={{ marginLeft: '5px' }}
              variant="outlined"
              onClick={() => {
                handleClickPrint('Print Tickets');
              }}
            >
              On-site Ticketing - Photoshoot
            </Button>
          )}
           {allowedActions['print-ticket-online'] && (
            <Button
              style={{ marginLeft: '5px' }}
              variant="outlined"
              onClick={() => {
                handleClickPayment('Print Ticket Online');
              }}
            >
              Online Ticketing - Pay at Gate
            </Button>
          )}
        </div>
        <TicketTableData
          isLoading={isListLoading}
          ticketList={ticketList}
          pageNum={tableProps.pageNum}
          rowCount={ticketRowCount}
          allowedActions={allowedActions}
          onPageChangeHandler={onPageChangeHandler}
          onSortChangeHandler={onSortChangeHandler}
          onDeleteButtonClick={(value) => showHideDeleteDialog(true, value)}
          onVisibleButtonClick={(value)=> showRegisterationDetail(value)}
        />
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          handleClickOpen();
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClickOpen();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedTicketingOption}
            </Typography>
          </Toolbar>
        </AppBar>
      </Dialog>
      <Dialog
        fullScreen
        open={openPrint}
        onClose={() => {
          handleClickPrint();
        }}
        TransitionComponent={Transition}
      >
      <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClickPrint();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedTicketPrint}
            </Typography>
          </Toolbar>
        </AppBar>
        <Onsite
         handleClickPrint={handleClickPrint}
         showAlertBox={showAlertBox}
        />
         </Dialog>
         <Dialog
        fullScreen
        open={openPayment}
        onClose={() => {
          handleClickPayment();
        }}
        TransitionComponent={Transition}
      >
      <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClickPayment();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {SelectedPayment}
            </Typography>
          </Toolbar>
        </AppBar>
        <PayAtGate
        handleClickPayment={handleClickPayment}
        showAlertBox={showAlertBox}
        />
         </Dialog>
         <Dialog
        fullScreen
        open={isticketInfoOpen}
        onClose={() => {
          toggleTicketInfodetails();
        }}
        TransitionComponent={Transition}
      >
      <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                toggleTicketInfodetails();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Reservation Details
            </Typography>
          </Toolbar>
        </AppBar>
        <RegistrationDetails registrationDetails={registrationDetails}
        />
         </Dialog>
      {allowedActions['delete-ticket'] && (
        <DeleteModal
          open={isDeleteModalOpen}
          isLoading={isDeleteLoading}
          deleteDetails={deleteDetails}
          deleteConfirmHandler={deleteConfirmHandler}
          closeHandler={() => showHideDeleteDialog(false)}
        />
      )}
    </div>
    
  );
}
export {PhotoShootTicketing};