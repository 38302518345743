import React, { PureComponent } from 'react';

export class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={-0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">
          {payload.value}
        </text>
      </g>
    );
  }
}

export const tickFormatter = (value, index) => {
  const limit = 2;
  if (value.length < limit) return value;
  return `${value.substring(0, limit)}...`;
};
