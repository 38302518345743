import {
  AppBar,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Slide,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  isNumeric,
  isValideEmail,
  IsPhoneNumber,
  isValidPassword,
} from '../../../../utils/functions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { makeStyles } from '@mui/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  textParent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '16px',
    marginTop: '16px',
  },
});

const passwordReq = () => (
  <Typography
    fontWeight={'light'}
    fontStyle={'italic'}
    marginTop={1}
    sx={{ fontFamily: 'revert', fontSize: 'default' }}
  >
    * Password should contain minimum 6 characters including 1 number and 1 UPPER case letter
  </Typography>
);

const dateComponent = (value, isError, onChangeFunc) => (
  <TextField
    label="DOB"
    type="date"
    value={value}
    error={isError}
    onChange={(e) => onChangeFunc('dob', e.target.value)}
    variant="outlined"
  />
);

export default function AddEditUser(props) {
  const classes = useStyles();

  const { open, isLoading, selectedUser } = props;

  const isEditMode = selectedUser && selectedUser.id > 0;

  const [errorFields, setErrorFields] = useState([]);
  const [user, setUser] = useState({});

  const setUserFields = (key, value) => {
    setUser((prev) => {
      return { ...prev, [key]: value };
    });
  };

  const onSaveClick = async () => {
    if (!validateFields()) {
      return;
    }

    const response = await props.saveClickHandler(user);
    if (response) {
      setErrorFields([]);
    }
  };

  const isErrorField = (field) => {
    const index = errorFields.findIndex((x) => x === field);
    return index > -1;
  };

  const validateFields = () => {
    let emptyFields = [];

    Object.entries(user).map(([key, value]) => {
      if (isEditMode && ['id', 'username', 'password'].includes(key)) {
        return null;
      }

      if (!value) {
        emptyFields.push(key);
      }
      return null;
    });

    setErrorFields(emptyFields);

    if (emptyFields.length > 0) {
      props.showAlertBox('All Fields Are Required', 'warning');
      return;
    }

    if (!isValideEmail(user.email)) {
      props.showAlertBox('Invalid Email Address', 'warning');
      setErrorFields([...['email']]);
      return;
    }

    if (!IsPhoneNumber(user.phoneNumber)) {
      props.showAlertBox('Invalid phone Number', 'warning');
      setErrorFields([...['phoneNumber']]);
      return;
    }

    if (!isEditMode && !isValidPassword(user.password)) {
      props.showAlertBox('Password Does Not Meet Requirements', 'warning');
      setErrorFields([...['password']]);
      return;
    }

    return true;
  };

  const passwordResetHandler = async () => {
    if (!isValidPassword(user.password)) {
      props.showAlertBox('Password Does Not Meet Requirements', 'warning');
      setErrorFields(['re-password']);
      return;
    }

    setErrorFields([]);
    const response = await props.resetPasswordHandler(user);
  };

  const closeHandler = () => {
    setErrorFields([]);
    setUser({});
    props.closeHandler();
  };

  useEffect(() => {
    setUser(selectedUser);
  }, [selectedUser]);

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      TransitionComponent={Transition}
      maxWidth={'md'}
      fullWidth={true}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={closeHandler} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h4" component="div">
            {!isEditMode ? 'Add User' : 'Update User'}
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <div className={classes.textParent}>
          <TextField
            label="First Name"
            value={user.firstName}
            error={isErrorField('firstName')}
            type="text"
            onChange={(e) => setUserFields('firstName', e.target.value)}
            variant="outlined"
          />
          <TextField
            label="Last Name"
            value={user.lastName}
            error={isErrorField('lastName')}
            onChange={(e) => setUserFields('lastName', e.target.value)}
            variant="outlined"
          />
          <TextField
            label="NIC/Passport Number"
            value={user.identification}
            error={isErrorField('identification')}
            onChange={(e) => setUserFields('identification', e.target.value)}
            variant="outlined"
          />
        </div>
        <div className={classes.textParent}>
          <TextField
            label="Phone Number"
            type="tel"
            value={user.phoneNumber}
            error={isErrorField('phoneNumber')}
            onChange={(e) => {
              const val = e.target.value;
              if (isNumeric(val) || e.nativeEvent.inputType === 'deleteContentBackward') {
                if (val.length < 11) {
                  setUserFields('phoneNumber', val);
                }
              }
            }}
            variant="outlined"
          />
          <TextField
            label="Email Address"
            type="email"
            value={user.email}
            error={isErrorField('email')}
            onChange={(e) => setUserFields('email', e.target.value)}
            variant="outlined"
          />
          <TextField
            label="Position"
            type="email"
            value={user.position}
            error={isErrorField('position')}
            onChange={(e) => setUserFields('position', e.target.value)}
            variant="outlined"
          />
        </div>
        <div className={classes.textParent}>
          {!isEditMode && (
            <TextField
              label="Username"
              type="text"
              error={isErrorField('username')}
              value={user.username}
              disabled={isEditMode}
              onChange={(e) => {
                if (!isEditMode) {
                  setUserFields('username', e.target.value);
                }
              }}
              variant="outlined"
            />
          )}
          {!isEditMode && (
            <TextField
              label="Password"
              type="text"
              error={isErrorField('password')}
              value={user.password}
              onChange={(e) => setUserFields('password', e.target.value)}
              variant="outlined"
            />
          )}
          {/**show date component here only in edit mode */}
          {isEditMode && dateComponent(user.dob, isErrorField('dob'), setUserFields)}
          <div>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Permission Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={user.role}
                label="Permission Role"
                error={isErrorField('firstName')}
                onChange={(e) => setUserFields('role', e.target.value)}
              >
                <MenuItem value="Admin">Admin</MenuItem>
                <MenuItem value="Ticketing">Ticketing</MenuItem>
                <MenuItem value="SiteManager">Site Manager</MenuItem>
                <MenuItem value="GeneralIT">General IT</MenuItem>
                <MenuItem value="EntityAdmin">Entity Admin</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        {/**show date component here only in add mode */}
        {!isEditMode && (
          <div className={classes.textParent}>
            {dateComponent(user.dob, isErrorField('dob'), setUserFields)}
          </div>
        )}

        {!isEditMode && passwordReq()}
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
          <Button
            style={{ width: '200px' }}
            size="large"
            color="primary"
            startIcon={<SaveIcon />}
            variant="contained"
            onClick={onSaveClick}
            disabled={isLoading}
          >
            {user && !user.id ? 'Save User' : 'Update User'}
          </Button>
        </div>

        {isEditMode && (
          <div style={{ marginTop: '24px' }}>
            <Typography variant="h4" sx={{ fontFamily: 'revert' }}>
              Reset password
            </Typography>
            {passwordReq()}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '12px',
                alignItems: 'end',
              }}
            >
              <TextField
                style={{ width: '33%' }}
                label="New Password"
                type="text"
                error={isErrorField('re-password')}
                value={user.password}
                onChange={(e) => setUserFields('password', e.target.value)}
                variant="outlined"
              />
              <Button
                style={{ width: '200px', height: '3em' }}
                size="large"
                color="primary"
                startIcon={<RestartAltIcon />}
                variant="contained"
                onClick={passwordResetHandler}
                disabled={isLoading}
              >
                Reset
              </Button>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
