import React, { useCallback, useState } from 'react';
import { Slide } from '@mui/material';
// import styles from './style.css';
import { IsPhoneNumber, isValideEmail, IsPassword } from '../../../shared/functions';
import { useAuth } from '../../../../../context';
import { Dialog, Typography, AppBar, IconButton, Toolbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ListGuides from '../list-registraions/ListGuides';
import { sendPOSTRequest } from '../../../../../adaptors/bff-requests';
import CreateNewGuide from './components/create-guide';
import AlertMessage from '../../../../Alerts/AlertMessage';
import { useAlertStore } from '../../../../../stores';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function TourGuide(props) {
  const { token } = useAuth();
  const fnShowAlert = useAlertStore((state) => state.showAlert);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialState = {
    id: '',
    firstName: '',
    lastName: '',
    dob: '',
    email: '',
    phoneNumber: '',
    identification: '',
    username: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    district: '',
    province: '',
    postalCode: '',
    country: 'Sri Lanka',
    bankAccountNumber: '',
    bankAccountName: '',
    bankName: '',
    bankBranch: '',
    newPassword: '',
    confirmPassword: '',
  };

  const [showList, setShowList] = useState({
    state: false,
    isUpdate: false,
  });
  const [tourGuide, setTourGuide] = useState(initialState);
  const [isError, setIsError] = useState({
    state: false,
    message: '',
  });

  const [errorAlert, setErrorAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const validationError = (status, message) => {
    setIsError({ state: status, message: message });
  };

  const onSubmit = useCallback(async () => {
    const tg = tourGuide;
    console.log(tg);
    if (
      tg.firstName &&
      tg.lastName &&
      tg.dob &&
      tg.phoneNumber &&
      tg.identification &&
      tg.addressLine1 &&
      tg.addressLine2 &&
      tg.city &&
      tg.district &&
      tg.province &&
      tg.postalCode &&
      tg.bankAccountName &&
      tg.bankAccountNumber &&
      tg.bankBranch &&
      tg.bankName &&
      tg.username &&
      tg.email &&
      tg.newPassword &&
      tg.password
    ) {
      if (tg.email && !isValideEmail(tourGuide.email)) {
        validationError(true, 'Invalid Email Address');
        return null;
      } else if (!IsPhoneNumber(tg.phoneNumber)) {
        validationError(true, 'Invalid Phone Number');
        return null;
      } else if (tg.newPassword !== tg.password) {
        validationError(true, 'Passwords must match');
        return null;
      } else if (!IsPassword(tg.password)) {
        validationError(true, 'Passwords must be strong');
        return null;
      } else {
        validationError(false, '');
        const resp = await sendPOSTRequest({ path: 'user/guide/new', payload: tourGuide }, token);
        if (resp.status === 'success') {
          fnShowAlert({
            message: 'New guide registered successfully',
            severity: 'success',
          });
          setTourGuide(initialState);
          closeModal();
          props.listTourGuides();
        } else {
          fnShowAlert({
            message: resp.status === 'warning' ? resp.message : 'Failed to register the guide',
            severity: 'warning',
          });
        }
      }
    } else {
      validationError(true, 'Required Fields Cannot Be Empty!');
    }
  }, [tourGuide, token, initialState]);

  const onUpdate = useCallback(async () => {
    const tg = tourGuide;
    if (
      tg.firstName &&
      tg.lastName &&
      tg.dob &&
      tg.phoneNumber &&
      tg.identification &&
      tg.addressLine1 &&
      tg.addressLine2 &&
      tg.city &&
      tg.district &&
      tg.province &&
      tg.postalCode &&
      tg.bankAccountName &&
      tg.bankAccountNumber &&
      tg.bankBranch &&
      tg.bankName &&
      tg.username &&
      tg.email &&
      tg.newPassword &&
      tg.password
    ) {
      if (tg.email && !isValideEmail(tourGuide.email)) {
        validationError(true, 'Invalid Email Address');
        return null;
      }
      if (!IsPhoneNumber(tg.phoneNumber)) {
        validationError(true, 'Invalid Phone Number');
        return null;
      } else {
        validationError(false, '');
        const resp = await sendPOSTRequest(
          { path: 'user/guide/update', payload: tourGuide },
          token
        );
        if (resp.status === 'success') {
          fnShowAlert({
            message: 'Tour guide updated successfully',
            severity: 'success',
          });
          setTourGuide(initialState);
          closeModal();
          props.listTourGuides();
        } else {
          fnShowAlert({
            message: resp.status === 'warning' ? resp.message : 'Failed to update the guide',
            severity: 'warning',
          });
        }
      }
    } else {
      validationError(true, 'Required Fields Cannot Be Empty!');
    }
  }, [tourGuide, token, initialState]);

  const getTourGuide = useCallback(
    (key) => {
      return props.tourGuideList
        ?.filter((e) => {
          return e.id === key;
        })
        .map((e) => {
          setTourGuide({
            id: e.id,
            firstName: e.firstname,
            lastName: e.lastname,
            dob: e.dob,
            email: e.email,
            username: e.email,
            phoneNumber: e.phonenumber,
            identification: e.identification,
            addressLine1: e.addressline1,
            addressLine2: e.addressline2,
            city: e.city,
            province: e.province,
            district: e.district,
            postalCode: e.postalcode,
            country: e.country,
            bankAccountName: e.bankaccountname,
            bankAccountNumber: e.bankaccountnumber,
            bankName: e.bankname,
            bankBranch: e.bankbranch,
          });
          return false;
        });
    },
    [props.tourGuideList]
  );

  const onOptionSelected = useCallback(
    (e) => {
      switch (e.value) {
        case 'more':
          getTourGuide(e.key);
          setShowList({ state: true, isUpdate: true });
          break;
        default:
          break;
      }
    },
    [getTourGuide]
  );

  const onStateChange = useCallback(() => {
    setTourGuide(initialState);
  }, [initialState]);

  const closeModal = () => {
    setShowList({ state: false, isUpdate: false });
  };

  return (
    <div>
      <ListGuides
        onStateChange={onStateChange}
        setShowList={setShowList}
        isFetchingList={props.isFetchingList}
        tourGuideList={props.tourGuideList}
        listTourGuides={props.listTourGuides}
        onOptionSelected={onOptionSelected}
        allowedActions={props.allowedActions}
      />
      <Dialog
        fullScreen
        open={showList.state}
        onClose={() => {
          closeModal();
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                closeModal();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {showList.isUpdate ? 'Update' : 'Register'} Tour Guide
            </Typography>
          </Toolbar>
        </AppBar>
        <CreateNewGuide
          tourGuide={tourGuide}
          setTourGuide={setTourGuide}
          isError={isError}
          setIsError={setIsError}
          onSubmit={onSubmit}
          isUpdate={showList.isUpdate}
          onUpdate={onUpdate}
        />
        <div>
          <AlertMessage
            message={alertMessage}
            show={errorAlert}
            closeHandler={(value) => setErrorAlert(false)}
          />
        </div>
      </Dialog>
    </div>
  );
}
