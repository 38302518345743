import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { LabelList } from 'recharts';

import CustomBarChart from '../shared/bar-chart';
import CustomPieChart from '../shared/pie-chart';

const useStyles = makeStyles({
  chartBlock: {
    height: '50vh',
    display: 'flex',
    marginTop: '24px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pieChartParent: {
    display: 'flex',
    height: '50vh',
    alignItems: 'center',
    marginTop: '32px',
    textAlign: 'center',
  },
  pieChartBlock: {
    height: '100%',
    width: '50%',
  },
  chartMargin: {
    top: 12,
    right: 8,
    left: 8,
    bottom: 4,
  },
  chartToolTipParent: {
    padding: '4px',
    background: '#eaeaea',
    borderRadius: '8px',
  },
});

export default function VisitorReport(props) {
  const classes = useStyles();

  const data = props.data;
  const visitorsGroupedByMainTypes = data ? data.visitorsGroupedByMainTypes || [] : [];
  const visitorsGroupedByAllTypes = data ? data.visitorsGroupedByAllTypes || [] : [];
  const vehiclesGroupedByAllTypes = data ? data.vehiclesGroupedByAllTypes || [] : [];

  const totalValueOfVisitor = visitorsGroupedByAllTypes.reduce((acc, curr) => acc + curr.costs, 0);
  const totalValueOfVehicles = vehiclesGroupedByAllTypes.reduce((acc, curr) => acc + curr.costs, 0);

  const RADIAN = Math.PI / 180;

  const chartDimensionProps = {
    margin: {
      top: 20,
      right: 8,
      left: 8,
      bottom: 4,
    },
  };

  const customBarLegend = (props) => {
    const { payload } = props;
    return (
      <ul style={{ padding: '0px', margin: '0px', textAlign: 'center' }}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{
              display: 'inline-block',
              marginRight: '10px',
              color: entry.color,
            }}
          >
            {entry.value.split('_')[0]}
          </li>
        ))}
      </ul>
    );
  };
  const customBarTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const item = payload[0].payload;
      return (
        <div className={classes.chartToolTipParent}>
          <p>
            Date: <strong>{label}</strong>
          </p>
          <div style={{ borderBottom: '1px solid #d7d7d7' }}>
            <p>
              <strong>
                <u>Locals</u>
              </strong>
            </p>
            <p>
              (LKR) : <strong>{item['local_cost']}</strong>
            </p>
            <p>
              Count : <strong>{item['local_count']}</strong>
            </p>
          </div>
          <div>
            <p>
              <strong>
                <u>Tourists</u>
              </strong>
            </p>
            <p>
              (LKR) : <strong>{item['tourist_cost']}</strong>
            </p>
            <p>
              Count : <strong>{item['tourist_count']}</strong>
            </p>
          </div>
        </div>
      );
    }
    return null;
  };

  const customPieTooltip = ({ active, payload }, chartType) => {
    if (active && payload && payload.length) {
      const item = payload[0];
      const percentage =
        (item.value / (chartType === 'visitor' ? totalValueOfVisitor : totalValueOfVehicles)) * 100;
      return (
        <div className={classes.chartToolTipParent} style={{ textAlign: 'left' }}>
          <p>
            <strong>
              <u>{item.name}</u>
            </strong>
          </p>
          <p>
            (LKR) : <strong>{item.value}</strong>
          </p>
          <p>
            Count : <strong>{item.payload['counts']}</strong>
          </p>
          <p>
            Ratio(value) : <strong>{percentage.toFixed(1)}%</strong>
          </p>
        </div>
      );
    }
    return null;
  };

  const customPieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, payload }, chartType) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentage =
      (payload.costs / (chartType === 'visitor' ? totalValueOfVisitor : totalValueOfVehicles)) *
      100;
    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {percentage.toFixed(1)}%
      </text>
    );
  };

  return (
    <>
      <div className={classes.chartBlock}>
        <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
          Visitors
        </Typography>
        <CustomBarChart
          data={visitorsGroupedByMainTypes}
          xaxisKey={'issueddate'}
          bars={[
            {
              key: 'local_cost',
              color: '#00C49F',
              label: {
                position: 'top',
                fontSize: 12,
              },
            },
            {
              key: 'tourist_cost',
              color: '#FF8042',
              label: {
                position: 'top',
                fontSize: 12,
              },
            },
          ]}
          dimensionProps={{ ...chartDimensionProps }}
          customToolTip={customBarTooltip}
          customLegend={customBarLegend}
        />
      </div>
      <div className={classes.pieChartParent}>
        <div className={classes.pieChartBlock}>
          <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
            Visitors %
          </Typography>
          <CustomPieChart
            data={visitorsGroupedByAllTypes}
            dataKey={'costs'}
            nameKey={'visitor'}
            legendPosition={'top'}
            paddingAngle={2}
            labelLine={true}
            dimensionProps={{ ...chartDimensionProps }}
            customToolTip={(props) => customPieTooltip(props, 'visitor')}
            customLabel={(props) => customPieLabel(props, 'visitor')}
          />
        </div>
        <div className={classes.pieChartBlock}>
          <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
            Vehicles %
          </Typography>
          <CustomPieChart
            data={vehiclesGroupedByAllTypes}
            dataKey={'costs'}
            nameKey={'vehicle'}
            paddingAngle={2}
            labelLine={true}
            legendPosition={'top'}
            dimensionProps={{ ...chartDimensionProps }}
            customToolTip={(props) => customPieTooltip(props, 'vehicle')}
            customLabel={(props) => customPieLabel(props, 'vehicle')}
          />
        </div>
      </div>
    </>
  );
}
