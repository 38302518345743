import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CustomLineChart from '../shared/line-chart';

const useStyles = makeStyles({
  chartBlock: {
    height: '50vh',
    display: 'flex',
    marginTop: '24px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chartMargin: {
    top: 12,
    right: 8,
    left: 8,
    bottom: 4,
  },
  chartToolTipParent: {
    padding: '4px',
    background: '#eaeaea',
    borderRadius: '8px',
  },
});

export default function SalesReport(props) {
  const classes = useStyles();

  const { data } = props;

  const lineChartOptions = {
    margin: {
      top: 20,
      right: 8,
      left: 16,
      bottom: 4,
    },
  };

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const element = payload[0];
      const title = element.dataKey === 'amount' ? 'Amount (LKR)' : 'Count';
      return (
        <div className={classes.chartToolTipParent}>
          <p>
            Date: <strong>{element.payload.date}</strong>
          </p>
          <p>
            {title} : <strong>{element.value}</strong>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className={classes.chartBlock}>
        <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
          Total Sales Values (LKR)
        </Typography>
        <CustomLineChart
          data={data}
          xaxisKey={'issueddate'}
          lines={[
            {
              key: 'amount',
              color: '#FF5733',
            },
          ]}
          lineChart={{ ...lineChartOptions }}
          customToolTip={customTooltip}
        />
      </div>
      <div className={classes.chartBlock}>
        <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
          Total Sales Count
        </Typography>
        <CustomLineChart
          data={data}
          xaxisKey={'issueddate'}
          lines={[
            {
              key: 'totalcount',
              color: '#0096FF',
            },
          ]}
          lineChart={{ ...lineChartOptions }}
          customToolTip={customTooltip}
        />
      </div>
    </>
  );
}
