import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectCountry() {
  return (
    <FormControl style={{ width: '25%' }}>
      <InputLabel id="country-simple-select-label">Country</InputLabel>
      <Select
        labelId="country-simple-select-label"
        id="country-simple-select"
        value="Sri Lanka"
        label="Country"
        onChange={() => {}}
      >
        <MenuItem selected value="Sri Lanka">
          Sri Lanka
        </MenuItem>
      </Select>
    </FormControl>
  );
}
