import React, { useState, useEffect, useCallback } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { SpinnerComponent } from '../../Shared';
import ConfirmModal from '../shared/confirm-modal';
import { sendRequestNewdb } from '../../../adaptors/bff-newdb-requests';
import { format } from 'date-fns';
import { useAlertStore } from '../../../stores';
import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useAuth } from '../../../context';
import { moduleIds, userRoles } from '../../../utils/rolesAndPermissions';

export default function Commission() {
  const showAlert = useAlertStore((state) => state.showAlert);
  const [loading, setLoading] = useState(false); 
  const [selectedGuide, setSelectedGuide] = useState(-1);
  const [guideList, setGuideList] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(-1);
  const [giveCommissionEnabled, setGiveCommissionEnabled] = useState(false);
  const [mainTotalValues, setMainTotalValues] = useState({
    remainingCommissionOnSaleForGuide: '-',
    totalSale: '-',
    totalCommissionGiven: '-',
    commissionToGive: '-',
    presentCommissionPercentage: '-',
  });
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [commissionToGive, setCommissionToGive] = useState();
  const [payModalOpen, setPayModalOpen] = useState(false);
  const [commissionChangeModalOpen, setCommissionChangeModalOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalItemCount, setTotalItemCount] = useState(0);

  const { token, authorizedUser, permissionsForAllowedModules } = useAuth();
  const allowedActions =
    (permissionsForAllowedModules && permissionsForAllowedModules[moduleIds.commission]) || {};

  const cardHeight = '100px';
  const cardInsideMargin = '10px';

  const showAlertBox = (message, type = 'success') => {
    showAlert({
      message: message,
      severity: type,
    });
  };

  const onSelectedGuideChanged = async (selectedGuide) => {
    setSelectedGuide(selectedGuide);
  };
  const onPayClick = () => {
    if (commissionToGive <= 0 || commissionToGive > mainTotalValues.commissionToGive) {
      showAlertBox('Please check guide expense amount', 'error');
    } else {
      setPayModalOpen(true);
    }
  };

  const handleCommissionChange = (e) => {
    setCommissionToGive(e.target.value);
  };

  const fetchEntityList = useCallback(async () => {
    try {
      setGiveCommissionEnabled(false);
      setLoading(true);
      const params = {
        organization_id: 1,
      };
      const entityListResponse = await sendRequestNewdb({
        method: 'get',
        path: 'entities',
        queryParams: params,
      });

      if (entityListResponse.status === 'error') {
        showAlertBox('Error while fetching data', 'error');
        setGiveCommissionEnabled(false);
      } else {
        const entities = entityListResponse?.data;
        if (entities.length > 0) {
          setSelectedEntity(entities[0].id);
          setEntityList(entities);
          setGiveCommissionEnabled(true);
        }
      }
    } catch (error) {
      setGiveCommissionEnabled(false);
      showAlertBox('Error while fetching data', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchGuideList = useCallback(async () => {
    try {
      setLoading(true);
      setGiveCommissionEnabled(false);
      const guideListResponse = await sendRequestNewdb({
        method: 'get',
        path: 'guides',
      });

      if (guideListResponse.status === 'error') {
        showAlertBox('Error while fetching data', 'error');
        setGiveCommissionEnabled(false);
      } else {
        const guides = guideListResponse?.data;
        if (guides.length > 0) {
          const defaultGuideId = guides[0].id;
          setGiveCommissionEnabled(true);
          setGuideList(guides);
          setSelectedGuide(defaultGuideId);
        }
      }
    } catch (error) {
      setGiveCommissionEnabled(false);
      showAlertBox('Error while fetching data', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchMainTotalValues = useCallback(async (guideId, entityId) => {
    try {
      setGiveCommissionEnabled(false);
      setLoading(true);
      const payload = {
        entities_id: entityId,
        guide_id: guideId,
      };
      const mainTotalValuesResponse = await sendRequestNewdb({
        method: 'post',
        path: 'sales/remaining-commission',
        payload: payload,
      });
      if (mainTotalValuesResponse.status === 'error') {
        showAlertBox('Error while fetching data', 'error');
        setGiveCommissionEnabled(false);
      } else {
        setGiveCommissionEnabled(true);
        setMainTotalValues(mainTotalValuesResponse?.data);
        setCommissionToGive(mainTotalValuesResponse?.data.commissionToGive);
        setTotalItemCount(mainTotalValuesResponse?.data?.totalOrders);
      }
    } catch (error) {
      setGiveCommissionEnabled(false);
      showAlertBox('Error while fetching data', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchTransactionHistory = useCallback(async (guideId, entityId,cpage=1,cpageSize=10) => {
    try {
      setGiveCommissionEnabled(false);
      setLoading(true);
      const payload = {
        entities_id: entityId,
        guide_id: guideId,
      };
      const queryParams = {
        page: cpage,
        pageSize: cpageSize,
      };
      const transactionHistoryResponse = await sendRequestNewdb({
        method: 'post',
        path: 'transactions/entities/guide',
        payload: payload,
        queryParams: queryParams,
      });
      if (transactionHistoryResponse.status === 'error') {
        showAlertBox('Error while fetching data', 'error');
        setGiveCommissionEnabled(false);
      } else {
        setGiveCommissionEnabled(true);
        setTransactionHistory(transactionHistoryResponse?.data);
      }
    } catch (error) {
      setGiveCommissionEnabled(false);
      showAlertBox('Error while fetching data', 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const getProfileDetails = useCallback(async () => {
    try {
      const resp = await sendPOSTRequest({ path: 'user/my-profile/get' }, token);
      if (!resp || resp.status === 'warning') {
        if (resp.status === 'warning') {
          showAlertBox(resp.message, 'warning');
        }
        return;
      }
      
      if(resp.data.role == userRoles.entityAdmin){
        const userEntityId = parseInt(resp.data.position);
        fetchGuideList();
        setSelectedEntity(userEntityId);
      }else if(resp.data.role == userRoles.guide){
        fetchEntityList();
        setSelectedGuide(resp.data.id)
      }else{
          fetchEntityList();
          fetchGuideList();
      }

    } catch (error) {
      showAlertBox('Error while fetching data in getProfileDetails'+error, 'error');
    }
  }, []);

  const pushCommission = useCallback(async () => {
    const payload = {
      salesType: 'commission',
      amount: (commissionToGive * 100) / mainTotalValues?.presentCommissionPercentage,
      guide_id: selectedGuide,
      entities_id: selectedEntity,
      commission: commissionToGive,
    };
    const pushCommissionResponse = await sendRequestNewdb({
      method: 'post',
      path: 'add-sale',
      payload: payload,
    });
    if (pushCommissionResponse.status === 'error') {
      showAlertBox('Error while pushing data', 'error');
    } else {
      fetchMainTotalValues(selectedGuide,selectedEntity);
    }
  });

  const updateCommission = useCallback(async (updatedCommission) => {
    const payload = {
      commission_percentage: updatedCommission,
      entities_id: selectedEntity,
    };
    const updateCommissionResponse = await sendRequestNewdb({
      method: 'put',
      path: 'entity',
      payload: payload,
    });
    if (updateCommissionResponse.status === 'error') {
      showAlertBox('Error while pushing data', 'error');
    } else {
      fetchMainTotalValues(selectedGuide,selectedEntity);
    }
  });

  useEffect(() => {
    getProfileDetails();
  }, []);

  useEffect(() => {
    setPage(1);
    setPageSize(10);
    fetchMainTotalValues(selectedGuide,selectedEntity);
    fetchTransactionHistory(selectedGuide,selectedEntity);
  }, [selectedEntity, selectedGuide]);

  useEffect(() => {
    fetchTransactionHistory(selectedGuide,selectedEntity,page,pageSize);
  }, [page, pageSize]);

  useEffect(() => {
    if (guideList.length > 0) {
      const defaultGuideId = guideList[0].id;
      setSelectedGuide(defaultGuideId);
    }
  }, [guideList]);

  const handlePayModalConfirmation = () => {
    pushCommission();
    showAlertBox(`Payment of ${commissionToGive} successful`, 'success');
    setPayModalClose();
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage+1);
  };

  const handleBackButtonClick = useCallback(async () =>{
    setPage((prevPage) => prevPage - 1);
  });

  const handleNextButtonClick = useCallback(async () =>{
    setPage((prevPage) => prevPage + 1);
  });

  const handlePageSizeChange = (event) => {
    setPageSize(event.target.value);
    setPage(1);
  };

  const setPayModalClose = () => {
    setCommissionToGive(mainTotalValues.commissionToGive);
    setPayModalOpen(false);
  };

  const onUpdateCommissionClick = () => {
    setCommissionChangeModalOpen(true);
  };

  const handleCommissionChangeModalConfirmation = (v) => {
    if (v <= 0 || v > 100) {
      showAlertBox(`Please enter correct percentage`, 'error');
    } else {
      updateCommission(parseInt(v));
      showAlertBox(`Guide Expense Updated successfully`, 'success');
      setCommissionChangeModalClose();
    }
  };
  const setCommissionChangeModalClose = () => {
    setCommissionChangeModalOpen(false);
  };
  return (
    <>
    {loading && <SpinnerComponent />}
      {payModalOpen && (
        <ConfirmModal
          title="Confirm Payment"
          message={`Are you sure you want to give Rs ${commissionToGive} guide expense?`}
          open={payModalOpen}
          handleClose={setPayModalClose}
          handleConfirmation={handlePayModalConfirmation}
        />
      )}
      {commissionChangeModalOpen && (
        <ConfirmModal
          title="Update Guide Expense"
          message={`Enter the new Guide Expense percentage between 1-100`}
          open={commissionChangeModalOpen}
          handleClose={setCommissionChangeModalClose}
          handleConfirmation={handleCommissionChangeModalConfirmation}
          showTextInput={true}
        />
      )}
      <div>
        <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-start' }}>
          {allowedActions['select-entity'] && (
            <div>
              <InputLabel>Entity</InputLabel>
              <Select
                style={{ minWidth: '15em', maxWidth: '20em' }}
                value={selectedEntity}
                label="selectedEntity"
                onChange={(e) => {
                  setSelectedEntity(e.target.value);
                }}
              >
                {entityList.map((entity) => (
                  <MenuItem key={entity.id} value={entity.id}>
                    {entity.entity_name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          {allowedActions['select-guide'] && (
            <div style={{ marginLeft: '10px' }}>
              <InputLabel>Guide</InputLabel>
              <Select
                style={{ minWidth: '9em', maxWidth: '9em' }}
                value={selectedGuide}
                label="selectedGuide"
                onChange={(e) => setSelectedGuide(e.target.value)}
              >
                {guideList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', height: '85vh' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Card style={{ height: cardHeight }}>
              <CardContent>
                <Typography variant="h5" component="div">
                  Unpaid Sales
                </Typography>
                <Typography
                  variant="h6"
                  color="primary"
                  align="center"
                  style={{ marginTop: cardInsideMargin }}
                >
                  Rs. {mainTotalValues.remainingCommissionOnSaleForGuide}
                </Typography>
              </CardContent>
            </Card>
            <Card style={{ height: cardHeight }} onClick={onUpdateCommissionClick}>
              <CardContent>
                <Typography variant="h5" component="div">
                  Expense %
                </Typography>
                <Typography
                  variant="h6"
                  color="text.secondary"
                  align="center"
                  style={{ marginTop: cardInsideMargin }}
                >
                  {mainTotalValues.presentCommissionPercentage}%
                </Typography>
              </CardContent>
            </Card>
            <Card style={{ height: cardHeight }}>
              <CardContent>
                <Typography variant="h5" component="div">
                Expense Balance
                </Typography>
                <Typography
                  variant="h6"
                  color={{ color: '#4CAF50' }}
                  align="center"
                  style={{ marginTop: cardInsideMargin }}
                >
                  Rs. {mainTotalValues.commissionToGive}
                </Typography>
              </CardContent>
            </Card>
            <Card style={{ height: cardHeight }}>
              <CardContent>
                <Typography variant="h5" component="div">
                Reimbursed Expense
                </Typography>
                <Typography
                  variant="h6"
                  color={{ color: '#FF5722' }}
                  align="center"
                  style={{ marginTop: cardInsideMargin }}
                >
                  Rs. {mainTotalValues.totalCommissionGiven}
                </Typography>
              </CardContent>
            </Card>
            {allowedActions['give-commission'] && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5" component="div" style={{ marginBottom: '10px' }}>
                  Payout
                </Typography>
                <TextField
                  variant="outlined"
                  value={commissionToGive}
                  onChange={handleCommissionChange}
                  style={{ marginBottom: '10px', width: '150px' }}
                />
                <Button
                  style={{
                    height: '2.5em',
                    width: '8em',
                    padding: '8px 0',
                    fontSize: '1em',
                    marginLeft: '5px',
                  }}
                  variant="contained"
                  disabled={!giveCommissionEnabled}
                  onClick={onPayClick}
                >
                  Pay
                </Button>
              </div>
            )}
          </div>
          <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <TablePagination
              rowsPerPageOptions={[10, 20, 50]}
              component="div"
              count={totalItemCount}
              rowsPerPage={pageSize}
              page={page - 1}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handlePageSizeChange}
              onBackButtonClick={handleBackButtonClick}
              onNextButtonClick={handleNextButtonClick}
            />
            <Table>
              <TableHead>
                <TableRow style={{ backgroundColor: '#1E3932', color: 'white' }}>
                  <TableCell style={{ fontWeight: 'bold', color: 'white' }}>ID</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Guide ID</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Date</TableCell>
                  <TableCell style={{ fontWeight: 'bold', color: 'white' }}>Sale Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactionHistory.map((transaction) => (
                  <TableRow key={transaction.id}>
                    <TableCell>{transaction.id}</TableCell>
                    <TableCell>{transaction.guide_id}</TableCell>
                    <TableCell>
                      {' '}
                      {format(new Date(transaction.date), 'MMMM dd, yyyy hh:mm a')}
                    </TableCell>
                    <TableCell>{transaction.total}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
