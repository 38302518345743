import React, { useState} from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import DonationTable from './components/donation-table';
import CustomLineChart from '../shared/line-chart';
// Define styles using makeStyles
const useStyles = makeStyles({
    chartBlock: {
        height: '50vh',
        display: 'flex',
        marginTop: '24px',
        flexDirection: 'column',
        alignItems: 'center',
    },
    chartMargin: {
        top: 12,
        right: 8,
        left: 8,
        bottom: 4,
    },
    chartToolTipParent: {
        padding: '4px',
        background: '#eaeaea',
        borderRadius: '8px',
    },

});
export default function DonationReport(props) {
    const [isLoading, setIsLoading] = useState(false);
    const {data} = props;
   
    const classes = useStyles();
    const lineChartOptions = {
        margin: {
            top: 20,
            right: 8,
            left: 16,
            bottom: 4,
        },
    };
    const customTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const element = payload[0];
            const title = element.dataKey === 'amount' ? 'Amount (LKR)' : 'Count';
            return (
                <div className={classes.chartToolTipParent}>
                    <p>
                        Date: <strong>{label}</strong>
                    </p>
                    <p>
                        {title} : <strong>{element.value}</strong>
                    </p>
                </div>
            );
        }
        return null;
    };
    return (
        <>
            <div className={classes.chartBlock}>
                <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
                    Total Donation Amount (LKR)
                </Typography>
                <CustomLineChart
                    data={data}
                    xaxisKey={'receiveddate'}
                    lines={[
                        {
                            key: 'totalearnings',
                            color: '#FF5733',
                        },
                    ]}
                    lineChart={{ ...lineChartOptions }}
                    customToolTip={customTooltip}
                />
            </div>
            <div className={classes.chartBlock}>
                <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
                    Total Donation Count
                </Typography>
                <CustomLineChart
                    data={data}
                    xaxisKey={'receiveddate'}
                    lines={[
                        {
                            key: 'totaldonations',
                            color: '#0096FF',
                        },
                    ]}
                    lineChart={{ ...lineChartOptions }}
                    customToolTip={customTooltip}
                />
            </div>
            <div style={{display: 'flex',marginTop: '24px',flexDirection: 'column',alignItems: 'center',}}>
                <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
                    Donation Table
                </Typography>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', height: '85vh' ,marginTop:'20px'}}>
                <DonationTable
                    isLoading={isLoading}
                    
                />
            </div>
        </>
    );
}
