import { Box, Typography, Radio, RadioGroup } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as React from 'react';
import { useState } from 'react';
import { OnlinePhotoShootRes } from '../Admin/photoshootReservation/OnlineBook/OnlinePhotoShootRes'; 
import { OnlineEntranceRes } from '../EntranceTicket/OnlineBook/OnlineEntranceRes';
import Header from '../Header';
import Footer from '../Footer';

const useStyles = makeStyles({
  background: {
    backgroundColor: '#f5f5f5',
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  main: {
    display: 'flex',
    overflowX: 'scroll',
  },
  radioLable:{
    display:'flex', 
    flexDirection:'column',
    alignItems: 'bottom',
    fontSize: '3.5vh',
    fontFamily: 'revert'
  }
});

const ReservationType = () => {
  const location = useLocation();
  const bookingDate = location.state?.bookingDate;
  const people = location.state?.people;
  const nationality = location.state?.nationality;
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = useState('Entrance');


  return (
    <>
    <Header />
    <Box pt="3rem">

      <Box className={classes.background} style={{display : 'flex', flexDirection: 'column', alignItems: 'center', flexWrap:'wrap',margin:'5px'}}>
        <div style={{border: '2.5px solid',padding:'15px', paddingInline: '100px', borderRadius: '15px'}}>

        <div >
          <h3 style={{marginTop: '-30px', backgroundColor: '#f5f5f5',paddingInline: '25px',marginInline: '-20px',width: 'fit-content'}} 
            > Select Your Reservation Type</h3>
        </div>
        <RadioGroup
        // style={{backgroundColor:'red'}}
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="Entrance"
            name="radio-buttons-group"
            onChange={(e)=>(setSelectedValue(e.target.value))}
            >
                <div style={{display:'flex', flexDirection:'row', alignItems: 'baseline', flexWrap:'wrap'}}>
                    <div style={{marginBottom: '16px'}}>
                        <FormControlLabel value="Entrance" control={<Radio />} labelPlacement="bottom"/> 
                    </div>
                    <div className={useStyles.radioLable}>
                        <div style={{fontFamily: 'revert',fontSize: '1.35rem'}}>
                            Entrance Ticket
                        </div>
                        <div style={{fontFamily: 'Inter ,sans-serif',color: '#92929D'}}>
                            (To visit Ambuluwawa Bio-diversity complex including the tower)
                        </div>

                    </div>
                    
                </div>
                <div style={{display:'flex', flexDirection:'row', alignItems: 'baseline', flexWrap:'wrap'}}>
                    <div style={{marginBottom: '16px'}}>
                        <FormControlLabel value="PhotoShoot" control={<Radio />} labelPlacement="bottom"/> 
                    </div>
                    <div className={useStyles.radioLable}>
                        <div style={{fontFamily: 'revert',fontSize: '1.35rem'}}>
                            Pre-shoot & Photo-shoot Reservations 
                        </div>
                        <div style={{fontFamily: 'Inter ,sans-serif',color: '#92929D'}}>
                            (To grab your memories with us)                
                        </div>

                    </div>

                    
                </div>
        </RadioGroup>
        </div>
        
      </Box>
      {
          selectedValue === 'Entrance' &&
          <div style={{marginTop : '6rem'}}>
            <OnlineEntranceRes bookingDate={bookingDate} people={people} nationality={nationality}/>  
          </div>       
      }
      {
        selectedValue === 'PhotoShoot' &&
          <div style={{marginTop : '6rem'}}>
            <OnlinePhotoShootRes bookingDate={bookingDate} people={people} nationality={nationality}/> 
          </div>
      }
    </Box>
    <Footer />
    </>
  );
};

export { ReservationType };
