import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import UserTable from './components/user-table';
import AddEditUser from './components/user-add-edit';
import ToggleStatusModal from './components/toggle-status-modal';

import { AppBar, IconButton, Slide, Toolbar, Typography } from '@mui/material';

import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useAuth } from '../../../context';
import { useAlertStore } from '../../../stores';
import { moduleIds } from '../../../utils/rolesAndPermissions';

const defaultUser = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  role: '',
  identification: '',
  position: '',
  password: '',
  email: '',
  username: '',
  dob: '',
};

export default function Users() {
  const { token, permissionsForAllowedModules } = useAuth();

  const allowedActions =
    (permissionsForAllowedModules && permissionsForAllowedModules[moduleIds.users]) || {};

  const fnShowAlert = useAlertStore((state) => state.showAlert);

  const [open, setOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState(defaultUser);

  const [isStatusToggleOpen, toggleStatusDialog] = useState(false);
  const [statusToggleDetails, setStatusToggleDetails] = useState({
    title: '',
    message: '',
    callBackData: undefined,
  });

  const showAlertBox = (message, type = 'success') => {
    fnShowAlert({
      message: message,
      severity: type,
    });
  };

  const getUsers = useCallback(async () => {
    setIsLoading(true);
    const resp = await sendPOSTRequest({ path: 'user/get' }, token);
    if (resp.status === 'success') {
      setUserList(resp.data);
      setIsLoading(false);
    }
  }, [token]);

  const toggleAddEditDialog = (show = false) => {
    if (!show) {
      setSelectedUser(defaultUser);
    }
    setOpen(show);
  };

  const editButtonClickHandler = (user) => {
    const { authkey, isactive, firstname, lastname, phone, dob, ...other } = user;

    let formattedDob = '';
    if (dob) {
      const date = new Date(dob).toLocaleDateString('en-US');
      const [month, day, year] = date.split('/');
      formattedDob = year;
      formattedDob += `-${month >= 10 ? month : `0${month}`}`;
      formattedDob += `-${day >= 10 ? day : `0${day}`}`;
    }

    setSelectedUser((prev) => {
      return {
        ...prev,
        firstName: firstname,
        lastName: lastname,
        phoneNumber: phone,
        dob: formattedDob,
        ...other,
      };
    });
    toggleAddEditDialog(true);
  };

  const saveClickHandler = async (user) => {
    setIsLoading(true);
    const isEdit = user.id && user.id > 0;
    const url = `user/${isEdit ? 'update' : 'new'}`;
    const response = await sendPOSTRequest({ path: url, payload: user }, token);
    if (response.status === 'success') {
      showAlertBox(`Successfully ${isEdit ? 'Updated' : 'Created New'} User`);
      toggleAddEditDialog();
      setIsLoading(false);
      await getUsers();
      return true;
    } else {
      showAlertBox(
        response.status === 'warning'
          ? response.message
          : `Error ${isEdit ? 'Updating' : 'Creating'} User`,
        response.status === 'warning' ? 'warning' : 'error'
      );
      setIsLoading(false);
    }
  };

  const resetPasswordHandler = async (user) => {
    setIsLoading(true);
    const response = await sendPOSTRequest(
      {
        path: 'user/reset-password',
        payload: {
          id: user.id,
          encodedPassword: btoa(user.password),
        },
      },
      token
    );

    if (response.status === 'success') {
      showAlertBox(`Successfully Changed User Password`);
      toggleAddEditDialog();
      setIsLoading(false);
      return true;
    } else {
      showAlertBox(
        response.status === 'warning' ? response.message : `Error Changing Password`,
        response.status === 'warning' ? 'warning' : 'error'
      );
      setIsLoading(false);
    }
  };

  const onToggleSwitchClick = async (show = false, data) => {
    toggleStatusDialog(show);
    const title = show ? `${data.isactive ? 'Deactivate' : 'Activate'} User` : '';
    const message = show
      ? `Do You Wish To ${data.isactive ? 'Deactivate' : 'Active'} This User ?`
      : '';
    const callBackData = show ? { id: data.id, isActive: !data.isactive } : undefined;
    setStatusToggleDetails((prev) => {
      return { title, message, callBackData };
    });
  };

  const toggleConfirmHandler = async (payload) => {
    setIsLoading(true);
    const response = await sendPOSTRequest(
      {
        path: 'user/toggle-status',
        payload: payload,
      },
      token
    );
    if (response.status === 'success') {
      onToggleSwitchClick(false);
      showAlertBox('Successfully Updated User Status');
      await getUsers();
    } else {
      showAlertBox(
        response.status === 'warning'
          ? response.message
          : `Error Updating User Status, please try again`,
        response.status === 'warning' ? 'warning' : 'error'
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', height: '85vh' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {allowedActions['add-user'] && (
            <Button
              style={{ marginLeft: '5px' }}
              variant="outlined"
              onClick={() => {
                toggleAddEditDialog(true);
              }}
            >
              Add User
            </Button>
          )}
        </div>
        <UserTable
          isLoading={isLoading}
          userList={userList}
          allowedActions={allowedActions}
          onToggleSwitchClick={(value) => onToggleSwitchClick(true, value)}
          onUpdateButtonClick={editButtonClickHandler}
        />
      </div>
      <AddEditUser
        open={open}
        isLoading={isLoading}
        selectedUser={selectedUser}
        saveClickHandler={saveClickHandler}
        closeHandler={() => toggleAddEditDialog()}
        resetPasswordHandler={resetPasswordHandler}
        showAlertBox={showAlertBox}
      />
      {allowedActions['delete-user'] && (
        <ToggleStatusModal
          open={isStatusToggleOpen}
          isLoading={isLoading}
          details={statusToggleDetails}
          confirmHandler={toggleConfirmHandler}
          closeHandler={() => onToggleSwitchClick(false)}
        />
      )}
    </div>
  );
}
