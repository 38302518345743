import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const modes = [
  {
    img: 'https://images.livemint.com/img/2022/07/21/600x338/train_1658429807599_1658429825810_1658429825810.jpg',
    title: 'Normal Trains',
  },
  {
    img: 'https://www.newswire.lk/wp-content/uploads/2022/08/Ella-Odyssey-Train.jpg',
    title: 'Express Trains - Ella Odyssey',
  },
  {
    img: 'https://i.pinimg.com/736x/c3/92/9a/c3929a0a04998725bc6bb85c0d5e0924.jpg',
    title: 'Public Busses',
  },
  {
    img: 'https://www.cpp.edu/fas/university-accounting-services/img/Auto%20Travel1.jpg',
    title: 'Private Vehicles',
  },
];

const TransportationModes = () => {
  return (
    <Box px="8vw" py="4rem">
      <Grid container spacing="4vw">
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            variant="p"
            sx={{
              fontSize: '6vw',
              fontWeight: '200',
              textAlign: 'center',
            }}
          >
            HOW TO REACH THE DESTINATION?
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <ImageList sx={{ width: '100%', height: '100%' }}>
            {modes.map((mode) => (
              <ImageListItem key={mode.img}>
                <img width="100%" src={mode.img} alt={mode.title} />
                <ImageListItemBar
                  align="right"
                  title={
                    <Typography
                      variant="h3"
                      sx={{
                        fontSize: '2vh',
                        fontFamily: 'revert',
                        color: 'white',
                      }}
                    >
                      {mode.title}
                    </Typography>
                  }
                ></ImageListItemBar>
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </Box>
  );
};

export { TransportationModes };
