import { Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  main: {
    marginTop: '4rem',
  },
  map: {
    width: '100%',
    height: '100%',
    marginTop: '4em',
  },
});

const SiteMap = () => {
  const classes = useStyles();

  return (
    <Container align="center" className={classes.main}>
      <Typography variant="h2" sx={{ fontSize: '4vh', fontFamily: 'revert', textAlign: 'center' }}>
        Site Map
      </Typography>
      <img
        src="https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Home/map.png"
        className={classes.map}
        alt="site map"
      />
    </Container>
  );
};

export { SiteMap };
