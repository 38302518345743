import React, { useCallback, useMemo, useState } from 'react';
import CreateNewGuide from '../Admin/tour-guide/components/create-new/components/create-guide';
import { IsPassword, IsPhoneNumber, isValideEmail } from '../Admin/shared/functions';
import { sendPOSTRequest } from '../../adaptors/bff-requests';
import { useAlertStore } from '../../stores';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../Shared';

export default function TourGuideRegister() {
  const navigate = useNavigate();
  const initialState = useMemo(
    () => ({
      id: '',
      firstName: '',
      lastName: '',
      dob: '',
      email: '',
      phoneNumber: '',
      identification: '',
      username: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      district: '',
      province: '',
      postalCode: '',
      country: 'Sri Lanka',
      bankAccountNumber: '',
      bankAccountName: '',
      bankName: '',
      bankBranch: '',
      newPassword: '',
      confirmPassword: '',
    }),
    []
  );
  const [tourGuide, setTourGuide] = useState(initialState);
  const [isError, setIsError] = useState({
    state: false,
    message: '',
  });
  const fnShowAlert = useAlertStore((state) => state.showAlert);
  const validationError = (status, message) => {
    setIsError({ state: status, message: message });
  };
  const onSubmit = useCallback(async () => {
    const tg = tourGuide;
    console.log(tg);
    if (
      tg.firstName &&
      tg.lastName &&
      tg.dob &&
      tg.phoneNumber &&
      tg.identification &&
      tg.addressLine1 &&
      tg.addressLine2 &&
      tg.city &&
      tg.district &&
      tg.province &&
      tg.postalCode &&
      tg.bankAccountName &&
      tg.bankAccountNumber &&
      tg.bankBranch &&
      tg.bankName &&
      tg.username &&
      tg.email &&
      tg.newPassword &&
      tg.password
    ) {
      if (tg.email && !isValideEmail(tourGuide.email)) {
        fnShowAlert({
          message: 'Invalid Email Address',
          severity: 'error',
        });
        validationError(true, 'Invalid Email Address');
        return null;
      } else if (!IsPhoneNumber(tg.phoneNumber)) {
        validationError(true, 'Invalid Phone Number');
        return null;
      } else if (tg.newPassword !== tg.password) {
        validationError(true, 'Passwords must match');
        return null;
      } else if (!IsPassword(tg.password)) {
        validationError(true, 'Passwords must be strong');
        return null;
      } else {
        validationError(false, '');
        const resp = await sendPOSTRequest(
          { path: 'user/guide/register', payload: tourGuide },
          null
        );
        if (resp.status === 'success') {
          console.log('success');
          fnShowAlert({
            message: 'New guide registered successfully',
            severity: 'success',
          });
          setTourGuide(initialState);
          navigate('/login');
        } else {
          console.log('error');
          fnShowAlert({
            message: resp.status === 'warning' ? resp.message : 'Failed to register the guide',
            severity: 'warning',
          });
        }
      }
    } else {
      if (!tg.firstName) validationError(true, 'First Name Cannot Be Empty!');
      else if (!tg.lastName) validationError(true, 'Last Name Cannot Be Empty!');
      else if (!tg.dob) validationError(true, 'Date of birth Cannot Be Empty!');
      else if (!tg.email) validationError(true, 'Email Cannot Be Empty!');
      else if (!tg.username) validationError(true, 'User Name Cannot Be Empty!');
      else if (!tg.phoneNumber) validationError(true, 'Phone Number Cannot Be Empty!');
      else if (!tg.identification) validationError(true, 'NIC/Passport Number Cannot Be Empty!');
      else if (!tg.addressLine1) validationError(true, 'Address Line 1 Cannot Be Empty!');
      else if (!tg.addressLine2) validationError(true, 'Address Line 2 Cannot Be Empty!');
      else if (!tg.city) validationError(true, 'City Cannot Be Empty!');
      else if (!tg.province) validationError(true, 'Province Cannot Be Empty!');
      else if (!tg.district) validationError(true, 'District Cannot Be Empty!');
      else if (!tg.postalCode) validationError(true, 'Postal Code Cannot Be Empty!');
      else if (!tg.bankAccountName)
        validationError(true, 'Name as in Bank Account Cannot Be Empty!');
      else if (!tg.bankAccountNumber) validationError(true, 'Bank Account Number Cannot Be Empty!');
      else if (!tg.bankName) validationError(true, 'Bank Name Cannot Be Empty!');
      else if (!tg.bankBranch) validationError(true, 'Branch Name Cannot Be Empty!');
      else if (!tg.newPassword) validationError(true, 'New Password Cannot Be Empty!');
      else if (!tg.password) validationError(true, 'Confirm Password Cannot Be Empty!');
      else validationError(true, 'Required Fields Cannot Be Empty!');
    }
  }, [tourGuide, initialState, fnShowAlert, navigate]);
  return (
    <PageWrapper
      props={{
        hideFooter: true,
      }}
    >
      <CreateNewGuide
        tourGuide={tourGuide}
        setTourGuide={setTourGuide}
        isError={isError}
        setIsError={setIsError}
        onSubmit={onSubmit}
        isUpdate={false}
      />
    </PageWrapper>
  );
}
