import React, { useState , useRef } from 'react';
import { Button, Input, InputLabel, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { useAuth } from '../../../context';
import { sendPOSTRequest } from '../../../adaptors/bff-requests';


export default function Announce() {
  const [uploadedFile, setUploadedFile] = useState(null);
  const { token } = useAuth();
  const fileInputRef = useRef(null);
  const [formFields, setFormFields] = useState({
    title: '',
    publish_date: '',
    description: '',
    filename: '',
  });
  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (files && files[0]) {
      const file = files[0];
      const validExtension = 'docx';
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (fileExtension !== validExtension) {
        Swal.fire('Error', 'Only .docx files are allowed.', 'error');
        setUploadedFile(null);
        if (fileInputRef.current) fileInputRef.current.value = '';
        return;
      }
      setUploadedFile(file);
      setFormFields((prevFields) => ({
        ...prevFields,
        filename: file.name,
      }));
    } else {
      setFormFields((prevFields) => ({
        ...prevFields,
        [name]: value,
      }));
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();
    if (!uploadedFile) {
      Swal.fire('Error', 'Please select a file to upload.', 'error');
      return;
    }
    try {
       // Read the file contents as ArrayBuffer
      const reader = new FileReader(); // base64
      reader.readAsDataURL(uploadedFile);

      reader.onloadend = async () => {
      const base64File = reader.result.split(',')[1];
        const createPayload = {
          isAnnounce: true,
          title: formFields.title,
          publish_date: formFields.publish_date,
          description: formFields.description,
          filename: formFields.filename,
          fileData: base64File, // Add base64 encoded file data
          isOngoing: formFields.status === 'Upcoming' ? false : true
        };
        const response = await sendPOSTRequest(
          { path: 'announcement-type1/new', payload: createPayload },
          token
        );
        if (response.status === 'success') {
          Swal.fire('Success', 'Announcement added successfully', 'success');
          setFormFields({
            title: '',
            publish_date: '',
            description: '',
            filename: '',
            
          });
          setUploadedFile(null);
          if (fileInputRef.current) fileInputRef.current.value = '';
        } else {
          throw new Error('Failed to create announcement');
        }
      };
    } catch (error) {
      console.error('Error uploading file or creating announcement:', error);
      Swal.fire('Error', error.message, 'error');
    }
  }
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: '15px' }}>
          <InputLabel htmlFor="file">Word Document *</InputLabel>
          <Input
            type="file"
            id="file"
            name="file"
            accept=".docx"
            onChange={handleInputChange}
            required
            inputRef={fileInputRef}
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="Title"
            name="title"
            value={formFields.title}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </div>
        <div style={{ marginTop: '25px' }}>
          <TextField
            label="Publish Date"
            name="publish_date"
            type="date"
            value={formFields.publish_date}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="Description"
            name="description"
            value={formFields.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            required
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="File Name"
            name="filename"
            value={formFields.filename}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            variant="contained"
            type="submit"
            style={{ width: '10em', padding: '8px 0', fontSize: '1em', marginTop: '20px' }}
          >
            Add Announcement
          </Button>
        </div>
      </form>
    </div>
  );
}