import React from 'react';
import { Button, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import FileDownloadSharpIcon from '@mui/icons-material/FileDownloadSharp';

export const REPORT_TYPE = [
  {
    name: 'Sales Report',
    value: 'salesReport',
  },
  {
    name: 'Visitor Report',
    value: 'visitorReport',
  },
  {
    name: 'Pre-shoots/Photo-shoot  Report',
    value: 'photoReport',
  },
  {
    name: 'Entrance Ticketing Report',
    value:'entranceTicketingReport'
  },
  {
    name: 'Donation Report',
    value:'donationReport'
  },
  {
    name: 'TourGuide Report',
    value: 'tourGuideReport',
  },
  {
    name: 'Rates',
    value: 'Itperson',
  },
];

export default function ReportFilterSection(props) {
  const { fromDate, toDate, frequency, ticketType } = props.filters;

  const onApplyClick = () => {
    props.onApply();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        columnGap: '1em',
      }}
    >
      <div>
        <InputLabel>Report Type</InputLabel>
        <Select
          style={{ minWidth: '9em', maxWidth: '9em' }} //maxWidth is set to fit in the components
          value={props.reportType}
          label="reportType"
          onChange={(e) => props.onReportTypeChanged(e.target.value)}
        >
          {REPORT_TYPE.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </div>
      {props.reportType !== 'Itperson' && (
      <div>
        <InputLabel>Frequency</InputLabel>
        <Select
          style={{ minWidth: '9em' }} //minWidth is changed from 10em to 9em
          value={frequency}
          label="frequencyType"
          onChange={(e) => props.onFiltersChanged('frequency', e.target.value)}
        >
          <MenuItem value={'daily'}>Daily Sales</MenuItem>
          <MenuItem value={'weekly'}>Weekly Sales</MenuItem>
          <MenuItem value={'monthly'}>Monthly Sales</MenuItem>
          <MenuItem value={'yearly'}>Yearly Sales</MenuItem>
        </Select>
      </div>
      )}
      {props.reportType === 'entranceTicketingReport' && (
      <div> 
      <InputLabel>Ticket Type</InputLabel>
        <Select
            style={{ minWidth: '9em' }}
            value={ticketType}
            label="ticketType"
            onChange={(e) => props.onFiltersChanged('ticketType', e.target.value)}
        >
          <MenuItem value={'onsite'}>onsite</MenuItem>
          <MenuItem value={'onsite-guide'}>onsite-guide</MenuItem>
          <MenuItem value={'online-paid'}>online-paid</MenuItem>
          <MenuItem value={'online-pay-at-gate'}>online-pay-at-gate</MenuItem>
          <MenuItem value={'online-guide-paid'}>online-guide-paid</MenuItem>
          <MenuItem value={'online-guide-pay-at-gate'}>online-guide-pay-at-gate</MenuItem>
        </Select>
      </div>  
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: '3px',
        }}
      >
        <div>
          <InputLabel>Date From</InputLabel>
          <TextField
            hiddenLabel
            variant="outlined"
            type="date"
            size="normal"
            value={fromDate}
            onChange={(e) => {
              props.onFiltersChanged('fromDate', e.target.value);
            }}
          />
        </div>
        <div style={{ marginTop: '24px' }}> - </div>
        <div>
          <InputLabel>Date To</InputLabel>
          <TextField
            hiddenLabel
            variant="outlined"
            type="date"
            size="normal"
            value={toDate}
            onChange={(e) => {
              props.onFiltersChanged('toDate', e.target.value);
            }}
          />
        </div>
      </div>
      <div style={{ display:'inline-block', marginTop: '24px' }}>
        <Button
          style={{ width: '10em', padding: '8px 0', fontSize: '1em',marginLeft:'5px' }}
          variant="contained"
          disabled={props.isLoading}
          onClick={onApplyClick}
        >
          Apply
          </Button>
          {props.allowedActions['export'] && (

       <Button
         style={{ width: '10em', padding: '8px 0', fontSize: '1em' ,marginLeft: '5px', marginTop: '5px' }}
         variant="outlined"
         disabled={props.isLoading}
         onClick={() => props.onExport()}
         startIcon={<FileDownloadSharpIcon />}
       >
         Export xlsx
       </Button>
     
      )}
      </div>
    </div>
  );
}
