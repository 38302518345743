import React, { useState } from 'react';
import { IsPhoneNumber } from '../../../utils/functions';
import AlertMessage from '../../Alerts/AlertMessage';
const EntranceTicketWithVisitors = ({ ticketInfo, setTicketInfo, changeCurrentPage,totalFee,listOfNationalities,rows,handleAddRow,handleRemoveRow,selectedNoAdults,selectedNoKids,selectedNationality,setSelectedNoAdults,setSelectedNoKids,setSelectedNationality}) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [errorAlert, setErrorAlert] = useState(false);
  const handleSubmitForm =  async (event) => {
    event.preventDefault();
    
    if (ticketInfo.country === 212) {

      if (ticketInfo.visitortype === 'tourist') {
        setErrorAlert(true);
        setAlertMessage('Please check your selected Nationality again');
        return null;
      }
    }

    if(rows.length==0){
      setErrorAlert(true);
      setAlertMessage('Please add visitors');
      return null;
    }

    if (ticketInfo.paymentMethod === 'card') {
      changeCurrentPage(3);
    }
    else {
      changeCurrentPage(4);
    }
  };
  return (
    <div>
      <form className="reservation-form" onSubmit={handleSubmitForm}>
      <div className="reservation-form-new-left">
          <div>
          <label style={{fontWeight:'600'}}>
            Visitors
            </label>
            <div className="multiple-nationalities-input" style={{ marginTop: '15px' }}>
            
              <div className="input-row">
                <label>
                  No of Adults
                  <input
                    type="number"
                    value={selectedNoAdults}
                    onChange={(e) => setSelectedNoAdults(e.target.value)}
                    max={100}
                    min={0}
                  />
                </label>
                <label>
                  No of Kids
                  <input
                    type="number"
                    value={selectedNoKids}
                    onChange={(e) => setSelectedNoKids(e.target.value)}
                    max={100}
                    min={0}
                  />
                </label>
                <label>
                  Nationality
                  <div className="inline">
                    <select
                      value={selectedNationality}
                      onChange={(e) => setSelectedNationality(e.target.value)}
                    >
                      {listOfNationalities.map((country) => {
                        return (
                          <option
                            key={country.id}
                            value={country.id}
                            selected={ticketInfo.country === country.id}
                          >
                            {country.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </label>
                <button className="add-button" onClick={(event)=>{event.preventDefault(); handleAddRow(selectedNoAdults,selectedNoKids ,selectedNationality); setSelectedNoAdults(0);setSelectedNoKids(0)}} >
                +
              </button>
              </div>
            </div>
            {rows.length > 0 && (
            <div className='table-container' >  
              <table className='custom-table'>
                <thead>
                  <tr>
                    <th className='custom-header'>Nationality</th>
                    <th className='custom-header'>No of Adults</th>
                    <th className='custom-header'>No of Kids</th>
                    <th className='custom-header'>Remove</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row, index) => (
                    <tr key={index}>
                      <td className='custom-cell'>{row.nationalityName || 'Sri Lanka'}</td>
                      <td className='custom-cell'>{row.adults}</td>
                      <td className='custom-cell'>{row.kids}</td>
                      <td className='custom-cell'>
                        <button className='remove-button' onClick={(event) => { event.preventDefault(); handleRemoveRow(index) }}>&ndash;</button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
             )}
          </div>
        </div>
        <div className="reservation-form__right">
          <label style={{fontWeight:'600'}}>
            Vehicle
          </label>
          <br />
          <label>
            Bike :
            <input   type="number"  max={100}  value={ticketInfo.bike} onChange={(e) => {
              let input = e.target.value;
              input = input.replace(/\D/g, '');
              setTicketInfo('bike', input)
            }} required />
          </label>
          <br />
          <label>
            ThreeWheel :
            <input   type="number"  max={100}  value={ticketInfo.threewheel} onChange={(e) => {
              let input = e.target.value;
              input = input.replace(/\D/g, '');
              setTicketInfo('threewheel', input)
            }} required />
          </label>
          <br />
          <label>
            Car :
            <input   type="number"  max={100}  value={ticketInfo.car} onChange={(e) => {
              let input = e.target.value;
              input = input.replace(/\D/g, '');
              setTicketInfo('car', input)
            }} required />
          </label>
          <br />
         
          <label>
            Van :
            <input   type="number"  max={100}  value={ticketInfo.van} onChange={(e) => {
              let input = e.target.value;
              input = input.replace(/\D/g, '');
              setTicketInfo('van', input)
            }} required />
          </label>
          <br />
          <label>
            Bus :
            <input   type="number"  max={100}  value={ticketInfo.bus} onChange={(e) => {
              let input = e.target.value;
              input = input.replace(/\D/g, '');
              setTicketInfo('bus', input)
            }} required />
          </label>
          <br />
          <label>
            Total Cost : &nbsp;&nbsp;
            <input type='text' value={totalFee} onChange={(e) => setTicketInfo('reservationFee', e.target.value)} required disabled style={{ width: "45%", border: "2px solid" }} />&nbsp;&nbsp;&nbsp;
            <select value={ticketInfo.currency} onChange={(e) => setTicketInfo('currency', e.target.value)} style={{ width: "35%" }} required>
              <option value="LKR" selected>LKR</option>
            </select>
          </label>
          <br />
        </div>
        <div className='form-footer'>
        <button className="button back-button" onClick={() => { changeCurrentPage(1) }}> Back </button>
        <input className='proceedBtn' type="submit" value='Next' />
        </div>
      </form>
      <AlertMessage
        message={alertMessage}
        show={errorAlert}
        closeHandler={(value) => setErrorAlert(false)}
      />
    </div>
  );
}
export { EntranceTicketWithVisitors };