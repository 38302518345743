import React, { useState, useEffect, useCallback } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';

import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoRounded';
import TicketTableData from './components/tableData';
import OnsiteTicketing from './components/OnsiteTicketing';
import SalesInfo from './components/salesInfo';
import { DeleteModal } from '../../Shared';

import { AppBar, IconButton, Slide, Toolbar, Typography } from '@mui/material';

import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useAuth } from '../../../context';
import { useAlertStore } from '../../../stores';
import { moduleIds, userRoles } from '../../../utils/rolesAndPermissions';
import { TicketingPayAtGate } from '../../TicketingPerson/Ticketing-PAG';
//import { TicketingPayAtGate } from '../../TicketingPerson/Ticketing-PAG';
import{TicketingPayAtGateIP} from '../../TicketingPerson/TicketingPerson-includingPayment/Ticketing-IP';
import { RegistrationDetails } from './components/RegistrationDetails';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Ticketing() {
  const { token, authorizedUser, permissionsForAllowedModules } = useAuth();

  const isGuide = authorizedUser.role === userRoles.guide;

  const allowedActions =
    (permissionsForAllowedModules && permissionsForAllowedModules[moduleIds.ticketing]) || {};

  const fnShowAlert = useAlertStore((state) => state.showAlert);

  const [open, setOpen] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [selectedTicketingOption, setSelectedTicketingOption] = useState('');
  const [selectedTicketPrint, setSelectedTicketPrint] = useState('');
  const [SelectedPayment, setSelectedPayment] = useState('');
  const [isListLoading, setIsListLoading] = useState(true);
  const [ticketRowCount, setTicketRowCount] = useState(0);
  const [tableProps, setTableProps] = useState({
    pageNum: 0,
    sortColumn: undefined,
    sortOrder: undefined,
    searchKeyword: '',
    tempProp: true,
  });

  const [ticketList, setTicketList] = useState([]);
  const [isticketInfoOpen,toggleTicketInfo]=useState(false);
  const [registrationDetails,setRegistrationDetails]=useState({});


  const [defaultNationality, setdefaultNationality] = useState(0);
  const [visitorCostDetails, setVisitorCost] = useState();
  const [vehicleCostDetails, setVehicleCost] = useState();
  const [listOfNationalities, setNationalities] = useState([]);
  const [existingTicketData, setExistingTicketData] = useState(undefined);

  const [isSalesInfoOpen, toggleSalesInfo] = useState(false);
  const [isSalesInfoLoading, toggleSalesInfoLoading] = useState(false);
  const [salesInfo, setSalesInfo] = useState({ total: undefined, visitorCount: undefined });
  
  const [isDeleteModalOpen, toggleDelete] = useState(false);
  const [isDeleteLoading, toggleDeleteLoading] = useState(false);
  const [deleteDetails, setDeleteMessage] = useState({
    title: 'Ticket',
    message: '',
    callBackData: undefined,
  });

  const showAlertBox = (message, type = 'success') => {
    fnShowAlert({
      message: message,
      severity: type,
    });
  };

  const handleClickOpen = (option) => {
    setOpen(!open);
    if (option) {
      setSelectedTicketingOption(option);
    } else {
      setExistingTicketData(undefined);
    }
  };
  const handleClickPrint = (printticket) => {
    resetTable();
    setOpenPrint(!openPrint);
    if (printticket) {
      setSelectedTicketPrint(printticket);
    }
  };
  const handleClickPayment = (Payment) => {
    setOpenPayment(!openPayment);
    if (Payment) {
      setSelectedPayment(Payment);
    }
  };

  const afterSaveHandler = async (response) => {
    if (!response) {
      showAlertBox('Error creating ticket, please try again', 'error');
      return;
    }
    handleClickOpen();
    showAlertBox(
      response.status === 'warning' ? response.message : 'Successfully created ticket',
      response.status === 'warning' ? 'warning' : 'success'
    );
    resetTable();
  };
  const onChangeSearchQuery =(s)=>{
    resetTable(s);
  }
  const resetTable = (keyword='') => {
    setTableProps((state) => {
      return {
        pageNum: 0,
        sortColumn: undefined,
        sortOrder: undefined,
        searchKeyword: keyword,
        tempProp: !state.tempProp,
      };
    });
  };

  const fetchContents = useCallback(async () => {
    const visitorCostResp = await sendPOSTRequest({ path: 'visitor/get-types' }, token);
    setVisitorCost(visitorCostResp ? visitorCostResp.data || [] : []);
    const vehicleCostResp = await sendPOSTRequest({ path: 'vehicle/get' }, token);
    setVehicleCost(vehicleCostResp ? vehicleCostResp.data || [] : []);

    const nationalityResp = await sendPOSTRequest({ path: 'nationality/get' }, token);
    if (nationalityResp) {
      const topTenNationalites = nationalityResp.data.topTenCountries || [];
      const nationalities = nationalityResp.data.allCountries || [];
      const topTenCountryIds = topTenNationalites.map(country => country.id);
      const filteredNationalities = nationalities.filter(country => !topTenCountryIds.includes(country.id));
      setNationalities(topTenNationalites.concat(filteredNationalities));
      setdefaultNationality(1)
    }
  }, [token]);

  const getTicketList = useCallback(async () => {
    setIsListLoading(true);
    const resp = await sendPOSTRequest(
      {
        path: 'ticket/get',
        payload: {
          filterByUser: isGuide,
          page: tableProps.pageNum,
          sortColumn: tableProps.sortColumn,
          sortOrder: tableProps.sortOrder,
          keyword: tableProps.searchKeyword,
        },
      },
      token
    );
    const hasData = resp && resp.data;
    setTicketRowCount(hasData ? resp.data.rowCount : 0);
    setTicketList(hasData ? resp.data.listOfTickets : []);
    setIsListLoading(false);
  }, [token, tableProps]);

  const onPageChangeHandler = async (nextPageNum) => {
    setTableProps((previous) => {
      return { ...previous, pageNum: nextPageNum };
    });
  };

  const onSortChangeHandler = async (model, details) => {
    if (model) {
      setTableProps((previous) => {
        return { ...previous, sortColumn: model[0]?.field, sortOrder: model[0]?.sort };
      });
    }
  };

  const toggleInfoDialog = async (show = false) => {
    toggleSalesInfo(show);
    setSalesInfo({ total: undefined, visitorCount: undefined });
  };
  
  const applyInfoClickHandler = async (selectedDate) => {
    toggleSalesInfoLoading(true);
    const salesInfoResponse = await sendPOSTRequest(
      {
        path: 'sales-per-day',
        payload: {
          selectedDate,
          filterByUser: isGuide,
        },
        queryParams:'/analytics'
      },
      token
    );
    if (salesInfoResponse.data) {
      setSalesInfo(salesInfoResponse.data);
      toggleSalesInfo(true);
    } else {
      showAlertBox('Error retrieving data, please try again', 'error');
    }
    toggleSalesInfoLoading(false);
  };

  const showHideDeleteDialog = async (show = false, data) => {
    toggleDelete(show);
    const message = show ? `Do You Wish To Delete The Ticket #${data.serialnumber} ?` : '';
    const callBackData = show ? data.ticketid : undefined;
    setDeleteMessage((prev) => {
      return { ...prev, message, callBackData };
    });
  };

  const deleteConfirmHandler = async (selectedTicketId) => {
    toggleDeleteLoading(true);
    const deleteResponse = await sendPOSTRequest(
      {
        path: 'ticket/delete',
        payload: {
          ticketId: selectedTicketId,
        },
      },
      token
    );
    if (deleteResponse.status === 'success') {
      showHideDeleteDialog(false);
      resetTable();
      showAlertBox('Successfully Deleted Ticket');
    } else {
      showAlertBox('Error Deleting Ticket, please try again', 'error');
    }
    toggleDeleteLoading(false);
  };
  const showRegisterationDetail=async (selectedTicketId) => {
    if(selectedTicketId === undefined)
    {
      return showAlertBox('Error whhile getting details', 'error');
    }
    const Response = await sendPOSTRequest(
      {
        path: 'ticket/get-by-id',
        payload: {
          ticketId: selectedTicketId.ticketid,
        },
      },
      token
    );
    if (Response.status === 'success') {
      setRegistrationDetails(Response.data);
      toggleTicketInfodetails();
    } else {
      showAlertBox('Error while getting details', 'error');
    }

  }
  function getCountryNameById(id)  {
    console.log(typeof(id));
    id = parseInt(id);
    const foundObject = listOfNationalities.find(item => item.id === id);
    console.log(foundObject)
    return foundObject ? foundObject.name : null;
  }
  const onViewEditClickHandler = async (row) => {
    setIsListLoading(true);
    const getTicketResponse = await sendPOSTRequest(
      {
        path: 'ticket/get-by-id-for-mobile',
        payload: {
          ticketId: row.ticketid,
        },
      },
      token
    );
    setIsListLoading(false);

    if (!getTicketResponse.data) {
      showAlertBox(
        getTicketResponse.status === 'warning'
          ? getTicketResponse.message
          : 'Error getting ticket details',
        getTicketResponse.status === 'warning' ? 'warning' : 'error'
      );
      return;
    }

    const { ticketDetails, vehicleDetails, visitorDetails } = getTicketResponse.data;

    const ticketData = {
      id: row.ticketid,
      guideCode: ticketDetails.userCode,
      subTotal: ticketDetails.amount,
      paymentStatus: ticketDetails.paymentStatus,
      ticketStatus: ticketDetails.ticketStatus,
      ticketType: ticketDetails.ticketType,
      visitorType: 'local',
      nationality: 0,
      kidsCount: 0,
      adultsCount: 0,
      noOfBikes: 0,
      noOfThreewheels: 0,
      noOfVans: 0,
      noOfCars: 0,
      noOfBuses: 0,
    };

    if (visitorDetails.length > 0) {
      const visitorType = visitorDetails[0].visitorType.split('-')[0];
      const nationalityId = visitorDetails[0].nationalityId;
      const kids = visitorDetails.find((x) => x.visitorType.includes('kids'));
      const adults = visitorDetails.find((x) => x.visitorType.includes('adults'));

      ticketData.visitorType = visitorType;
      ticketData.nationality = nationalityId;
      ticketData.kidsCount = kids ? kids.count : 0;
      ticketData.adultsCount = adults ? adults.count : 0;
      
      const rowData = [];
      visitorDetails.forEach(item => {
        const existingItemIndex = rowData.findIndex(outputItem => outputItem.nationality === item.nationalityId);
    
        if (existingItemIndex !== -1) {
            if (item.visitorType.includes('adults')) {
                rowData[existingItemIndex].adults += item.count;
            } else if (item.visitorType.includes('kids')) {
                rowData[existingItemIndex].kids += item.count;
            }
        } else {
           const nationalityName = getCountryNameById(item.nationalityId);
            const newItem = {
                adults: 0,
                kids: 0,
                nationality: item.nationalityId,
                nationalityName:nationalityName
            };
    
            if (item.visitorType.includes('adults')) {
                newItem.adults += item.count;
            } else if (item.visitorType.includes('kids')) {
                newItem.kids += item.count;
            }
    
            rowData.push(newItem);
        }
      });
      ticketData.rows=rowData;
    }

    if (vehicleDetails.length > 0) {
      const bikes = vehicleDetails.find((x) => x.name === 'Bike');
      const threeWheels = vehicleDetails.find((x) => x.name === 'Three-Wheeler');
      const vans = vehicleDetails.find((x) => x.name === 'Van');
      const cars = vehicleDetails.find((x) => x.name === 'Car');
      const buses = vehicleDetails.find((x) => x.name === 'Bus');

      ticketData.noOfBikes = bikes ? bikes.count : 0;
      ticketData.noOfThreewheels = threeWheels ? threeWheels.count : 0;
      ticketData.noOfVans = vans ? vans.count : 0;
      ticketData.noOfCars = cars ? cars.count : 0;
      ticketData.noOfBuses = buses ? buses.count : 0;
    }

    setExistingTicketData(ticketData);
    console.log("ticketData",ticketData);
    handleClickOpen(row.paymentstatus === 'pending' ? 'Confirm Ticket' : 'View Ticket');
  };
  const toggleTicketInfodetails=async () => {
    toggleTicketInfo(!isticketInfoOpen);
  };
  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  useEffect(() => {
    getTicketList();
  }, [getTicketList]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', height: '85vh' }}>
        <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
        <TextField
          id="filled-search"
          label="Search"
          type="search"
          variant="outlined"
          onChange={(e) => {
            onChangeSearchQuery(e.target.value)
          }}
        />
          {allowedActions['add-ticket'] && (
            <Button
              style={{ marginLeft: '15px' }}
              variant="outlined"
              onClick={() => {
                handleClickOpen('Onsite Tickets');
              }}
            >
              On-site Ticketing - Guest
            </Button>
          )}
           {allowedActions['print-ticket'] && (
            <Button
              style={{ marginLeft: '15px' }}
              variant="outlined"
              onClick={() => {
                handleClickPrint('Print Tickets');
              }}
            >
              On-site Ticketing-PayAtGate
            </Button>
          )}
           {allowedActions['print-ticket-online'] && (
            <Button
              style={{ marginLeft: '15px' }}
              variant="outlined"
              onClick={() => {
                handleClickPayment('print-ticket-online ');
              }}
            >
              On-site Ticketing - Including Payment
            </Button>
          )}
          {allowedActions['view-sales-info'] && (
            <IconButton
              aria-label="sales info"
              size="large"
              color="primary"
              onClick={() => toggleInfoDialog(true)}
            >
              <InfoIcon />
            </IconButton>
          )}
        </div>
        <TicketTableData
          isLoading={isListLoading}
          ticketList={ticketList}
          pageNum={tableProps.pageNum}
          rowCount={ticketRowCount}
          allowedActions={allowedActions}
          isGuide={isGuide}
          onPageChangeHandler={onPageChangeHandler}
          onSortChangeHandler={onSortChangeHandler}
          onDeleteButtonClick={(value) => showHideDeleteDialog(true, value)}
          onVisibleButtonClick={(value)=> showRegisterationDetail(value)}
          onViewEditClickHandler={onViewEditClickHandler}
        />
      </div>
      <Dialog
        fullScreen
        open={open}
        onClose={() => {
          handleClickOpen();
        }}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClickOpen();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedTicketingOption}
            </Typography>
          </Toolbar>
        </AppBar>
        <OnsiteTicketing
          listOfNationalities={listOfNationalities}
          visitorCostDetails={visitorCostDetails}
          vehicleCostDetails={vehicleCostDetails}
          defaultNationality={defaultNationality}
          existingTicketData={existingTicketData}
          afterSaveHandler={afterSaveHandler}
          showAlert={showAlertBox}
          isGuide={isGuide}
        />
       
      </Dialog>
      <Dialog
        fullScreen
        open={openPrint}
        onClose={() => {
          handleClickPrint();
        }}
        TransitionComponent={Transition}
      >
      <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClickPrint();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedTicketPrint}
            </Typography>
          </Toolbar>
        </AppBar>
        <TicketingPayAtGate
        />
         </Dialog>
         <Dialog
        fullScreen
        open={openPayment}
        onClose={() => {
          handleClickPayment();
        }}
        TransitionComponent={Transition}
      >
      <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleClickPayment();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {SelectedPayment}
            </Typography>
          </Toolbar>
        </AppBar>
        <TicketingPayAtGateIP
        />
                 </Dialog>
         <Dialog
        fullScreen
        open={isticketInfoOpen}
        onClose={() => {
          toggleTicketInfodetails();
        }}
        TransitionComponent={Transition}
      >
      <AppBar sx={{ position: 'fixed' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                toggleTicketInfodetails();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Registration Details
            </Typography>
          </Toolbar>
        </AppBar>
        <RegistrationDetails registrationDetails={registrationDetails}
        />
         </Dialog>
         

      {allowedActions['view-sales-info'] && (
        <SalesInfo
          open={isSalesInfoOpen}
          isLoading={isSalesInfoLoading}
          salesInfo={salesInfo}
          applyInfoClickHandler={applyInfoClickHandler}
          closeHandler={() => toggleInfoDialog(false)}
        />
      )}
      {allowedActions['delete-ticket'] && (
        <DeleteModal
          open={isDeleteModalOpen}
          isLoading={isDeleteLoading}
          deleteDetails={deleteDetails}
          deleteConfirmHandler={deleteConfirmHandler}
          closeHandler={() => showHideDeleteDialog(false)}
        />
      )}
    </div>
    
  );
}