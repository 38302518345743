import { PageWrapper } from '../components/Shared';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { DocxGrid } from '../components/Announcements';
import DocxGridOld from '../components/Announcements/DocxGridOld';

const useStyles = makeStyles({
  homeImage: {
    height: '60vh',
    width: '100vw',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
  },
  homeContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 100,
    height: '60vh',
  },
  homeTextStyles: {
    background:
      '-webkit-linear-gradient(360deg, rgba(0,0,0,0.7105435924369747) 0%, rgba(0,0,0,0.8477984943977591) 46%, rgba(0,0,0,1) 100%);',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  },
  line: {
    width: '100%',
    height: '1px',
    background: 'rgba(0, 0, 0, 0.5)', // Change this to your desired line color
    margin: '1rem 0', // Adjust margin as needed
  },
});

const Announcements = () => {
  const classes = useStyles();
  return (
    <PageWrapper style={{ height: '100vh', width: '100wh', backgroundColor: 'red' }}>
      <img
        src="https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Home/home-image.jpg"
        className={classes.homeImage}
        alt="home"
      />
      <div className={classes.homeContainer}>
        <Typography
          variant="h1"
          className={classes.homeTextStyles}
          fontWeight={700}
          zIndex={10}
          style={{ marginLeft: '4rem' }}
        >
          Announcements
        </Typography>
      </div>
      <DocxGridOld />
      <DocxGrid />
    </PageWrapper>
  );
};

export { Announcements };
