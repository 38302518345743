import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectDistric(props) {
  const selectedProvince = props.province;
  const width = props.width;

  const distric = {
    centralprovince: ['Kandy', 'Matale', 'Nuwara Eliya'],
    northcentralprovince: ['Anuradhapura', 'Polonnaruwa'],
    northernprovince: ['Jaffna', 'Kilinochchi', 'Mannar', 'Vavuniya', 'Mullativu', 'Alambil'],
    easternprovince: ['Ampara', 'Batticaloa', 'Trincomalee'],
    northwesternprovince: ['Kurunagala', 'Puttalam'],
    southernprovince: ['Galle', 'Hambanthota', 'Mathara'],
    uvaprovince: ['Badulla', 'Monaragala'],
    sabaragamuwaprovince: ['Kegalle', 'Rathnapura'],
    westernprovince: ['Colombo', 'Gampaha', 'Kaluthara'],
  };

  return (
    <FormControl style={{ width: width }}>
      <InputLabel id="distric-simple-select-label">Distric</InputLabel>
      <Select
        labelId="distric-simple-select-label"
        id="distric-simple-select"
        value={props.distric}
        label="Distric"
        error={!props.distric}
        disabled={!selectedProvince}
        onChange={(e) => {
          props.setDistric(e.target.value);
        }}
      >
        {distric[selectedProvince]?.map((e) => (
          <MenuItem value={e}>{e}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
