import React from 'react';
import { TextField, Typography } from '@mui/material';
import { isNumberOnly } from '../../../../../utils/functions';

export const VehicleComponenet = (props) => {
  return (
    <div
      className="twoBoxes"
      style={{
        marginRight: '10px',
        borderLeft: '2px solid #000000',
        borderRight: '2px solid #000000',
        borderTopRightRadius: '20px',
      }}
    >
      <div style={{ marginTop: '30px' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h5">Vehicles</Typography>
          </div>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              marginTop: '20px',
              justifyContent: 'center',
            }}
          >
            <TextField
              style={{ width: '200px', margin: '10px' }}
              id="outlined-textarea-bikes"
              label="No Of Bikes"
              type="number"
              value={props.noOfBikes}
              disabled={props.isEditMode}
              onChange={(e) => {
                const isValid = isNumberOnly(e.target.value);
                props.setNoOfBikes(isValid ? e.target.value : '');
              }}
            />
            <TextField
              style={{ width: '200px', margin: '10px' }}
              id="outlined-textarea-two"
              label="No Of Three Wheels"
              type="number"
              value={props.noOfThreewheels}
              disabled={props.isEditMode}
              onChange={(e) => {
                const isValid = isNumberOnly(e.target.value);
                props.setNoOfThreewheels(isValid ? e.target.value : '');
              }}
            />
            <TextField
              style={{ width: '200px', margin: '10px' }}
              id="outlined-textarea-vans"
              label="No Of Vans"
              type="number"
              value={props.noOfVans}
              disabled={props.isEditMode}
              onChange={(e) => {
                const isValid = isNumberOnly(e.target.value);
                props.setNoOfVans(isValid ? e.target.value : '');
              }}
            />
            <TextField
              style={{ width: '200px', margin: '10px' }}
              id="outlined-textarea-cars"
              label="No Of Cars"
              type="number"
              value={props.noOfCars}
              disabled={props.isEditMode}
              onChange={(e) => {
                const isValid = isNumberOnly(e.target.value);
                props.setNoOfCars(isValid ? e.target.value : '');
              }}
            />
            <TextField
              style={{ width: '200px', margin: '10px' }}
              id="outlined-textarea-buses"
              label="No Of Buses"
              type="number"
              value={props.noOfBuses}
              disabled={props.isEditMode}
              onChange={(e) => {
                const isValid = isNumberOnly(e.target.value);
                props.setNoOfBuses(isValid ? e.target.value : '');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
