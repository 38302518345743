import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';

const useStyles = makeStyles({
  background: {
    backgroundColor: '#F4FAFC',
    paddingTop: '3rem',
    paddingBottom: '2rem',
  },
  main: {
    display: 'flex',
    overflowX: 'scroll',
  },
});

const imgs = [
  {
    key: 1,
    category: 'Main Tower',
    image:
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/main-tower/tower_5.jpg',
    links: [
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/main-tower/tower_1.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/main-tower/tower_2.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/main-tower/tower_3.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/main-tower/tower_4.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/main-tower/tower_5.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/main-tower/tower_6.jpg',
    ],
  },
  {
    key: 2,
    category: 'Places of Worship',
    image:
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_11.jpg',
    links: [
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_1.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_2.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_3.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_4.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_5.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_6.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_7.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_8.JPG',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_9.JPG',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_10.JPG',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_11.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_12.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_13.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_14.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_15.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/places-of-worship/worship_16.jpg',
    ],
  },
  {
    key: 3,
    category: 'Beautiful Paths',
    image:
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/beautiful-paths/paths_1.jpg',
    links: [
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/beautiful-paths/paths_1.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/beautiful-paths/paths_2.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/beautiful-paths/paths_3.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/beautiful-paths/paths_4.JPG',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/beautiful-paths/paths_5.JPG',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/beautiful-paths/paths_6.jpg',
    ],
  },
  {
    key: 4,
    category: 'Location Scenaries',
    image:
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/location-scenaries/scenaries_2.jpg',
    links: [
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/location-scenaries/scenaries_1.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/location-scenaries/scenaries_2.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/location-scenaries/scenaries_3.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/location-scenaries/scenaries_4.jpg',
    ],
  },
  // {
  //   key: 5,
  //   category: '360° View Photos',
  //   image: 'worship_2.jpg',
  //   links: [
  //     'worship_1.jpg',
  //     'worship_2.jpg',
  //     'worship_3.jpg',
  //     'worship_4.jpg',
  //     'worship_5.jpg',
  //     'worship_6.jpg',
  //     'worship_7.jpg',
  //     'worship_8.JPG',
  //     'worship_9.JPG',
  //     'worship_10.JPG',
  //     'worship_11.jpg',
  //     'worship_12.jpg',
  //     'worship_13.jpg',
  //     'worship_14.jpg',
  //     'worship_15.jpg',
  //     'worship_16.jpg',
  //   ],
  // },
  {
    key: 6,
    category: 'Animals',
    image:
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/animals/animals.jpg',
    links: [
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/animals/animals_1.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/animals/animals_2.jpg',
    ],
  },
  {
    key: 7,
    category: 'Agro Park',
    image:
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_5.jpg',
    links: [
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_1.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_2.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_3.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_4.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_5.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_6.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_7.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_8.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_9.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_10.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/agro-park/agro_11.jpg',
    ],
  },
  {
    key: 8,
    category: 'Couple Pre-shoots',
    image:
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_4.jpg',
    links: [
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_1.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_2.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_3.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_4.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_5.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_6.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_7.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_8.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_9.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_10.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_11.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_12.jpg',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/couple-pre-shoots/shoots_13.jpg',
    ],
  },
  // {
  //   key: 9,
  //   category: 'Sun Rise',
  //   image: 'worship_5.jpg',
  //   links: [
  //     'worship_1.jpg',
  //     'worship_2.jpg',
  //     'worship_3.jpg',
  //     'worship_4.jpg',
  //     'worship_5.jpg',
  //     'worship_6.jpg',
  //     'worship_7.jpg',
  //     'worship_8.JPG',
  //     'worship_9.JPG',
  //     'worship_10.JPG',
  //     'worship_11.jpg',
  //     'worship_12.jpg',
  //     'worship_13.jpg',
  //     'worship_14.jpg',
  //     'worship_15.jpg',
  //     'worship_16.jpg',
  //   ],
  // },
  {
    key: 10,
    category: 'Elder House',
    image:
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/elder-house/elder_2.JPG',
    links: [
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/elder-house/elder_1.JPG',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/elder-house/elder_2.JPG',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/elder-house/elder_3.JPG',
      'https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Gallery/elder-house/elder_4.JPG',
    ],
  },
];

const MainGallery = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box px="2vw" className={classes.background}>
        <Typography
          variant="h2"
          sx={{
            fontSize: '4vh',
            fontFamily: 'revert',
            paddingTop: '2rem',
            textAlign: 'center',
          }}
        >
          Gallery
        </Typography>
        <Box px="4vw" py="8vw" className={classes.main}>
          <Grid container columnSpacing={2} rowSpacing={8}>
            {imgs.map((img) => (
              <Grid item key={img.key} xs={12} sm={6} md={3}>
                <Button href={'/gallery/' + img.category}>
                  <Card>
                    <CardMedia
                      component="img"
                      alt={img.category}
                      height="400rem"
                      image={img.image}
                    />
                    <CardContent align="left">
                      <Typography gutterBottom variant="h5">
                        {img.category}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {img.links.length}
                      </Typography>
                    </CardContent>
                  </Card>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export { MainGallery };
