import { BrowserRouter, Routes as Switch, Route, Navigate } from 'react-router-dom';
import ConfirmSignUp from './components/Auth/ConfirmSignUp';
import { useCallback, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import SignIn from './components/Auth/SignIn';
import ForgotPassword from './components/Auth/ForgotPassword';
import SignUp from './components/Auth/SignUp';
import { useAuth } from './context';
import { ViewGallery, ViewHome, AdminPage, ViewGalleryImages } from './views';
import { SpinnerComponent } from './components/Shared';
import { userRoles } from './utils/rolesAndPermissions';
import { ViewAttractionsDetails } from './views/ViewAttractionsDetails';
import { ReservationType } from './components/ReservationType/ReservationType';
import { Announcements } from "./views/Announcements";
import { Donation } from './components/Donation';
import TourGuideRegister from './components/Auth/TourGuideRegister';
import { Publish } from './components/NewsEvents/Publish';
import { PublishNews } from './components/NewsEvents/PublishNews';
import { PublishEvents } from './components/NewsEvents/PublishEvents';
import { ViewNewsDetails } from './views/ViewNewsDetails';
import { ViewEventsDetails } from './views/ViewEventsDetails';
import { UpdateNews } from './components/Admin/newsAndEvent/update';
import { UpdateEvent } from './components/Admin/newsAndEvent/updateEvent';
import { OnlinePhotoShootRes } from './components/Admin/photoshootReservation/OnlineBook/OnlinePhotoShootRes';

const Routes = () => {
  const { isAuthReady, isAuthenticated, signIn, signOut, authorizedUser } = useAuth();

  const auth = useCallback(async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        signIn(user);
      }
    } catch (e) {
      await Auth.signOut();
      signOut();
    }
  }, [signIn, signOut]);

  useEffect(() => {
    auth();
  }, [auth]);

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" element={<ViewHome />} />
        <Route path="/register" element={<SignUp />} />
        <Route path="/register-tour-guide" element={<TourGuideRegister />} />
        <Route path="/confirm-account" element={<ConfirmSignUp />} />
        <Route path="/reset-password" element={<ForgotPassword />} />
        <Route path="/gallery" element={<ViewGallery />} />
        <Route path="/announcements" element={<Announcements />} />
        <Route path="/gallery/:category" element={<ViewGalleryImages />} />
        <Route path="/attractions/:place" element={<ViewAttractionsDetails />} />
        <Route path="/donation" element={<Donation/>}/>
        <Route path="/publish" element={<Publish/>}/>
        <Route path="/publish/news" element={<PublishNews/>}/>
        <Route path="/publish/events" element={<PublishEvents/>}/>
        <Route path="/news/details/:place" element={<ViewNewsDetails />} />
        <Route path="/events/details/:place" element={<ViewEventsDetails />} />
        <Route path="/news/updateNews/:place" element={<UpdateNews/>} />
        <Route path='/news/updateEvent/:place' element={<UpdateEvent/>} />
        <Route path="/photoshootres/onlinephotoshootres" element={<OnlinePhotoShootRes />} />
        <Route 
          path="/guide"
          element={
            !isAuthReady ? (
              <SpinnerComponent />
            ) : isAuthenticated ? (
              <AdminPage />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/admin"
          element={
            !isAuthReady ? (
              <SpinnerComponent />
            ) : authorizedUser && authorizedUser.role == userRoles.guide ? (
              <Navigate to="/guide" />
            ) : isAuthenticated ? (
              <AdminPage />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/login"
          element={
            !isAuthReady ? (
              <SpinnerComponent />
            ) : !isAuthenticated ? (
              <SignIn />
            ) : (
              <Navigate to="/admin" />
            )
          }
        />
        <Route
          path="/guide"
          element={
            !isAuthReady ? (
              <SpinnerComponent />
            ) : !isAuthenticated ? (
              <SignIn />
            ) : (
              <Navigate to="/guide" />
            )
          }
        />
        <Route path="/reservationType" element={<ReservationType />} /> 
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
