import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { LinearProgress, IconButton, Button } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { saveAs } from 'file-saver';
import { useAuth } from '../../../../context';

export default function FeedbacksTable(props) {
  const {
    authorizedUser: { authKey },
  } = useAuth();

  const { feedbacksList, isLoading, allowedActions } = props;

  const numColumnProps = { align: 'right', headerAlign: 'right' };

  const columns = [
    { field: 'id', headerName: '#FeedbackNo', ...numColumnProps },
    { field: 'name', headerName: 'Name', flex: 1 },
    { field: 'phoneNumber', headerName: 'Phone Number', flex: 1 },
    { field: 'country', headerName: 'Country', flex: 1 },
    { field: 'message', headerName: 'Message', flex: 1 },
  ];

  function downloadFeedbacksToCsv() {
    try {
      const header = 'id,name,phone_number,country,message\n';

      const rows = feedbacksList.map((feedback) => {
        const values = [feedback.id, feedback.name, feedback.phone_number, feedback.country, feedback.message];

        return values.join(',');
      });

      const csv = header + rows.join('\n');

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

      saveAs(blob, 'feedbacks.csv');

      console.info('download feedbacks to CSV success');
    } catch (e) {
      console.log('Error downloading feedbacks to CSV', e);
      throw e;
    }
  }

  if (allowedActions['edit-feedbacks'] && allowedActions['delete-feedbacks']) {
    columns.push({
      field: 'action',
      headerName: 'Actions',
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        const isSameUser = params.value.authkey === authKey;
        return (
          <>
            {allowedActions['edit-feedbacks'] && (
              <Tooltip title="View/Update">
                <IconButton
                  aria-label="update"
                  size="small"
                  onClick={() => props.onUpdateButtonClick(params.value)}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
            {allowedActions['delete-feedbacks'] && !isSameUser && (
              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => props.onDeleteButtonClick(params.value)}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    });
  }

  const getRows = () => {
    const rows =
      feedbacksList &&
      feedbacksList.map((e, i) => {
        return {
          id: e.id,
          name: e.name,
          phoneNumber: e.phone_number,
          country: e.country,
          message: e.message,
          action: e,
        };
      });
    return rows;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div>
          <Button variant="outlined" onClick={() => downloadFeedbacksToCsv()}>
            Download Feedbacks
          </Button>
        </div>
      </div>
      <div
        style={{
          height: '100%',
          width: '100%',
          marginTop: '10px',
        }}
      >
        {feedbacksList && feedbacksList.length && (
          <DataGrid
            style={{ color: 'green', fontSize: '12px' }}
            components={{ LoadingOverlay: LinearProgress }}
            loading={isLoading}
            rows={getRows()}
            columns={columns}
            pageSize={20}
            rowsPerPageOptions={[20]}
          />
        )}
      </div>
    </div>
  );
}
