import { Box, Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Carousel from 'react-material-ui-carousel';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { useState, useEffect } from 'react';
import { sendPOSTRequest } from '../../adaptors/bff-requests';
const useStyles = makeStyles({
  main: {
    marginTop: '4rem',
  },
  imgs: {
    width: '100%',
    height: '100%',
  },
});
const EventsDetails = () => {
  const classes = useStyles();
  const params = useParams();
  console.log(params.place);

  const [eventbyid, setEventbyid] = useState({});
  useEffect(() => {
    const getAllEvents = async () => {
      const eventPayload = {
        limit: 2,
      };
      const eventResopnse = await sendPOSTRequest({
        path: 'latest',
        payload: eventPayload,
        queryParams: '/news-event',
      });
      console.log('event Resoponse');
      console.log(eventResopnse);
      const arrayOngoing = eventResopnse.data.events.ongoing;
      const arrayUpcoming = eventResopnse.data.events.upcoming;
      console.log(arrayOngoing);
      const allList = arrayOngoing.concat(arrayUpcoming);
      setEventbyid(allList.find((event) => event.id == params.place));
      console.log('The event by id is');
      console.log(eventbyid);
    };
    getAllEvents();
  }, []);
  return (
    <>
        <Box px="8vw" py="4rem" w="60%">
          <Typography
            variant="h2"
            sx={{
              fontSize: '4vh',
              fontFamily: 'revert',
              paddingBottom: '1rem',
              textAlign: 'center',
            }}
          >
            {eventbyid.title}
          </Typography>
          <Divider
            sx={{
              marginBottom: '1rem',
            }}
          />
          {eventbyid.date}         
            <Typography
              sx={{
                fontSize: '2vh',
                fontFamily: 'revert',
                fontWeight: '400',
                textAlign: 'justify',
                marginBottom: '2rem',
                marginTop:'2rem'
              }}
            >
              {eventbyid.description}

            </Typography>
          <Divider
            sx={{
              marginTop: '4rem',
              marginBottom: '8rem',
            }}
          />
        </Box>
    </>
  );
};
export { EventsDetails };
