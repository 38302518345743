import { Box, Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useParams } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import { useState, useEffect } from 'react';
import { sendPOSTRequest } from '../../adaptors/bff-requests';
const useStyles = makeStyles({
  main: {
    marginTop: '4rem',
  },
  imgs: {
    width: '100%',
    height: '100%',
  },
});
const NewsDetails = () => {
  const classes = useStyles();
  const params = useParams();
  console.log(params.place);

  const [newsbyid, setNewbyid] = useState({});
  useEffect(() => {
    const getAllNews = async () => {
      const newsPayload = {
        limit: 2,
      };
      const newsResopnse = await sendPOSTRequest({
        path: 'latest',
        payload: newsPayload,
        queryParams: '/news-event',
      });
      console.log('News Resoponse');
      console.log(newsResopnse);
      const arrayOngoing = newsResopnse.data.news.ongoing;
      const arrayUpcoming = newsResopnse.data.news.upcoming;
      console.log(arrayOngoing);
      const allList = arrayOngoing.concat(arrayUpcoming);
      setNewbyid(allList.find((news) => news.id == params.place));
      console.log('The news by id is');
      console.log(newsbyid);
    };
    getAllNews();
  }, []);

  return (
    <>
      <Box px="8vw" py="4rem" w="60%">
        <Typography
          variant="h2"
          sx={{
            fontSize: '4vh',
            fontFamily: 'revert',
            paddingBottom: '1rem',
            textAlign: 'center',
          }}
        >
          {newsbyid.title}
        </Typography>
        <Divider
          sx={{
            marginBottom: '1rem',
          }}
        />
        {newsbyid.date}
        <Typography
          variant="h5"
          sx={{
            fontSize: '4vh',
            fontFamily: 'revert',
            paddingTop: '2rem',
            textAlign: 'justify',
          }}
        >
          {newsbyid.tile}
        </Typography>
        <Typography
          sx={{
            fontSize: '2vh',
            fontFamily: 'revert',
            fontWeight: '400',
            textAlign: 'justify',
            marginBottom: '2rem',
            marginTop: '2rem',
          }}
        >
          {newsbyid.description}
        </Typography>
        <Divider
          sx={{
            marginTop: '4rem',
            marginBottom: '8rem',
          }}
        />
      </Box>
    </>
  );
};
export { NewsDetails };
