import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const BioDiversity = () => {
  return (
    <Box px="8vw" py="4rem">
      <Typography
        variant="h2"
        sx={{
          fontSize: '4vh',
          fontFamily: 'revert',
          paddingBottom: '2rem',
          textAlign: 'center',
        }}
      >
        Biodiversity
      </Typography>
      <Grid container spacing={12} pt="4em">
        <Grid item xs={12} sm={12} md={6} my="auto">
          <img
            width="100%"
            alt={'biodiversity'}
            src="https://i.pinimg.com/564x/80/a0/83/80a0833739bbda0a1223fc634c4352d6.jpg"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Typography
            sx={{
              fontSize: '2vh',
              fontFamily: 'revert',
              fontWeight: '400',
              textAlign: 'justify',
            }}
          >
            Ambuluwa occupies a special place in the geography of Sri Lanka. Being the only isolated
            mountain in the central mountains, all the climatic characteristics of Sri Lanka as well
            as plant community, bird species and quadruped animals belonging to all climates can be
            found here. Also, 14 types of soil have been identified in the research regarding the
            soil and stones done by the students of Peradeniya University. Apart from this, there
            are extremely rare types of medicines of Ayurveda in this natural forest. Especially
            Iraraja, Sadaraja etc. and medicine bear witness to it. The largest Ancatussa (A kind of
            lizard) found in Sri Lanka was found in Ambuluawa and large colorful butterflies can be
            seen here from time to time. There are plants that are resistant to all climatic
            conditions in Sri Lanka. Among them, Iraraja, Sandaraja, Naya Maroo Ala, etc. are unique
            and there are very few rare plant species. According to the animal composition,
            dominated by Ankatsusa, lizards, falcon, and large geckos can be seen.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export { BioDiversity };
