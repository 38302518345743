import { RefreshOutlined } from '@mui/icons-material';
import { Button, IconButton, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import ListTableData from '../../../shared/table';
import NumbersIcon from '@mui/icons-material/Numbers';

export default function ListGuides(props) {
  const setShowList = props.setShowList;
  const data = props.tourGuideList?.map(
    ({ id, usercode, firstname, lastname, email, phonenumber, identification }) => ({
      id,
      usercode,
      firstname,
      lastname,
      email,
      phonenumber,
      identification,
    })
  );
  const [name, setName] = useState('');
  const [userID, setUserID] = useState('');

  return (
    <div style={{ width: '100%', height: '90%' }}>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <div>
            <Button
              variant="outlined"
              onClick={() => {
                props?.onStateChange();
                setShowList({ state: true, isUpdate: false });
              }}
            >
              Registered Tour Guides
            </Button>
          </div>
        </div>
        <div
          style={{
            border: '1px solid',
            borderRadius: '20px',
            marginTop: '10px',
            borderBottomLeftRadius: '0px',
            borderBottomRightRadius: '0px',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '20px',
              justifyContent: 'space-between',
              alignItems: 'baseline',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <NumbersIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  value={userID}
                  onChange={(e) => {
                    setUserID(e.target.value);
                  }}
                  label="Search By User Id"
                  variant="standard"
                />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', marginX: 5 }}>
                <PersonIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  label="Search By Name"
                  variant="standard"
                />
              </Box>
            </div>
            <IconButton
              color="primary"
              aria-label="refresh"
              onClick={() => {
                props.listTourGuides();
              }}
            >
              <RefreshOutlined />
            </IconButton>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            border: '1px solid',
            borderRadius: '20px',
            borderTop: 'none',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
          }}
        >
          <ListTableData
            name={name}
            userID={userID}
            data={data}
            isFetchingList={props.isFetchingList}
            onOptionSelected={props.onOptionSelected}
            allowedActions={props.allowedActions}
          />
        </div>
      </div>
    </div>
  );
}
