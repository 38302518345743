import { Button, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import UpdatePassword from './update-password';
import UsernameChip from './username-chip';
import { isNumeric, IsAccountNum } from '../../shared/functions';
import { userRoles } from '../../../../utils/rolesAndPermissions';

import { IsPhoneNumber, isValideEmail, isValidPassword } from '../../../../utils/functions';

export default function UserDetails(props) {
  const [user, setUser] = useState(props.user);
  const [passwordFields, setPasswordFields] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [errorFields, setErrorFields] = useState([]);

  const onPasswordFieldsChange = (key, value) => {
    setPasswordFields({ ...passwordFields, [key]: value });
  };

  const onChangePasswordClick = async () => {
    const emptyFields = Object.entries(passwordFields).filter(([_, value]) => !value);
    if (emptyFields.length > 0) {
      props.showAlertBox('Please Fill All Password Fields', 'warning');
      return;
    }

    if (passwordFields.newPassword !== passwordFields.confirmPassword) {
      props.showAlertBox("Password And Confirm Password Don't Match", 'warning');
      return;
    }

    if (!isValidPassword(passwordFields.newPassword)) {
      props.showAlertBox('Password Does Not Meet Requirements', 'warning');
      return;
    }
    const isSuccess = await props.onChangePassword(
      passwordFields.oldPassword,
      passwordFields.newPassword
    );
    if (isSuccess) {
      setPasswordFields({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
      });
    }
  };

  const isErrorField = (field) => {
    const index = errorFields.findIndex((x) => x === field);
    return index > -1;
  };

  const onUpdateClickHandler = async () => {
    if (!validateFields()) {
      return;
    }
    await props.onUpdate(user);
  };

  const validateFields = () => {
    let emptyFields = [];
    Object.entries(user).map(([key, value]) => {
      if (!value) {
        emptyFields.push(key);
      }
      return null;
    });

    setErrorFields(emptyFields);

    if (emptyFields.length > 0) {
      props.showAlertBox('All Fields Are Required', 'warning');
      return;
    }

    if (!isValideEmail(user.email)) {
      props.showAlertBox('Invalid Email Address', 'warning');
      setErrorFields([...['email']]);
      return;
    }

    if (!IsPhoneNumber(user.phoneNumber)) {
      props.showAlertBox('Invalid phone Number', 'warning');
      setErrorFields([...['phoneNumber']]);
      return;
    }

    return true;
  };

  useEffect(() => {
    setUser({ ...props.user });
  }, [props.user]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div>
        <h3 className="heading">User Profile</h3>
      </div>
      <div style={{ display: 'flex', marginTop: '-20px' }}>
        <div className="rootUser">
          <div style={{ marginTop: '16px', marginBottom: '12px' }}>
            <UsernameChip username={user.username} position={user.position} />

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
              <div className="userDetilasTxt">
                <p className="userHeading">First Name</p>
                <TextField
                  hiddenLabel
                  variant="filled"
                  size="small"
                  value={user.firstName}
                  error={isErrorField('firstName')}
                  onChange={(e) => {
                    setUser({ ...user, firstName: e.target.value });
                  }}
                />
              </div>
              <div className="userDetilasTxt">
                <p className="userHeading">Last Name</p>
                <TextField
                  className="userData"
                  hiddenLabel
                  variant="filled"
                  size="small"
                  value={user.lastName}
                  error={isErrorField('lastName')}
                  onChange={(e) => {
                    setUser({ ...user, lastName: e.target.value });
                  }}
                />
              </div>
              <div className="userDetilasTxt">
                <p className="userHeading">Email Address</p>
                <TextField
                  hiddenLabel
                  variant="filled"
                  size="small"
                  value={user.email}
                  error={isErrorField('email')}
                  onChange={(e) => {
                    setUser({ ...user, email: e.target.value });
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
              <div className="userDetilasTxt">
                <p className="userHeading">Phone Number</p>
                <TextField
                  hiddenLabel
                  variant="filled"
                  size="small"
                  type="tel"
                  value={user.phoneNumber}
                  error={isErrorField('phone')}
                  onChange={(e) => {
                    setUser({ ...user, phoneNumber: e.target.value });
                  }}
                />
              </div>
              <div className="userDetilasTxt">
                <p className="userHeading">Date Of Birth</p>
                <TextField
                  hiddenLabel
                  variant="filled"
                  type="date"
                  size="small"
                  value={user.dob}
                  error={isErrorField('dob')}
                  onChange={(e) => {
                    setUser({ ...user, dob: e.target.value });
                  }}
                />
              </div>
              <div className="userDetilasTxt">
                <p className="userHeading">NIC/Passport Number</p>
                <TextField
                  hiddenLabel
                  variant="filled"
                  size="small"
                  value={user.identification}
                  error={isErrorField('identification')}
                  onChange={(e) => {
                    setUser({ ...user, identification: e.target.value });
                  }}
                />
              </div>
            </div>
            {user.role !== userRoles.guide && user.role !== userRoles.user && (
              <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '2em' }}>
                <div className="userDetilasTxt">
                  <p className="userHeading">Permission Role</p>
                  <TextField
                    hiddenLabel
                    variant="filled"
                    size="small"
                    value={user.role}
                    disabled
                    onChange={() => false}
                  />
                </div>
              </div>
            )}
            {user.role === userRoles.guide && (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
                  <div className="userDetilasTxt">
                    <p className="userHeading">Name as in Bank Account</p>
                    <TextField
                      variant="filled"
                      size="small"
                      value={user.bankAccountName}
                      error={isErrorField('bankAccountName')}
                      onChange={(e) => {
                        setUser({ ...user, bankAccountName: e.target.value });
                      }}
                    />
                  </div>
                  <div className="userDetilasTxt">
                    <p className="userHeading">Bank Account Number</p>
                    <TextField
                      variant="filled"
                      size="small"
                      value={user.bankAccountNumber}
                      error={isErrorField('bankAccountNumber')}
                      onChange={(e) => {
                        const val = e.target.value;
                        setUser({ ...user, bankAccountNumber: e.target.value });
                      }}
                    />
                  </div>
                  <div className="userDetilasTxt">
                    <p className="userHeading">Bank Name</p>
                    <TextField
                      variant="filled"
                      size="small"
                      value={user.bankName}
                      error={isErrorField('bankName')}
                      onChange={(e) => {
                        setUser({ ...user, bankName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }}>
                  <div className="userDetilasTxt">
                    <p className="userHeading">Branch Name</p>
                    <TextField
                      variant="filled"
                      size="small"
                      value={user.branchName}
                      error={isErrorField('branchName')}
                      onChange={(e) => {
                        setUser({ ...user, branchName: e.target.value });
                      }}
                    />
                  </div>
                </div>
              </>
            )}
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                style={{ width: '200px', marginTop: '16px' }}
                variant="contained"
                disabled={props.isLoading}
                onClick={onUpdateClickHandler}
              >
                Update
              </Button>
            </div>

            <UpdatePassword
              passwordFields={passwordFields}
              isLoading={props.isLoading}
              onChangePasswordClick={onChangePasswordClick}
              onPasswordFieldsChange={onPasswordFieldsChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
