import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function SelectProvince(props) {
  const width = props.width;
  const province = [
    { key: 'centralprovince', value: 'Central Province' },
    { key: 'northcentralprovince', value: 'North Central Province' },
    { key: 'northernprovince', value: 'Northern Province' },
    { key: 'easternprovince', value: 'Eastern Province' },
    { key: 'northwesternprovince', value: 'North Western Province' },
    { key: 'southernprovince', value: 'Southern Province' },
    { key: 'uvaprovince', value: 'Uva Province' },
    { key: 'sabaragamuwaprovince', value: 'Sabaragamuwa Province' },
    { key: 'westernprovince', value: 'Western Province' },
  ];
  return (
    <FormControl style={{ width: width }}>
      <InputLabel id="province-simple-select-label">Province</InputLabel>
      <Select
        labelId="province-simple-select-label"
        id="province-simple-select"
        value={props.province}
        // error={!props.province}
        label="Province"
        onChange={(e) => {
          props.setProvince(e.target.value);
        }}
      >
        {province.map((e) => (
          <MenuItem value={e.key}>{e.value}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
