import { Typography, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  main: {
    marginTop: '4em',
  },
  structureImage: {
    width: '100%',
    height: '100%',
    marginTop: '2rem',
  },
});

const TowerStructure = () => {
  const classes = useStyles();

  return (
    <Container align="center" className={classes.main}>
      <Typography variant="h2" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
        Ambuluwawa Tower Structure
      </Typography>
      <img
        src="https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Home/structure-image.png"
        className={classes.structureImage}
        alt="structure"
      />
    </Container>
  );
};

export { TowerStructure };
