import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';

const useStyles = makeStyles({
  background: {
    backgroundColor: '#000000C2',
    padding: '3rem',
  },
  main: {
    display: 'flex',
    overflowX: 'scroll',
    paddingTop: '4rem',
    paddingBottom: '2rem',
  },
  cardSize: {
    width: '20rem',
    height: '25rem',
  },
  cardName: {
    fontSize: '4rem',
    color: 'white',
    position: 'absolute',
    top: 320,
    left: 16,
    textShadow: '4px 4px 5px #000000',
  },
  cardDesc: {
    fontSize: '1rem',
    color: 'white',
    position: 'absolute',
    top: 350,
    left: 16,
    textShadow: '4px 4px 5px #000000',
  },
});

const list = [
  {
    img: 'https://i.pinimg.com/564x/b7/2e/0d/b72e0dd6256b920ff3329cba116577ae.jpg',
    name: 'Temple of the Tooth',
    desc: 'Kandy - 10 miles',
  },
  {
    img: 'https://i.pinimg.com/564x/99/78/b3/9978b3295de65e24360706a8c7297d28.jpg',
    name: 'Royal Botanical Gardens',
    desc: 'Peradeniya - 13 miles',
  },
  {
    img: 'https://img.freepik.com/premium-photo/lankatilaka-vihara-is-ancient-buddhist-temple-situated-udunuwara-kandy-14th-century_352439-1133.jpg?w=2000',
    name: 'Lankatilaka Temple ',
    desc: 'Kovilakanda - 13 miles',
  },
  {
    img: 'https://t4.ftcdn.net/jpg/01/33/78/21/360_F_133782127_sM0tShzjn1dJdgoLp6yh1Inl7SlDXSVG.jpg',
    name: 'Embekke Devalaya',
    desc: 'Udunuwara - 10 miles',
  },
  {
    img: 'https://www.lakpura.com/images/LK94009958-01-E.JPG',
    name: 'Asupini Ella Falls',
    desc: 'Aranayaka - 14 miles',
  },
  {
    img: 'https://pbs.twimg.com/media/FQo5EV-VQAop5QB.jpg',
    name: 'Kadadora Viharaya',
    desc: 'Kotmale - 14 miles',
  },
  {
    img: 'https://i.pinimg.com/564x/8c/13/87/8c13878e3dc70989a2027dd3b02641ea.jpg',
    name: 'Peacock Hill',
    desc: 'Pussellawa - 16 miles',
  },
  {
    img: 'https://nexttravelsrilanka.com/wp-content/uploads/2021/03/A-white-stupa-along-with-a-stairway-with-blue-sky-as-the-background-1-749x1024.jpg',
    name: 'Kotmale Mahaweli Maha Seya',
    desc: 'Kotmale - 15 miles',
  },
];

const AttractivePlaces = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.background}>
        <Typography
          variant="h3"
          sx={{
            fontSize: '4vh',
            fontFamily: 'revert',
            color: '#FFFFFF',
            textAlign: 'center',
          }}
        >
          Places Near by Ambuluwawa
        </Typography>
        <Box className={classes.main}>
          {list.map((item) => (
            <Box pr={3}>
              <Card className={classes.cardSize}>
                <CardActionArea>
                  <CardMedia component="img" image={item.img} alt={item.name} />
                  <CardContent>
                    <Typography variant="h6" className={classes.cardName}>
                      {item.name}
                    </Typography>
                    <Typography variant="p" className={classes.cardDesc}>
                      {item.desc}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export { AttractivePlaces };
