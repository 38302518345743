import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context';
import { useAlertStore } from '../../stores';

import { Key, Person } from '@mui/icons-material';

function Copyright(props) {
  return (
    <Typography variant="h6" color="text.secondary" align="center" {...props} fontSize={'1rem'}>
      {'Copyright © '}
      <Link color="inherit" href="https://ambuluwawa.com/" style={{ textDecoration: 'none' }}>
        ambuluwawa.com
      </Link>
      {'  '}
      {new Date().getFullYear()}
    </Typography>
  );
}
const theme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const fnShowAlert = useAlertStore((state) => state.showAlert);

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      const user = await Auth.signIn(username, password);
      signIn(user);
      navigate('/admin');
    } catch (error) {
      fnShowAlert({
        message: error.message || 'Unable to Login With The Provided Credentials',
        severity: 'error',
      });
      setIsLoading(false);
      console.log('error signing in', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'left',
            }}
          >
            <Typography
              component="h1"
              variant="h5"
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 700,
                fontSize: '54px',
                lineHeight: '68px',

                color: '#252525',
              }}
            >
              Admin Portal
            </Typography>
            <Typography
              component="h2"
              variant="h5"
              style={{
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '54px',
                lineHeight: '68px',

                color: '#252525',
              }}
            >
              Login
            </Typography>

            <Box
              style={{
                fontFamily: 'Poppins',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '24px',
                margin: 5,
                color: '#404040',

                opacity: 0.99,
              }}
            >
              <Typography>Please input your information in the fields below</Typography>
              <Typography>to enter your Ambuluwawa platform.</Typography>
            </Box>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 2 }}>
              <TextField
                margin="normal"
                color="success"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person />
                    </InputAdornment>
                  ),
                }}
                id="username"
                label="Username"
                name="username"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                autoFocus
              />
              <TextField
                margin="normal"
                color="success"
                required
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Key />
                    </InputAdornment>
                  ),
                }}
                name="password"
                label="Password"
                type="password"
                id="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControlLabel
                    control={<Checkbox value="remember" color="success" />}
                    label="Remember me"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Link
                    href="/reset-password"
                    variant="h2"
                    style={{
                      fontFamily: 'Poppins',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '16px',
                      textDecoration: 'none',
                      /* or 138% */

                      letterSpacing: '0.5px',

                      color: '#54A92C',
                    }}
                  >
                    <Typography marginTop={1}> Forgot password?</Typography>
                  </Link>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}></Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    fullWidth
                    sx={{ mt: 3, mb: 2 }}
                    style={{
                      height: '58px',

                      background: '#333333',
                    }}
                  >
                    <Typography
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        fontSize: '20px',
                        lineHeight: '152.52%',
                        /* or 31px */

                        display: 'flex',
                        alignItems: 'center',
                        textAlign: 'center',

                        color: '#FFFFFF',
                      }}
                    >
                      Login
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Grid>
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: `url(https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Home/SignIn.jpg)`,
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
      </Grid>
    </ThemeProvider>
  );
}
