import React, { useCallback, useEffect, useState } from 'react';
import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useAuth } from '../../../context';
import { moduleIds } from '../../../utils/rolesAndPermissions';
import ToggleStatusModal from '../users/components/toggle-status-modal';
import FeedbacksTable from './components/feedbacks-table';
import { useAlertStore } from '../../../stores';
import AddEditFeedback from './components/feedback-add-edit';

const defaultFeedback = {
  id: '',
  name: '',
  phoneNumber: '',
  message: '',
};

export default function Feedbacks() {
  const { token, permissionsForAllowedModules } = useAuth();

  const allowedActions =
    (permissionsForAllowedModules && permissionsForAllowedModules[moduleIds.feedbacks]) || {};
  const fnShowAlert = useAlertStore((state) => state.showAlert);

  const [feedbacksList, setFeedbacksList] = useState();
  const [isFetchingList, setIsFetchingList] = useState(true);
  const [isDeleteModalOpen, toggleDelete] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState(defaultFeedback);
  const [isDeleteLoading, toggleDeleteLoading] = useState(false);
  const [deleteDetails, setDeleteMessage] = useState({
    title: 'Feedback',
    message: '',
    callBackData: undefined,
  });

  const [open, setOpen] = useState(false);

  const showHideDeleteDialog = async (show = false, data) => {
    toggleDelete(show);
    const message = show ? `Do You Wish To Delete The Feedback #${data.id} ?` : '';
    const callBackData = show ? data.id : undefined;
    setDeleteMessage((prev) => {
      return { ...prev, message, callBackData };
    });
  };

  const listFeedbacks = useCallback(async () => {
    setIsFetchingList(true);
    const resp = await sendPOSTRequest({ path: 'get', payload: {}, queryParams: '/feedback' }, token);
    if (resp.status === 'success') {
      setFeedbacksList(resp.data);
      setIsFetchingList(false);
    } else {
      setIsFetchingList(false);
      alert('Failed to fetch feedbacks');
    }
  }, [token]);

  const toggleAddEditDialog = (show = false) => {
    if (!show) {
      setSelectedFeedback(defaultFeedback);
    }
    setOpen(show);
  };

  const showAlertBox = (message, type = 'success') => {
    fnShowAlert({
      message: message,
      severity: type,
    });
  };

  const editButtonClickHandler = (feedback) => {
    const { name, phoneNumber, message, ...other } = feedback;

    setSelectedFeedback((prev) => {
      return {
        ...prev,
        name: name,
        message: message,
        phoneNumber: phoneNumber,
        ...other,
      };
    });
    toggleAddEditDialog(true);
  };

  const deleteConfirmHandler = async (selectedFeedbackId) => {
    toggleDeleteLoading(true);
    const deleteResponse = await sendPOSTRequest(
      {
        path: 'delete',
        payload: {
          feedbackId: selectedFeedbackId,
        },
        queryParams: '/feedback',
      },
      token
    );
    if (deleteResponse.status === 'success') {
      showHideDeleteDialog(false);
      await listFeedbacks();
      showAlertBox('Successfully Deleted Ticket');
    } else {
      showAlertBox('Error Deleting Ticket, please try again', 'error');
    }
    toggleDeleteLoading(false);
  };

  const saveClickHandler = async (user) => {
    setIsFetchingList(true);
    const url = `update`;
    const response = await sendPOSTRequest({ path: url, payload: user, queryParams: '/feedback' }, token);
    if (response.status === 'success') {
      showAlertBox(`Feedback is successfully updated`);
      toggleAddEditDialog();
      setIsFetchingList(false);
      await listFeedbacks();
      return true;
    } else {
      showAlertBox(
        response.status === 'warning' ? response.message : `Error Updating feedback`,
        response.status === 'warning' ? 'warning' : 'error'
      );
      setIsFetchingList(false);
    }
  };

  useEffect(() => {
    listFeedbacks();
  }, [listFeedbacks]);

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', height: '85vh' }}>
        <FeedbacksTable
          isLoading={isFetchingList}
          feedbacksList={feedbacksList}
          allowedActions={allowedActions}
          onDeleteButtonClick={(value) => showHideDeleteDialog(true, value)}
          onUpdateButtonClick={editButtonClickHandler}
        />
      </div>
      <AddEditFeedback
        open={open}
        isLoading={isFetchingList}
        selectedFeedback={selectedFeedback}
        saveClickHandler={saveClickHandler}
        closeHandler={() => toggleAddEditDialog()}
        showAlertBox={showAlertBox}
      />
      {allowedActions['delete-feedbacks'] && (
        <ToggleStatusModal
          open={isDeleteModalOpen}
          isLoading={isFetchingList}
          details={deleteDetails}
          confirmHandler={deleteConfirmHandler}
          closeHandler={() => showHideDeleteDialog(false)}
        />
      )}
    </div>
  );
}
