import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useMemo, useState } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
// import ReservationOption from './ReservationOption'
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles({
  bookingWidgetContainer: {
    position: 'absolute',
    top: '85vh',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  bookingWidget: {
    backgroundColor: 'white',
    borderRadius: 25,
    boxShadow: '0px 0px 100px 0px rgba(0,0,0,0.15)',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    '@media (max-width:800px)': {
      marginLeft: '2rem',
      marginRight: '2rem',
      alignItems: 'center',
    },
  },
  contentContainer: {
    borderRadius: 25,
    borderWidth: 1,
    borderColor: '#D7D7D7',
    display: 'flex',
    borderStyle: 'solid',
    margin: 20,
    flexDirection: 'column',
    paddingRight: '1rem',
    paddingLeft: '1rem',
    paddingTop: '1rem',
    height: '6rem',
  },
  nationalitySelectContainer: {
    marginRight: '5rem',
    marginBottom: '1rem',
  },
  datePicker: {
    marginTop: '5px',
    border: 'none',
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '1rem',
  },
  hideContentLg: {
    '@media (max-width:1000px)': {
      display: 'none',
    },
  },
  hideContentMd: {
    '@media (max-width:800px)': {
      display: 'none',
    },
  },
});
const nationalityOptions = [
  { value: 'local', label: 'Local' },
  { value: 'tourist', label: 'Foreign' },
];
const customStyles = {
  control: (base) => ({
    ...base,
    // This line disable the blue border
    boxShadow: 'none',
    borderWidth: 0,
    padding: 0,
    width: '10rem',
    fontWeight: 700,
  }),
  valueContainer: (base) => ({
    ...base,
    padding: 0,
  }),
};

const BookingWidget = () => {
  const [date, setDate] = useState(new Date());
  
  const classes = useStyles();
  const navigate = useNavigate();
  const numberOfPeoples = useMemo(() => {
    const options = [];
    for (let i = 0; i < 100; i++) {
      options.push({
        value: i+1,
        label: `${i + 1} ${i === 0 ? 'Person' : 'People'}`,
      });
    }
    return options;
  }, []);
  const [people, setPeople] = useState(numberOfPeoples[0]);
  const [nationality, setNationality] = useState(nationalityOptions[0]);
  const handleBook = () => {
    navigate('/reservationType', {
      state: {
        bookingDate: date,
        people: people.value,
        nationality: nationality.value,
      },
    });
  };
  return (
    <div className={classes.bookingWidgetContainer}>
      <Grid className={classes.bookingWidget}>
        <div className={`${classes.contentContainer} ${classes.hideContentMd}`}>
          <Typography variant="body1" fontWeight={400} zIndex={10}>
            Nationality
          </Typography>
          <div className={classes.nationalitySelectContainer}>
            <Select
              options={nationalityOptions}
              defaultValue={nationalityOptions[0]}
              value={nationality}
              onChange={(changeNationality) => setNationality(changeNationality)}
              styles={customStyles}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: () => null,
              }}
            />
          </div>
        </div>
        <div item className={`${classes.contentContainer} ${classes.hideContentLg}`}>
          <Typography variant="body1" fontWeight={400} zIndex={10}>
            No of Adults
          </Typography>
          <Select
            options={numberOfPeoples}
            defaultValue={numberOfPeoples[0]}
            value={people}
            onChange={(numPeople) => setPeople(numPeople)}
            styles={customStyles}
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
            }}
          />
        </div>
        <Grid container style={{ justifyContent: 'center' }}>
          <div item className={classes.contentContainer}>
            <Typography variant="body1" fontWeight={400} zIndex={10}>
              Booking Date
            </Typography>
            <DatePicker
              selected={date}
              onChange={(date) => setDate(date)}
              className={classes.datePicker}
              minDate={new Date()}
            />
          </div>
          <div item className={classes.buttonContainer} style={{ marginBottom: '1rem' }}>
            <Button
              variant="contained"
              size="large"
              onClick={handleBook}
              style={{                
                backgroundColor: '#252525',
                width: '150px',
                height: '60px',
                borderRadius: 20,
                fontSize: '1.2rem',
                fontWeight: 700,
                boxShadow: '0px 4px 35px 9px rgba(0, 0, 0, 0.15)',
              }}
            >
              Book
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export { BookingWidget };
