import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

import { CustomizedAxisTick, tickFormatter } from './custom-shared';

const defaultOptions = {
  responsive: {
    width: '100%',
    height: '100%',
  },
  barChart: {
    width: 500,
    height: 500,
    margin: {
      top: 5,
      right: 8,
      left: 8,
      bottom: 5,
    },
  },
};

export default function CustomBarChart({
  data,
  xaxisKey,
  bars,
  dimensionProps = defaultOptions.barChart,
  responsive = defaultOptions.responsive,
  customToolTip,
  customLegend,
  legendPosition = 'top',
  showVerticalTick = false,
}) {
  return (
    <ResponsiveContainer width={responsive.width} height={responsive.height}>
      <BarChart
        width={dimensionProps.width}
        height={dimensionProps.height}
        margin={dimensionProps.margin}
        data={data}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey={xaxisKey}
          tick={showVerticalTick ? <CustomizedAxisTick /> : true}
          height={showVerticalTick ? 80 : undefined}
        />
        <YAxis />
        {customToolTip ? <Tooltip content={(options) => customToolTip(options)} /> : <Tooltip />}
        {customLegend ? (
          <Legend verticalAlign={legendPosition} content={customLegend} />
        ) : (
          <Legend verticalAlign={legendPosition} content />
        )}
        {(bars || []).map((bar) => {
          return (
            <Bar
              key={`key-${bar.key}`}
              dataKey={bar.key}
              fill={bar.color || '#8884d8'}
              label={bar.label ? bar.label : { position: 'top' }}
            ></Bar>
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}
