import React, { useState } from 'react';
import { Button, TextField} from '@mui/material';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import './donation.css';
import { PageWrapper } from '../Shared';
import { sendPOSTRequest } from '../../adaptors/bff-requests';
import { useAuth } from '../../context';



const Donation = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
    const [amount, setAmount] = useState('');
    const [description, setDescription] = useState('');
    const [remark, setRemark] = useState('');
    const [preferredCurrency, setPreferredCurrency] = useState('LKR');
    const { token } = useAuth();

    const newDonation = async(e) => { 
        const data ={
            "firstName": firstName,
            "lastName": lastName,
            "phoneNumber":"+"+phoneNumber,
            "email": email,
            "remark": remark,
            "amount":amount ,
            "description": description ,
             "isReceived": true
        }
        console.log(data)
        const donationResponse = await sendPOSTRequest({ path: 'new', payload:data,queryParams:'/donation'},token,'public').then((res)=>{
            console.log(res);
    
            const confirmationId = res.data.id;
            
            const paymentPayload = {
              "amount": amount,
              "currency": preferredCurrency,
              "returnUrl": process.env.REACT_APP_RETURN_URL,
              "paymentType": "DONATION",
              "comment": "test",
              "confirmationId": confirmationId
            }
            localStorage.setItem('payload', JSON.stringify(data));
            localStorage.setItem('paymentPayload', JSON.stringify(paymentPayload));
            if (confirmationId) {
              const paymentResponse = sendPOSTRequest({ path: 'init', payload: paymentPayload,queryParams:"/payments" }).then((paymentRes)=>{
        
                window.location.href = paymentRes.data.responseData.paymentPageUrl;
              });
            }
    
            //setApiResponse(response);
    
          });

    };
    
    const handleFirstNameChange = (e) => {
        const regex = /^[a-zA-Z]*$/;
        const input = e.target.value.replace(/[^a-zA-Z]/g, '');
        if (regex.test(input)) {
        setFirstName(input);}
    };
    const handleLastNameChange = (e) => {
        const regex = /^[a-zA-Z]*$/;
        const input = e.target.value.replace(/[^a-zA-Z]/g, '');
        if (regex.test(input)) {
        setLastName(input);}
    };

    const handleEmailChange = (e) => {
        const emailRegex = /\S+@\S+\.\S+/;
        const value = e.target.value;
        setEmail(value);
        setEmailError(!emailRegex.test(value));
    };

    const handlePhoneNumberChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleDateChange = (e) => {
        setDate(e.target.value);
    };
    const handleAmountChange = (e) => {
        let input = e.target.value;
        input = input.replace(/\D/g, '');
        setAmount(input);
    };
    const handleCurrencyChange = (e) => {
        setPreferredCurrency(e.target.value);
    };

    const handleDescriptionChange = (e) => {
        setDescription(e.target.value);
        
    };
    const handleRemarkChange = (e) => {
        setRemark(e.target.value);
    };

    return (
        <PageWrapper
            props={{
                hideFooter: true,
            }}>
            <div style={{ marginTop: '80px' }}>
                    <form className="Donationform" >
                        <div className='VisiterDetails'>
                            <h3 className="headingdetails">Visitor Details</h3>
                        </div>
                        <div style={window.innerWidth > 768 ? { display: 'flex', justifyContent: 'space-between' } : {}}>
                            <div className="userDetilasTxt">
                                <p className="userHeading" class="required" >First Name</p>
                                <TextField
                                    hiddenLabel
                                    variant="filled"
                                    size="small"
                                    value={firstName}
                                    // error={isErrorField('firstName')}
                                    onChange={handleFirstNameChange}
                                />
                            </div>
                            <div className="userDetilasTxt">
                                <p className="userHeading" class="required">Last Name</p>
                                <TextField
                                    className="userData"
                                    hiddenLabel
                                    variant="filled"
                                    size="small"
                                    value={lastName}
                                    onChange={handleLastNameChange}
                                />
                            </div>
                            <div className="userDetilasTxt">
                                <p className="userHeading" class="required">Email Address</p>
                                <TextField
                                    hiddenLabel
                                    variant="filled"
                                    size="small"
                                    value={email}
                                    onChange={handleEmailChange}
                                    error={emailError}
                                    helperText={emailError ? 'Please enter a valid email address' : ''}
                                />
                            </div>
                        </div>
                        <div style={window.innerWidth > 768 ?{ display: 'flex', justifyContent: 'space-between', marginTop: '2em' }:{}}>
                            <div className="userDetilasTxt">
                                <p className="userHeading" class="required">Phone Number</p>
                                    <PhoneInput
                                     inputStyle={window.innerWidth > 768 ?{
                                        background:'#f0f0f0',
                                        borderColor: 'white',
                                        width :'29.28vw',
                                        height :'35px'
                                      }:
                                    {
                                        background:'#f0f0f0',
                                        borderColor: 'white', 
                                    }
                                    }
                                    containerClass="phone-input-container"
                                    inputClass="phone-input"
                                    country={"eg"}
                                    enableSearch={true}
                                    value={phoneNumber}
                                    onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
                                    />
                           </div>
                            <div className="userDetilasTxt">
                                <p className="userHeading" class="required">Date</p>
                                <TextField
                                    hiddenLabel
                                    variant="filled"
                                    type="date"
                                    size="small"
                                    value={date}
                                    disabled
                                    onChange={handleDateChange}
                                />
                            </div>
                            <div className="userDetilasTxt">
                                <p className="userHeading" class="required">Remark</p>
                                <TextField
                                    hiddenLabel
                                    variant="filled"
                                    type="text"
                                    size="small"
                                    value={remark}
                                    placeholder="Share your experience about the place"
                                    onChange={handleRemarkChange}
                                />
                            </div>
                        </div>
                        <div>
                            <div>
                                <h3 className="headingDonation">Donation Details</h3>
                            </div>
                        </div>
                        <div style={window.innerWidth > 768 ?{ display: 'flex', justifyContent: 'space-between' }:{}}>
                            <div className="userDetilasTxt">
                                <p className="userHeading" class="required">Donation Amount</p>
                                <TextField
                                    hiddenLabel
                                    variant="filled"
                                    size="small"
                                    type="number"
                                    value={amount}
                                    onChange={handleAmountChange}
                                />
                            </div>
                            <div className="userDetailsTxt">
                                <p className="userHeading" class="required">Currency</p>
                                <TextField 
                                    hiddenLabel
                                    variant="filled"
                                    size="small"
                                    disabled
                                    value={preferredCurrency} />
                            </div>
                            <div className="userDetilasTxt">
                                <p className="userHeading" class="required">Donation Reason</p>
                                <TextField
                                    hiddenLabel
                                    variant="filled"
                                    type="text"
                                    size="small"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                            </div>
                        </div>
                        <div className='submitButton'>
                            <Button
                                style={{ width: '200px', marginTop: '16px' }}
                                variant="contained"
                                onClick={newDonation}
                            >
                                Donate
                            </Button>
                        </div>
                    </form>
            </div>
        </PageWrapper>
    );

}

export { Donation };