import React, { useEffect, useState } from 'react';
import { useNavigate} from 'react-router-dom';
const PhotoShootResSuccess=({paymentMethod,apiResponse})=> {
  const[cashMessage, setCashMessage] = useState('Reservation Successful');
  const[cardMessage, setCardMessage] = useState('Payment Successful');
  useEffect(()=>{
    if (apiResponse.status==='success') {
      if (paymentMethod==='cash') {
        setCashMessage('Reservation Successful')
      }else{
        setCardMessage('Payment Successful')
      }
    }else{
      if (paymentMethod==='cash') {
        setCashMessage('Please try again ,there is some error')
      }else{
        setCardMessage('Please try again ,there is some error')
      }
    }
    
  },[]);
  
  const navigate = useNavigate();
  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/');
  };
  return (
    <div>
    <form className="reservation-form-middle" onSubmit={handleSubmit}>
        {paymentMethod==='cash' &&
        <h2>{cashMessage}</h2>
        }
        {paymentMethod==='card' &&
        <h2>{cardMessage}</h2>
        }
        <br />
        <input type="submit" value='Home'/>
       </form>
     </div>
     );
}

export {PhotoShootResSuccess};