import { React, useState, useEffect } from 'react';
import { Button, Input, InputLabel, TextField } from '@mui/material';
import Swal from 'sweetalert2';
import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import { useParams } from 'react-router-dom';
import { useAuth } from '../../../context';

export function UpdateNews() {
  const { token } = useAuth();
  const params = useParams();
  console.log(params.place);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [newsbyid, setNewbyid] = useState({});
  useEffect(() => {
    const getAllNews = async () => {
      const newsPayload = {
        limit: 2,
      };
      const newsResopnse = await sendPOSTRequest({
        path: 'latest',
        payload: newsPayload,
        queryParams: '/news-event',
      });
      console.log('News Resoponse');
      console.log(newsResopnse);
      const arrayOngoing = newsResopnse.data.news.ongoing;
      const arrayUpcoming = newsResopnse.data.news.upcoming;
      console.log(arrayOngoing);
      const allList = arrayOngoing.concat(arrayUpcoming);

      setNewbyid(allList.find((news) => news.id == params.place));
      const newsbyidtemp = allList.find((news) => news.id == params.place);
      fillApiResponse(newsbyidtemp);
      console.log('The news by id is');
      console.log(newsbyid);
    };
    getAllNews();
  }, []);

  const fillApiResponse = (newsbyidtemp) => {
    setFormFields((prevFields) => ({
      ...prevFields,
      ['status']: newsbyidtemp.status,
    }));
    setFormFields((prevFields) => ({
      ...prevFields,
      ['title']: newsbyidtemp.title,
    }));
    setFormFields((prevFields) => ({
      ...prevFields,
      ['date']: newsbyidtemp.date,
    }));
    setFormFields((prevFields) => ({
      ...prevFields,
      ['description']: newsbyidtemp.description,
    }));
  };

  const formStyle = {
    marginLeft: '15%',
    width: '70%',
    border: '1px solid',
    padding: '20px',
    borderRadius: '15px',
    marginTop: '5%',
  };
  const [formFields, setFormFields] = useState({
    image: '',
    status: '',
    title: '',
    date: '',
    description: '',
  });

  const handleInputChange = (event) => {
    const { name, value, files } = event.target;
    if (files && files[0]) {
      setUploadedImage(files[0]);
    }
    setFormFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  async function handleSubmit(event) {
    event.preventDefault();
    setFormFields({
      image: '',
      status: '',
      title: '',
      date: '',
      description: '',
    });
    console.log(formFields.title);

    var statusTemp = true;
    if (formFields.status == 'Upcoming') {
      statusTemp = false;
    }

    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64Image = reader.result;

      const updatePayload = {
        id: params.place,
        isNews: true,
        title: formFields.title,
        description: formFields.description,
        isOngoing: statusTemp,
        effectiveDate:formFields.date,
        removedImages: [newsbyid.images[0]],
        addedImages: [base64Image.toString(),base64Image.toString()],
      };

      const updateResponse = await sendPOSTRequest(
        {
          path: 'update',
          payload: updatePayload,
          queryParams: '/news-event',
        },
        token
      );

      console.log('The update response is:');
      console.log(updateResponse);
      if(updateResponse.status==='success'){
        Swal.fire(
          'Updated!',
          'The news has been Updated.',
          'success'
        )
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Internal Sever Error',
          text: 'The news is not updated please try again later',
      });
      }
    };

    reader.readAsDataURL(uploadedImage);
  }

  return (
    <div style={formStyle}>
      <form onSubmit={handleSubmit}>
        <div style={{ marginTop: '15px' }}>
          <InputLabel htmlFor="image">Image *</InputLabel>
          <Input
            type="file"
            id="image"
            name="image"
            accept="image/*"
            onChange={handleInputChange}
            required
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="Status"
            name="status"
            value={formFields.status}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="Title"
            name="title"
            value={formFields.title}
            onChange={handleInputChange}
            fullWidth
            required
          />
        </div>
        <div style={{ marginTop: '25px' }}>
          <TextField
            label="Date"
            name="date"
            type="date"
            value={formFields.date}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            required
          />
        </div>
        <div style={{ marginTop: '20px' }}>
          <TextField
            label="Description"
            name="description"
            value={formFields.description}
            onChange={handleInputChange}
            multiline
            rows={4}
            fullWidth
            required
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <div>
            <Button
              variant="contained"
              type="submit"
              style={{ width: '10em', padding: '8px 0', fontSize: '1em', marginTop: '20px' }}
            >
              Update
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}
