import React, { useCallback, useEffect, useState } from 'react';
import styles from './TicketComponents/style.css';
import { PersonComponenet } from './TicketComponents/person';
import { PriceComponent } from './TicketComponents/price';
import { VehicleComponenet } from './TicketComponents/vehicles';

import { sendPOSTRequest } from '../../../../adaptors/bff-requests';

import { useAuth } from '../../../../context';

export default function OnsiteTicketing(props) {
  const {
    listOfNationalities,
    visitorCostDetails,
    vehicleCostDetails,
    defaultNationality,
    existingTicketData,
    isGuide,
    afterSaveHandler,
  } = props;

  const {
    token,
    authorizedUser: { loggedInUserId },
  } = useAuth();

  //person data
  const [vistorType, setVistorType] = useState('local');
  const [nationality, setNationality] = useState(defaultNationality || 0);

  const [kidsCount, setKidsCount] = useState(0);
  const [adultsCount, setAdultsCount] = useState(0);

  const [selectedNoAdults, setSelectedNoAdults] = useState(0);
  const [selectedNoKids, setSelectedNoKids] = useState(0);
  const [selectedNationality, setSelectedNationality] = useState(212);

  const [isLoading, setIsLoading] = useState(false);

  //vehicles data
  const [noOfBikes, setNoOfBikes] = useState(0);
  const [noOfThreewheels, setNoOfThreewheels] = useState(0);
  const [noOfVans, setNoOfVans] = useState(0);
  const [noOfCars, setNoOfCars] = useState(0);
  const [noOfBuses, setNoOfBuses] = useState(0);
  //price
  const [subTotal, setSubTotal] = useState();
  const [paidAmount, setPaidAmount] = useState();
  const [balance, setBalance] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('cash');

  const [guideId, setGuideId] = useState(undefined);
  const [guideSwitch, setGuideSwitch] = useState(undefined);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [listOfGuides, setListOfGuides] = useState([]);
  const [rows, setRows] = useState([]);
  const [scannedBarcodes, setScannedBarcodes] = useState([]);


  const getCostPerVehicle = useCallback(
    (vehicle, countIs) => {
      return vehicleCostDetails
        .filter((e) => {
          return e.type === vehicle;
        })
        .map((e) => {
          return parseInt(e.cost) * parseInt(countIs);
        });
    },
    [vehicleCostDetails]
  );

  const getVehicelId = (vehicle) => {
    return vehicleCostDetails
      .filter((e) => {
        return e.type === vehicle;
      })
      .map((e) => {
        return e.id;
      });
  };

  const getPersonCost = useCallback(
    (type, countIs) => {
      return visitorCostDetails
        .filter((e) => {
          return e.type === type;
        })
        .map((e) => {
          return parseInt(e.cost) * parseInt(countIs);
        });
    },
    [visitorCostDetails]
  );

  const calculate = useCallback(() => {
    try {
      if (existingTicketData) {
        return;
      }

      let kidsTotal = 0;
      let adultTotal = 0;
      let bikesTotal = 0;
      let threewheelTotal = 0;
      let vanTotal = 0;
      let carTotal = 0;
      let busTotal = 0;

      if (selectedNationality==212) {
        if (selectedNoAdults >0) {
          adultTotal += getPersonCost(`local-adults`, selectedNoAdults)[0]
        }
        if (selectedNoKids >0) {
          kidsTotal += getPersonCost(`local-kids`, selectedNoKids)[0]
        }
      }else{
        if (selectedNoAdults >0) {
          adultTotal += getPersonCost(`tourist-adults`, selectedNoAdults)[0]
        }
        if (selectedNoKids >0) {
          kidsTotal += getPersonCost(`tourist-kids`, selectedNoKids)[0]
        }
      }

      for (let i = 0; i < rows.length; i++) {
        const element = rows[i];
        if (element.nationality==212) {
          if (element.adults >0) {
            adultTotal += getPersonCost(`local-adults`, element.adults)[0]
          }
          if (element.kids >0) {
            kidsTotal += getPersonCost(`local-kids`, element.kids)[0]
          }
        }else{
          if (element.adults >0) {
            adultTotal += getPersonCost(`tourist-adults`, element.adults)[0]
          }
          if (element.kids >0) {
            kidsTotal += getPersonCost(`tourist-kids`, element.kids)[0]
          }
        }
      }
      if (noOfBikes > 0) {
        bikesTotal = getCostPerVehicle('Bike', noOfBikes)[0];
      }
      if (noOfThreewheels > 0) {
        threewheelTotal = getCostPerVehicle('Three-Wheeler', noOfThreewheels)[0];
      }
      if (noOfVans > 0) {
        vanTotal = getCostPerVehicle('Van', noOfVans)[0];
      }
      if (noOfCars > 0) {
        carTotal = getCostPerVehicle('Car', noOfCars)[0];
      }
      if (noOfBuses > 0) {
        busTotal = getCostPerVehicle('Bus', noOfBuses)[0];
      }
      const tot =
        kidsTotal + adultTotal + bikesTotal + threewheelTotal + vanTotal + carTotal + busTotal;
      setSubTotal(tot);
    } catch (er) {}
  }, [
    getCostPerVehicle,
    getPersonCost,
    selectedNoKids,
    selectedNoAdults,
    selectedNationality,
    noOfBikes,
    noOfBuses,
    noOfCars,
    noOfThreewheels,
    noOfVans,
    rows
  ]);

  const calculateBalance = (e) => {
    const bal = parseInt(e) - parseInt(subTotal);
    setPaidAmount(e);
    setBalance(e ? bal : 0);
  };

  const onPrint = async () => {
    if (guideSwitch && !guideId) {
      props.showAlert('Please select guide, else disable selection', 'warning');
      return;
    }

    setIsLoading(true);
    const vehicleArray = [];

    if (noOfBikes > 0) {
      const id = getVehicelId('Bike')[0];
      vehicleArray.push({ vehicleId: id, count: noOfBikes });
    }
    if (noOfThreewheels > 0) {
      const id = getVehicelId('Three-Wheeler')[0];
      vehicleArray.push({ vehicleId: id, count: noOfThreewheels });
    }
    if (noOfVans > 0) {
      const id = getVehicelId('Van')[0];
      vehicleArray.push({ vehicleId: id, count: noOfVans });
    }
    if (noOfCars > 0) {
      const id = getVehicelId('Car')[0];
      vehicleArray.push({ vehicleId: id, count: noOfCars });
    }
    if (noOfBuses > 0) {
      const id = getVehicelId('Bus')[0];
      vehicleArray.push({ vehicleId: id, count: noOfBuses });
    }

    if (existingTicketData && existingTicketData.id > 0) {
      // edit/confirm reservation
      const payload = {
        ticketId: existingTicketData.id,
        paymentType: 'cash',
        paidAmount: paidAmount || subTotal,
        balance: balance || 0,
      };
      const response = await sendPOSTRequest(
        { path: 'ticket/confirm-reservation', payload: payload },
        token
      );
      setIsLoading(false);
      afterSaveHandler(response);
    } else {
      //new onsite ticket
      const visitors= [];

      rows.forEach(i => {
          let visitor= {};
      
          if (i.nationality == '212') {
              visitor = {
                  'local-kids': String(i.kids),
                  'local-adults': String(i.adults),
                  nationalityId: String(i.nationality),
              };
          } else {
              visitor = {
                  'tourist-kids': String(i.kids),
                  'tourist-adults': String(i.adults),
                  nationalityId: String(i.nationality),
              };
          }
      
          visitors.push(visitor);
      });
      if ((visitors?.length==0 && vehicleArray?.length==0)) {
          props.showAlert('Please add details', 'warning');
          setIsLoading(false);
          return;
        }
      const payload = {
        visitors: visitors,
        vehicles: vehicleArray,
        payment: { paidAmount: paidAmount || subTotal, balance: balance || 0, paymentType: 'cash' }, // update when changed to payment type card
        guideId: !isGuide ? guideId : loggedInUserId,
        isReserve: isGuide ? true : false,
        barcodes : scannedBarcodes
      };
      const response = await sendPOSTRequest(
        { path: 'ticket/new-onsite', payload: payload },
        token
      );
      setIsLoading(false);
      afterSaveHandler(response);
    }
  };

  useEffect(() => {
    calculate();
  }, [
    selectedNoKids,
    selectedNoAdults,
    selectedNationality,
    noOfBikes,
    noOfThreewheels,
    noOfCars,
    noOfVans,
    noOfBuses,
    calculate,
  ]);

  const setExistingData = (data) => {
    if (data) {
      setVistorType(data.visitorType);
      setNationality(data.nationality);
      setKidsCount(data.kidsCount);
      setAdultsCount(data.adultsCount);
      setNoOfBikes(data.noOfBikes);
      setNoOfThreewheels(data.noOfThreewheels);
      setNoOfVans(data.noOfVans);
      setNoOfCars(data.noOfCars);
      setNoOfBuses(data.noOfBuses);
      setSubTotal(data.subTotal);
      setRows(data.rows);
    }
  };

  useEffect(() => {
    setExistingData(existingTicketData);
  }, [existingTicketData]);

  const onAutoCompleteChange = async (value) => {
    if (autocompleteLoading) {
      return;
    }

    if (!value || value.length < 3) {
      setListOfGuides([]);
      return;
    }

    setAutocompleteLoading(true);
    const response = await sendPOSTRequest(
      {
        path: 'user/guide/search',
        payload: {
          keyword: value.toLowerCase().trim(),
        },
      },
      token
    );

    setListOfGuides(response ? response.data || [] : []);
    setAutocompleteLoading(false);
  };

  const onAutoCompleteSelect = (value) => {
    setGuideId(value ? value.id : value);
  };
  function getCountryNameById(id)  {
    console.log(typeof(id));
    id = parseInt(id);
    const foundObject = listOfNationalities.find(item => item.id === id);
    console.log(foundObject)
    return foundObject ? foundObject.name : null;
  }
  const handleAddRow = (selectedNoAdults, selectedNoKids, selectedNationality) => {
    if(selectedNoAdults=="0"&& selectedNoKids=="0"){
      return;
    }
    const nationalityName = getCountryNameById(selectedNationality);
    const newRow = { adults: selectedNoAdults, kids: selectedNoKids, nationality: selectedNationality, nationalityName: nationalityName };
  
    const existingRowIndex = rows.findIndex(row => row.nationality === selectedNationality);
  
    if (existingRowIndex !== -1) {
      const updatedRows = [...rows];
      updatedRows[existingRowIndex] = newRow;
      setRows(updatedRows);
    } else {
      setRows([...rows, newRow]);
    }
  };

  const handleRemoveRow = (index ) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '100px',
        height: '100%',
        width: '100%',
      }}
    >
      <div>
        <h3 className="heading">Online Ticket Booking</h3>
      </div>
      <div style={{ display: 'flex', marginTop: '-20px' }}>
        <PersonComponenet
          selectedNoAdults={selectedNoAdults}
          setSelectedNoAdults={setSelectedNoAdults}
          selectedNoKids={selectedNoKids}
          setSelectedNoKids={setSelectedNoKids}
          selectedNationality={selectedNationality}
          setSelectedNationality={setSelectedNationality}
          listOfNationalities={listOfNationalities}
          autocompleteLoading={autocompleteLoading}
          listOfGuides={listOfGuides}
          onAutoCompleteChange={onAutoCompleteChange}
          onAutoCompleteSelect={onAutoCompleteSelect}
          onGuideSwitch={setGuideSwitch}
          isGuide={isGuide}
          guideCode={existingTicketData ? existingTicketData.guideCode : ''}
          isEditMode={existingTicketData ? true : false}
          rows={rows}
          handleAddRow={handleAddRow}
          handleRemoveRow={handleRemoveRow}
        />
        <VehicleComponenet
          noOfBikes={noOfBikes}
          setNoOfBikes={setNoOfBikes}
          noOfThreewheels={noOfThreewheels}
          setNoOfThreewheels={setNoOfThreewheels}
          noOfVans={noOfVans}
          setNoOfVans={setNoOfVans}
          noOfCars={noOfCars}
          setNoOfCars={setNoOfCars}
          noOfBuses={noOfBuses}
          setNoOfBuses={setNoOfBuses}
          isEditMode={existingTicketData ? true : false}
        />
      </div>
      <div style={{ display: 'flex' }}>
        <PriceComponent
          subTotal={subTotal}
          paidAmount={paidAmount}
          setPaidAmount={(e) => {
            calculateBalance(e);
          }}
          balance={balance}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          scannedBarcodes={scannedBarcodes}
          setScannedBarcodes={setScannedBarcodes}
          onPrint={onPrint}
          isLoading={isLoading}
          isGuide={isGuide}
          isEditMode={existingTicketData ? true : false}
          isPaymentPending={
            (existingTicketData && (
              (existingTicketData.ticketType ==='onsite' && existingTicketData.paymentStatus === 'pending') || 
              (existingTicketData.ticketType ==='online-paid' && existingTicketData.ticketStatus === 'ready-to-print')
            )) ||
            !existingTicketData
          }
        />
      </div>
    </div>
  );
}
