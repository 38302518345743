import React, { useState } from 'react';
import './Ticketing.css';
import PrintComponent from '../Admin/analytics/shared/printGenerator';
import { Button, TextField, Paper, Typography } from '@mui/material';
import './DetailsTicketingIP.css'
import { useReactToPrint } from 'react-to-print';
import { useAuth } from '../../context';
import { useAlertStore } from '../../stores';
import { sendPOSTRequest } from '../../adaptors/bff-requests';
const DetailsTicketing = ({ changeShow, ticket}) => {
  const [user, setUser] = useState({});
  const [cashReceived, setcashReceived] = useState();
  const [cashPayment, setCashPayment] = useState(true);
  const [cardPayment, setCardPayment] = useState(false);
  const { ticketDetails, vehicleDetails, visitorDetails } = ticket;
  const [printButtonText, setPrintButtonText] = useState("Pay");
  const [isPaymentInProcess, setIsPaymentInProcess] = useState(false);
  const {
    token,
    authorizedUser: { loggedInUserId },
  } = useAuth();
  const fnShowAlert = useAlertStore((state) => state.showAlert);
  const showAlertBox = (message, type = 'success') => {
    fnShowAlert({
      message: message,
      severity: type,
    });
  };

  const handlecashReceivedChange = (e) => {
    let input = e.target.value;
    input = input.replace(/\D/g, '');
    setcashReceived(input);

  };

  const handleCashPaymentChange = (event) => {
    setCashPayment(event.target.checked);
    setCardPayment(!event.target.checked);
  };

  const handleCardPaymentChange = (event) => {
    setCardPayment(event.target.checked);
    setCashPayment(!event.target.checked);
    setcashReceived('');
  };
  const handlePrint = useReactToPrint({
    content: () =>  document.getElementById("printTicketIP")
  });

  const onClickPrintOrPayment =async ()=>{

    if (printButtonText=="Pay") {
      setIsPaymentInProcess(true);
      const payload = {
        ticketId: ticketDetails.ticketId,
        paymentType: 'cash',
        paidAmount: ticketDetails.amount,
        balance: 0,
      };
      const response = await sendPOSTRequest(
        { path: 'ticket/confirm-reservation', payload: payload },
        token
      );

      if (response.status=='success') {
        setPrintButtonText("Print")
        showAlertBox("Payment successful")
      }else{
        showAlertBox("Payment unsuccessful ,Please try again later" , "error")
      }
      setIsPaymentInProcess(false);
      
    }else{
      handlePrint();
    }

  }

  return (
    <div>
        <div className='details-container' id='printTicketIP'>
      <div className='details-row'>
        <Paper elevation={3} className='details-paper'>
          <Typography variant='h5' gutterBottom>
            Ticket Details
          </Typography>
          <div className='details-field'>
            <Typography variant='subtitle1'>Balance:</Typography>
            <TextField
              variant='outlined'
              size='large'
              value={ticketDetails.amount}
              disabled
              fullWidth
              sx={{fontWeight:'bold'}}
            />
          </div>
          <div className='details-field'>
            <Typography variant='subtitle1'>Ticket ID:</Typography>
            <TextField
              variant='outlined'
              size='small'
              value={ticketDetails.ticketId}
              disabled
              fullWidth
            />
          </div>
          {/* Add more ticket details as needed */}
        </Paper>
      </div>

      {vehicleDetails?.length>0  && <div className='details-row'>
        <Paper elevation={3} className='details-paper'>
          <Typography variant='h5' gutterBottom>
            Vehicle Details
          </Typography>
          {vehicleDetails.map((vehicle, index) => (
            <div key={index} className='details-field'>
              <Typography variant='subtitle1'>
                {vehicle.name}:
              </Typography>
              <TextField
                variant='outlined'
                size='small'
                value={` Count: ${vehicle.count}, Total Cost: ${vehicle.totalCost}`}
                disabled
                fullWidth
              />
            </div>
          ))}
        </Paper>
      </div> }

      <div className='details-row'>
        <Paper elevation={3} className='details-paper'>
          <Typography variant='h5' gutterBottom>
            Visitor Details
          </Typography>
          {visitorDetails.map((visitor, index) => (
            <div key={index} className='details-field'>
              <Typography variant='subtitle1'>
              {visitor.visitorType}:
              </Typography>
              <TextField
                variant='outlined'
                size='small'
                value={` Count: ${visitor.count}, Total Cost: ${visitor.totalCost}`}
                disabled
                fullWidth
              />
            </div>
          ))}
        </Paper>
      </div>
      <div className='buttons-container'>
        <Button variant='contained' onClick={() => changeShow()}>
          Back
        </Button>
        <Button  variant='contained' onClick={() => onClickPrintOrPayment()} disabled = {isPaymentInProcess}>
          {printButtonText}
        </Button>

      </div>
    </div>

    </div>
  );
}
export { DetailsTicketing };