import React, { useState, useEffect, useCallback } from 'react';
import { Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import { useAlertStore } from '../../../stores';
import { useAuth } from '../../../context';
import { sendPOSTRequest } from '../../../adaptors/bff-requests';
import ConfirmModal from './ConfirmModal';

export default function EditPricing() {
  const [updateItem, setUpdateItem] = useState();
  const [data, setData] = useState();
  const { token } = useAuth();
  const fnShowAlert = useAlertStore((state) => state.showAlert);
  const [modalOpen, setModalOpen] = useState(false);

  const showAlertBox = (message, type = 'success') => {
    fnShowAlert({
      message: message,
      severity: type,
    });
  };

  const tableHeaderStyle = {
    background: '#212121',
    fontFamily: 'revert',
    padding: '13px',
    color: 'white',
    fontSize: '16px',
  };

  const tableHeaderCellStyle = {
    color: '#fff',
    fontWeight: 'bold',
    borderBottom: 'none',
  };

  const fetchContents = useCallback(async () => {
    const ticketPriceResp = await sendPOSTRequest({ path: 'get-all-prices', queryParams: '/price' }, token);
    if (ticketPriceResp.status === 'error') {
      showAlertBox('Error while fetching data', 'error');
    }
    setData(ticketPriceResp?.data);
  }, [token]);

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  const handleRateChange = (componentKey, itemId, newRate) => {
    setData((prevState) => {
      const updatedData = { ...prevState };
      updatedData[componentKey] = prevState[componentKey].map((item) => {
        if (item.id === itemId) {
          return { ...item, cost: newRate };
        }
        return item;
      });
      return updatedData;
    });
  };


  const setConfirmationMessage = async () => {
    const payload = {
      id: updateItem?.item.id,
      typeid: updateItem?.item.typeid,
      cost: updateItem?.item.cost
    }
    const UpdateTicketResp = await sendPOSTRequest({ path: updateItem.componentKey,payload:payload, queryParams: '/price' }, token);
    if(UpdateTicketResp?.status==='error')
    {
      showAlertBox('Error while updating price', 'error');
    }
    else
    {
      fetchContents();
      showAlertBox('Updated Successfully, The new rates will be reflected from midnight 12:00', 'success');
    }
    setModalOpen(false);
    
  };
  const setModalClose = () => {
    setModalOpen(false);
  }

  return (
    <>
      {modalOpen && <ConfirmModal open={modalOpen} handleClose={setModalClose} item={updateItem?.item} handleConfirmation={setConfirmationMessage} />}

      <Box sx={{ margin: '14px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <h1 style={{ fontFamily: 'revert' }}>Current Rate</h1>
        </div>

        {/* Vehicle Prices */}
        <TableContainer sx={{ marginTop: '20px' }}>
          <h2>Vehicle Prices</h2>
          <Table>
            <TableHead>
              <TableRow style={tableHeaderStyle}>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Item
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Description
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Cost (LKR)
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                 Updated Price(LKR)
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.vehiclePrices.map((vehicle) => (
                <TableRow key={vehicle.id}>
                  <TableCell align="left" padding='11px'>{vehicle.type}</TableCell>
                  <TableCell align="left" padding='11px'>{vehicle.description}</TableCell>
                 
                  <TableCell align="left">
                    <TextField
                      size="small"
                      variant="standard"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      value={vehicle.cost}
                      onChange={(e) => {
                        var value = parseInt(e.target.value, 10);
                        if (value < 1) {
                          showAlertBox("Please enter a valid input", "error");
                        } else {
                          handleRateChange('vehiclePrices', vehicle.id, e.target.value);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" padding='11px'>{vehicle?.updatedprice}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setUpdateItem({ item: vehicle, componentKey: 'update-vehicle-price' });
                        setModalOpen(true);
                      }}
                    >
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Visitor Prices */}
        <TableContainer sx={{ marginTop: '20px' }}>
          <h2>Visitor Prices</h2>
          <Table>
            <TableHead>
              <TableRow style={tableHeaderStyle}>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Type
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Description
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Cost (LKR)
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Updated Price(LKR)
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.visitorPrices.map((visitor) => (
                <TableRow key={visitor.id}>
                  <TableCell align="left" padding='11px'>{visitor.type}</TableCell>
                  <TableCell align="left" padding='11px'>{visitor.description}</TableCell>
                  <TableCell align="left">
                    <TextField
                      size="small"
                      variant="standard"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      value={visitor.cost}
                      onChange={(e) => {
                        var value = parseInt(e.target.value, 10);
                        if (value < 1) {
                          showAlertBox("Please enter a valid input", "error");
                        } else {
                          handleRateChange('visitorPrices', visitor.id, e.target.value);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" padding='11px'>{visitor?.updatedprice}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setUpdateItem({ item: visitor, componentKey: 'update-visitor-price' });
                        setModalOpen(true);
                      }}
                    >
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pre-Shoot Prices */}
        <TableContainer sx={{ marginTop: '20px' }}>
          <h2>Pre-Shoot Prices</h2>
          <Table>
            <TableHead>
              <TableRow style={tableHeaderStyle}>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Type
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Description
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Cost (LKR)
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Updated Price(LKR)
                </TableCell>
                <TableCell align="left" style={tableHeaderCellStyle}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.preShootPrices.map((preShoot) => (
                <TableRow key={preShoot.id}>
                  <TableCell align="left" padding='11px'>{preShoot.type}</TableCell>
                  <TableCell align="left" padding='11px'>{preShoot.description}</TableCell>
                  <TableCell align="left">
                    <TextField
                      size="small"
                      variant="standard"
                      type="number"
                      InputProps={{ inputProps: { min: 1 } }}
                      value={preShoot.cost}
                      onChange={(e) => {
                        var value = parseInt(e.target.value, 10);
                        if (value < 1) {
                          showAlertBox("Please enter a valid input", "error");
                        } else {
                          handleRateChange('preShootPrices', preShoot.id, e.target.value);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell align="left" padding='11px'>{preShoot?.updatedprice}</TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      onClick={() => {
                        setUpdateItem({ item: preShoot, componentKey: 'update-pre-shoot-price' });
                        setModalOpen(true);
                      }}
                    >
                      Update
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}
