const props = {
  MuiButton: {
    disableElevation: true,
    variant: 'contained',
  },
  MuiPaper: {
    elevation: 0,
  },
  MuiLink: {
    underline: 'hover',
  },
};

export default props;
