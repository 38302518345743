import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { AppBar, IconButton, Slide, Toolbar, Typography, TextField, Box } from '@mui/material';
import { SpinnerComponent } from '../../../Shared';

const useStyles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  valueParent: {
    marginTop: '16px',
    padding: '24px 0',
    display: 'flex',
    borderTop: '1px solid #dddd',
  },
  valueBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '50%',
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const currentDate = () => {
  return new Date().toISOString().split('T')[0];
};

export default function SalesInfo(props) {
  const classes = useStyles();
  const {
    open,
    isLoading,
    salesInfo: { total, visitorCount },
    closeHandler,
    applyInfoClickHandler,
  } = props;
  const [defaultDate, setDefaultDate] = useState(currentDate());

  const renderValueBlock = (firstBlock, secondBlock) => {
    return (
      <div className={classes.valueParent}>
        <div className={classes.valueBlock}>
          <Typography
            variant="p"
            sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'revert' }}
          >
            {firstBlock.label}
          </Typography>
          <Typography variant="p" sx={{ fontSize: '24px', fontFamily: 'revert' }}>
            {firstBlock.value}
          </Typography>
        </div>
        <div className={classes.valueBlock}>
          <Typography
            variant="p"
            sx={{ fontSize: '20px', fontWeight: 'bold', fontFamily: 'revert' }}
          >
            {secondBlock.label}
          </Typography>
          <Typography variant="p" sx={{ fontSize: '24px', fontFamily: 'revert' }}>
            {secondBlock.value}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={closeHandler}
      TransitionComponent={Transition}
      maxWidth={'sm'}
      fullWidth={true}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={closeHandler} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Sales Info
          </Typography>
        </Toolbar>
      </AppBar>
      <Box padding={'24px 12px'}>
        <div className={classes.content}>
          <TextField
            hiddenLabel
            variant="outlined"
            type="date"
            size="normal"
            label="Date"
            value={defaultDate}
            onChange={(e) => {
              setDefaultDate(e.target.value);
            }}
          />
          <Button
            style={{ width: '10em', fontSize: '1em' }}
            variant="contained"
            disabled={isLoading}
            onClick={() => applyInfoClickHandler(defaultDate)}
          >
            Apply
          </Button>
        </div>
        {renderValueBlock(
          { label: 'Total Sales Value', value: `(LKR) ${(total && total.amount) || '00'}` },
          { label: 'No.Of Tickets', value: `${(total && total.count) || '00'}` }
        )}
        {renderValueBlock(
          { label: 'No.Of Locals', value: `${(visitorCount && visitorCount.local) || '00'}` },
          { label: 'No.Of Tourists', value: `${(visitorCount && visitorCount.tourist) || '00'}` }
        )}
        {isLoading && <SpinnerComponent />}
      </Box>
    </Dialog>
  );
}
