import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useAlertStore } from '../../stores';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://ambuluwawa.com/">
        ambuluwawa.com
      </Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

const theme = createTheme();

function ResetPassword() {
  const navigate = useNavigate();

  const [username, setUserName] = useState('');
  const [code, setCode] = useState('');
  const [new_password, setnew_password] = useState('');
  const [sendCode, isSendCode] = useState(false);
  const [verifyCode, isVerifyCode] = useState(false);
  const fnShowAlert = useAlertStore((state) => state.showAlert);
  const forgotPassword = (event) => {
    event.preventDefault();
    Auth.forgotPasswordSubmit(username, code, new_password)
      .then((data) => {
        console.log(data);
        isVerifyCode(true);
        navigate('/login');
      })
      .catch((err) => {
        isSendCode(true);
        isVerifyCode(false);
        console.log(err);
        fnShowAlert({
          message: err.message,
          severity: 'error',
        });
      });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Send confirmation code to user's email
      if (username) {
        Auth.forgotPassword(username)
          .then((data) => {
            console.log(data);
            isSendCode(true);
          })
          .catch((err) => {
            console.log(err);
            fnShowAlert({
              message: err.message,
              severity: 'error',
            });
          });
      } else {
        fnShowAlert({
          message: 'Email/username is required',
          severity: 'error',
        });
      }

      // Collect confirmation code and new password, then
    } catch (error) {
      console.log('error in confirm sign up', error);
    }
  };
  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />

        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography component="h1" variant="h5">
              Forgot Password
            </Typography>
            {!sendCode ? (
              <div>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    autoFocus
                  />

                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Send code
                  </Button>
                </Box>
              </div>
            ) : !verifyCode ? (
              <div>
                <Box component="form" noValidate onSubmit={forgotPassword} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Enter email"
                    name="username"
                    value={username}
                    onChange={(e) => setUserName(e.target.value)}
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="code"
                    label="Enter code"
                    name="code"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    autoFocus
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="password"
                    id="new_password"
                    label="Enter new password"
                    name="new_password"
                    value={new_password}
                    onChange={(e) => setnew_password(e.target.value)}
                    autoFocus
                  />
                  <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                    Reset Password
                  </Button>
                </Box>
              </div>
            ) : (
              <div></div>
            )}
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default ResetPassword;
