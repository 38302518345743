import { useCallback, useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Typography
} from '@mui/material';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import { sendPOSTRequest } from '../../adaptors/bff-requests';
import { useAuth } from '../../context';
import { useAlertStore } from '../../stores';

const useStyles = makeStyles({
  iframeContainer: {
    position: 'relative',
    paddingBottom: '56.25%',
    overflow: 'hidden',
  },
  iframeSize: {
    position: 'absolute',
    margin: 0,
    width: '100%',
    height: '100%',
  },
  background: {
    backgroundColor: '#EEE',
    padding: '4vw',
  },
  main: {
    backgroundColor: 'white',
    boxShadow: '0px 0px 100px 0px rgba(0,0,0,0.15)',
    borderRadius: 25,
    padding: '2rem',
  },
});

const ContactUs = () => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [country, setCountry] = useState(0);
  const [listOfNationalities, setNationalities] = useState([]);
  const [defaultNationality, setDefaultNationality] = useState(0);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const classes = useStyles();
  const { token } = useAuth();
  const fnShowAlert = useAlertStore((state) => state.showAlert);

  const showAlertBox = useCallback(
    (message, type = 'success') => {
      fnShowAlert({
        message: message,
        severity: type,
      });
    },
    [fnShowAlert]
  );

  const handleSubmit = async () => {
    setIsLoading(true);
    const url = `post`;
    const feedback = {
      name,
      phoneNumber,
      message,
      country,
    };
    const response = await sendPOSTRequest({ path: url, payload: feedback, queryParams: '/feedback' }, token);

    if (response.status === 'success') {
      showAlertBox(`Successfully Added!`);
      setIsLoading(false);
      setName('');
      setPhoneNumber('');
      setCountry(defaultNationality);
      setMessage('');
      return true;
    } else {
      showAlertBox(
        response.status === 'warning' ? response.message : `Error Adding Feedback!`,
        response.status === 'warning' ? 'warning' : 'error'
      );
      setIsLoading(false);
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.currentTarget;

    if (name === 'name') {
      setName(value);
    } else if (name === 'phoneNumber') {
      setPhoneNumber(value);
    } else if (name === 'message') {
      setMessage(value);
    }
  };

  const fetchContents = useCallback(async () => {
    const nationalityResp = await sendPOSTRequest({ path: 'nationality/get' }, token);
    if (nationalityResp) {
      const topTenNationalites = nationalityResp.data.topTenCountries || [];
      const nationalities = nationalityResp.data.allCountries || [];
      const topTenCountryIds = topTenNationalites.map(country => country.id);
      const filteredNationalities = nationalities.filter(country => !topTenCountryIds.includes(country.id));
      setNationalities(topTenNationalites.concat(filteredNationalities));
      setDefaultNationality(212);
    }
  }, [token]);

  useEffect(() => {
    fetchContents();
  }, [fetchContents]);

  useEffect(() => {
    setCountry(defaultNationality);
  }, [defaultNationality])

  const isSubmitDisabled = !name || !phoneNumber || !message;

  return (
    <Box className={classes.background}>
      <Box className={classes.main}>
        <Typography
          variant="h2"
          sx={{ fontSize: '4vh', fontFamily: 'revert', textAlign: 'center' }}
        >
          Contact Us
        </Typography>
        <Grid container spacing={12} px="4vw" py="2rem">
          <Grid item xs={12} sm={12} md={8}>
            <Box className={classes.iframeContainer}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3958.693183593499!2d80.54495841477424!3d7.161424894829186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae371f92e975b5d%3A0xa383ec30d0ad2ef6!2sAmbuluwawa%20Biodiversity%20Complex!5e0!3m2!1sen!2ssg!4v1607866704171!5m2!1sen!2ssg"
                title="map"
                className={classes.iframeSize}
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '3vh',
                  fontFamily: 'revert',
                }}
              >
                Address
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: '2vh',
                  fontFamily: 'revert',
                  lineHeight: '3rem',
                }}
              >
                Ambuluwawa Biodiversity Complex,
                <br /> Ambuluwawa,
                <br /> Gampola,
                <br /> Sri Lanka
              </Typography>
            </Box>
            <Box my={2}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '3vh',
                  fontFamily: 'revert',
                }}
              >
                Email
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: '2vh',
                  fontFamily: 'revert',
                  lineHeight: '3rem',
                }}
              >
                info@ambuluwawa.com
              </Typography>
            </Box>
            <Box my={2}>
              <Typography
                variant="h3"
                sx={{
                  fontSize: '3vh',
                  fontFamily: 'revert',
                }}
              >
                Phone
              </Typography>
              <Typography
                variant="p"
                sx={{
                  fontSize: '2vh',
                  fontFamily: 'revert',
                  lineHeight: '3rem',
                }}
              >
                +94 71 272 4724
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography
          variant="h2"
          sx={{ fontSize: '4vh', fontFamily: 'revert', textAlign: 'center' }}
        >
          Feedback & Suggestions
        </Typography>
        <Grid container spacing={3} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item sm={12} md={6}>
            <FormControl fullWidth>
              <div style={{ marginBottom: '10px' }}>
                <Typography fontWeight={600}>Name</Typography>
              </div>
              <TextField
                required
                name="name"
                value={name}
                size="small"
                className="mui-text-field-label-hidden"
                placeholder="Name"
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl fullWidth>
              <div style={{ marginBottom: '10px' }}>
                <Typography fontWeight={600}>Phone Number</Typography>
              </div>
              <TextField
                required
                name="phoneNumber"
                value={phoneNumber}
                size="small"
                type="number"
                inputProps={{ maxLength: 12 }}
                className="mui-text-field-label-hidden"
                placeholder="Phone Number"
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl fullWidth>
              <div style={{ marginBottom: '10px' }}>
                <Typography fontWeight={600}>Country</Typography>
              </div>
              <Select
                value={country}
                onChange={(e) => {
                    setCountry(e.target.value);
                }}
              >
                {listOfNationalities.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={6}>
            <FormControl fullWidth>
              <div style={{ marginBottom: '10px' }}>
                <Typography fontWeight={600}>Message</Typography>
              </div>
              <TextField
                required
                name="message"
                value={message}
                multiline
                rows={4}
                className="mui-text-field-label-hidden"
                placeholder="Message"
                onChange={(e) => handleInputChange(e)}
              />
            </FormControl>
          </Grid>
          <Grid item className={classes.buttonContainer} style={{ marginBottom: '1rem' }}>
            <Button
              variant="contained"
              size="large"
              disabled={isSubmitDisabled || isLoading}
              style={{
                backgroundColor: isSubmitDisabled ? '#7a7a7a' : '#252525',
                width: '150px',
                height: '60px',
                borderRadius: 20,
                fontSize: '1.2rem',
                fontWeight: 700,
                boxShadow: '0px 4px 35px 9px rgba(0, 0, 0, 0.15)',
              }}
              onClick={() => handleSubmit()}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export { ContactUs };
