import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

const Intro = () => {
  return (
    <Box px="8vw" py="2rem">
      <Grid container spacing={12} pt="8em">
        <Grid item xs={12} sm={12} md={6} my="auto">
          <Typography
            variant="h2"
            sx={{
              fontSize: '4vh',
              fontFamily: 'revert',
              paddingBottom: '2rem',
              textAlign: 'center',
            }}
          >
            Ambuluwawa Tower
          </Typography>
          <Typography
            sx={{
              fontSize: '1.8vh',
              fontFamily: 'revert',
              fontWeight: '400',
              textAlign: 'justify',
            }}
          >
            Reaching the top of Ambuluwawa one can experience the most amazing views which could
            sometimes be covered by the ongoing mist. However, once the mist clears off the view can
            be scary and freak out climbers, especially those who have a fear of heights. Do note
            that hiking Ambuluwawa is not at all dangerous or risky, but it would not be the ideal
            kind of adventure for those with a fear of heights. Avoid leaning on the railing to
            capture photographs especially at the top as this is extremely risky and one could
            easily slip off from here. Ambuluwawa is a relatively easy hike to do which gives you
            360 degree views of the surrounding mountains, forests, rivers and towns. It is an area
            richly diverse, with evergreen forests, blossomed flower plants as well creepers and
            about 200 different kinds of plants from 80 plant families including a variety of
            medicinal plants. Ambuluwawa is surrounded by many mountains including Piduruthalagala
            from the East, Bible Rock (Bathalegala) from the West, Sri Pada (Adam’s Peak) from the
            South and Knuckles Mountain Range from the North. Apart from these mountains many other
            mountains are also clearly visible from here and adds to the beauty of the area. These
            are the Hanthana Mountain Range, Hunnasigiri Mountain and mountain ranges associated
            with Algalla and Kadugannawa. There is a cool mountain breeze in the area due to this
            reason. The Ambuluwawa Hill and the adjacent forest is designated as a forest
            reservation which is named as the Ambuluwawa ICC Forest Reservation. The history of the
            Ambuluwawa Temple dates back to the 13th century during the reign of King Buwanekabahu
            IV. It is said that the Ambuluwawa Peak was the center of the Gampola Kingdom during his
            reign. But there is nothing with historical or archaeological values in here.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <img
            width="100%"
            alt={'Introduction'}
            src="https://ambuluwawa-gallery.s3.ap-southeast-1.amazonaws.com/Images/Home/Ambuluwawa.jpg"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export { Intro };
