import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import CustomLineChart from '../shared/line-chart';

const useStyles = makeStyles({
  chartBlock: {
    height: '50vh',
    display: 'flex',
    marginTop: '24px',
    flexDirection: 'column',
    alignItems: 'center',
  },
  chartMargin: {
    top: 12,
    right: 8,
    left: 8,
    bottom: 4,
  },
  chartToolTipParent: {
    padding: '4px',
    background: '#eaeaea',
    borderRadius: '8px',
  },
});

export default function PhotoReport(props) {
  const classes = useStyles();

  const { data } = props;
  const lineChartOptions = {
    margin: {
      top: 20,
      right: 8,
      left: 16,
      bottom: 4,
    },
  };

  const thStyle = {
    background: '#212121',
    fontFamily:'revert',
    padding: '11px',
    color: 'white',
    fontSize: '16px'
  };

  const customTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const element = payload[0];
      const title = element.dataKey === 'amount' ? 'Amount (LKR)' : 'Count';
      return (
        <div className={classes.chartToolTipParent}>
          <p>
            Date: <strong>{label}</strong>
          </p>
          <p>
            {title} : <strong>{element.value}</strong>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <div className={classes.chartBlock}>
        <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
          Total Pre-shoot/Photo-shoot Sales Amount (LKR)
        </Typography>
        <CustomLineChart
          data={data}
          xaxisKey={'issueddate'}
          lines={[
            {
              key: 'amount',
              color: '#FF5733',
            },
          ]}
          lineChart={{ ...lineChartOptions }}
          customToolTip={customTooltip}
        />
      </div>
      <div className={classes.chartBlock}>
        <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert' }}>
          Total Pre-shoot/Photo-shoot Sales Count
        </Typography>
        <CustomLineChart
          data={data}
          xaxisKey={'issueddate'}
          lines={[
            {
              key: 'totalcount',
              color: '#0096FF',
            },
          ]}
          lineChart={{ ...lineChartOptions }}
          customToolTip={customTooltip}
        />
      </div>
      <div style={{ margin: '34px' }}></div>
      <Box sx={{ margin: '14px' }}>
        <Typography variant="h4" sx={{ fontSize: '4vh', fontFamily: 'revert',display: 'flex', justifyContent: 'center' }}>
          Pre-shoot/Photo-shoot Sales Report
        </Typography>
        <TableContainer sx={{ marginTop: '24px' }}>
          <Table >
            <TableHead>
            <TableRow style={thStyle}>
            <TableCell align="left" style={Object.assign({}, thStyle, { borderTopLeftRadius: '10px' }, { color: '#fff' })}>#Serial</TableCell>
            <TableCell align="right" style={Object.assign({}, thStyle, { color: '#fff' })}>Amount</TableCell>
            <TableCell align="right" style={Object.assign({}, thStyle, { borderTopRightRadius: '10px' }, { color: '#fff' })}>Reservation Date</TableCell>
            {/* <TableCell align="right" style={Object.assign({}, thStyle, { borderTopRightRadius: '10px' }, { color: '#fff' })}>Payment Type</TableCell> */}
          </TableRow>
            </TableHead>
            <TableBody>
              {data.map((item, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{index+1}</TableCell>
                  <TableCell align="right">{item.amount}</TableCell>
                  <TableCell align="right">{item.issueddate}</TableCell>
                </TableRow>
              ))}
              
            </TableBody>
          </Table>
        </TableContainer>
    </Box>
    </>
  );
}