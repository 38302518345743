import React, { useState, useEffect } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CardActions from '@mui/material/CardActions';
import CloseIcon from '@mui/icons-material/Close';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Dialog from '@mui/material/Dialog';
import { sendPOSTRequest } from '../../adaptors/bff-requests';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  background: {
    backgroundColor: '#F4FAFC',
    paddingTop: '0.5rem',
    paddingBottom: '2rem',
  },
  main: {
    display: 'flex',
  },
  button: {
    marginTop: '10px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
});

const convertTimeToAMPM = (time24) => {
  let [hours, minutes] = time24.split(':');
  hours = parseInt(hours, 10);
  const suffix = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; 
  return `${hours}:${minutes} ${suffix}`;
};


const DocxGrid = () => {
  const classes = useStyles();
  const [announcements, setAnnouncements] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  useEffect(() => {
    const fetchAnnouncements = async () => {
     const response = await sendPOSTRequest({  path: 'announcement-type-2/get'});
     
      if (response && response.data) {
        setAnnouncements(response.data);
      } else {
        console.error('Failed to fetch announcements');
      }
    };

    fetchAnnouncements();
  }, []);

  const handleLearnMoreClick = (item) => {
    setSelectedItem(item);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedItem({});
  };
 
  return (
    <Box>
      <Box px="2vw" className={classes.background}>
        <Box px="8vw" className={classes.main}>
          <Grid container columnSpacing={2} rowSpacing={2}>
            {announcements.map((item, index) => (
              <Grid item key={index} xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {item.name}
                      <span style={{ fontSize: '12px', fontWeight: '500' }}>
                        {' '}
                        (Published {item.publish_date})
                      </span>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Contract No:</strong> {item.contact_no}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Tender Closing Date:</strong> {item.ttender_closing_date}{' '}
                      <strong>Time:</strong> {convertTimeToAMPM(item.tender_closing_time)}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      <strong>Tender Opening Date: </strong>
                      {item.tender_opening_date} <strong>Time:</strong> {convertTimeToAMPM(item.tender_opening_time)}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth size="small">
                      More Information Available from the Ambuluwawa office
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {showDialog && (
          <Dialog
            fullScreen
            open={true}
            onClose={handleCloseDialog}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'fixed' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  onClick={handleCloseDialog}
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {selectedItem.name ? selectedItem.name : ''}
                </Typography>
              </Toolbar>
            </AppBar>
            <Box py={8} px={2}>
              <Typography variant="body1">Contract No: {selectedItem.contractNo}</Typography>
              <Typography variant="body1">
                Tender Closing Date: {selectedItem.tenderClosingDate} Time{' '}
                {selectedItem.tenderClosingTime}
              </Typography>
              <Typography variant="body1">
                Tender Opening Date: {selectedItem.tenderOpeningDate} Time{' '}
                {selectedItem.tenderOpeningTime}
              </Typography>
              <Typography variant="body1">
                Further Information: {selectedItem.furtherInformation}
              </Typography>
            </Box>
          </Dialog>
        )}
      </Box>
    </Box>
  );
};

export default DocxGrid;
