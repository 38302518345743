import { Typography } from '@mui/material';
import React from 'react';

export default function UsernameChip(props) {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', columnGap: '12px', alignItems: 'center' }}>
        <Typography variant="p">Username : </Typography>
        <Typography variant="h5">{props.username}</Typography>
      </div>
      <div>
        {props.position && (
          <span
            style={{
              padding: '8px 16px',
              borderRadius: '10px',
              background: '#09b250',
              color: 'white',
            }}
          >
            {props.position}
          </span>
        )}
      </div>
    </div>
  );
}
