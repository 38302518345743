import React, { useState , useEffect} from 'react';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Slide,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CardActions from '@mui/material/CardActions';
import CloseIcon from '@mui/icons-material/Close';
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer';
import Dialog from '@mui/material/Dialog';
import { sendPOSTRequest } from '../../adaptors/bff-requests';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  background: {
    backgroundColor: '#F4FAFC',
    paddingTop: '0.5rem',
    paddingBottom: '1rem',
  },
  main: {
    display: 'flex',
  },
  button: {
    marginTop: '10px',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
});

const DocxGridOld = () => {
  const classes = useStyles();
  const [announcements, setAnnouncements] = useState([]);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  useEffect(() => {
    const fetchAnnouncements = async () => {
     const response = await sendPOSTRequest({  path: 'announcement-type-1/get'});
     
      if (response && response.data) {
        setAnnouncements(response.data);
      } else {
        console.error('Failed to fetch announcements');
      }
    };

    fetchAnnouncements();
  }, []);



  const handleLearnMoreClick = (item) => {
    const fileData = [];
    fileData.push({
      uri: item.url, 
      fileName: item.fileName, 
    });
    setSelectedItem(fileData);
    setShowDialog(true);
  };



  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedItem([]);
  };
 
  
  return (
    <Box>
      <Box px="2vw" className={classes.background}>
        <Box px="8vw" py="2vw" className={classes.main}>
          <Grid container columnSpacing={2} rowSpacing={2}>
            {announcements.map((item, index) => (
              <Grid item key={index} xs={12} sm={6}>
                <Card className={classes.card}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {item.title}
                      <span style={{ fontSize: '12px', fontWeight: '500' }}>
                        {' '}
                        (Published {item.publish_date})
                      </span>
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {item.description}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth size="small" onClick={() => handleLearnMoreClick(item)}>
                      Learn More
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>

        {showDialog && (
          <Dialog
            fullScreen
            open={true}
            onClose={handleCloseDialog}
            TransitionComponent={Transition}
          >
            <AppBar sx={{ position: 'fixed' }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="close"
                  onClick={handleCloseDialog}
                >
                  <CloseIcon />
                </IconButton>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                  {selectedItem[0].fileName ? selectedItem[0].fileName : ''}
                </Typography>
              </Toolbar>
            </AppBar>
            <DocViewer
              documents={selectedItem}
              pluginRenderers={DocViewerRenderers}
              style={{ height: 1000 }}
            />
          </Dialog>
        )}
        <hr></hr>
      </Box>
    </Box>
  );
};

export default DocxGridOld;
