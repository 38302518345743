import { PageWrapper } from "../Shared";
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';
import CardActions from '@mui/material/CardActions';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { useState, useEffect } from "react";
import { sendPOSTRequest } from "../../adaptors/bff-requests";
import { Link } from 'react-router-dom';
// Creating use states
const useStyles = makeStyles({
  background: {
    backgroundColor: '#EBFFEE',
    paddingTop: '3rem',
    paddingBottom: '2rem',
  },
  main: {
    display: 'flex',
    flexWrap: 'wrap',
    // overflowX: 'scroll',
  },
});
const PublishNews = () => {
  const classes = useStyles();
  const [allNewsList, setAllNewsList] = useState([]);
  useEffect(() => {
    const getAllNews = async () =>{

      const newsPayload= {
        limit :2
      }
      const newsResopnse = await sendPOSTRequest({
        path: 'latest',payload: newsPayload, queryParams :'/news-event'
      });
      console.log("News Resoponse")
      console.log(newsResopnse);
      const arrayOngoing = newsResopnse.data.news.ongoing;
      const arrayUpcoming = newsResopnse.data.news.upcoming; 
      //setAllNewsList(newsResopnse.data.news.ongoing)
      setAllNewsList(arrayOngoing.concat(arrayUpcoming));
    }
    getAllNews();
   }, []);
  return (
    <PageWrapper>
      <div className="newsAndEvents_Container">
        {/*    ambuluwawa_news      */}
        <div className="ambuluwawa_news">
            <Box pt="6rem">
            <Box px="2rem" className={classes.background}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: '4vh',
                  fontFamily: 'revert',
                  paddingBottom: '1rem',
                  textAlign: 'center',
                }}
              >
                NEWS
              </Typography>
              <Box py="2rem" className={classes.main}>
                {allNewsList.map((item) => (
                  <Box pl="2rem" pt="2rem">
                    <Card sx={{ width: '20rem',}}>
                      <CardMedia component="img" alt={item.name} height="220rem" image={item.images[0].imageUrl} />
                      <CardContent sx={{flex: '1'}}>
                      <Typography variant="h6" color="#1E3932" align="center" backgroundColor="#4db398" marginBottom="1rem">
                            {item.status}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {item.date}
                        </Typography>
                        <Typography gutterBottom variant="h4">
                          {item.title}
                        </Typography>
                       
                        <Typography variant="body2" color="text.secondary">
                        {item.description.length >60
                          ? item.description.substring(0, 60) + '...'
                          : item.description.substring(0, 60)}
                          
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Button size="small" href={'/news/details/' + item.id}>
                            Read More <KeyboardArrowRightOutlinedIcon color="primary" fontSize="small" />
                        </Button>
                      </CardActions>
                    </Card>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    </PageWrapper>
    );
  };
  export { PublishNews };
  