import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const Climate = () => {
  return (
    <Box px="8vw" py="4rem" w="60%">
      <Typography
        variant="h2"
        sx={{
          fontSize: '4vh',
          fontFamily: 'revert',
          paddingTop: '4rem',
          textAlign: 'center',
        }}
      >
        Ambuluwawa Climate
      </Typography>

      <Typography
        py="4rem"
        sx={{
          fontSize: '2vh',
          fontFamily: 'revert',
          fontWeight: '400',
          textAlign: 'center',
        }}
      >
        The climatic alignment of Ambuluawa mountain range is three-fold. The first four seasons of
        the year have normal rain and clear sunshine, then it becomes dry until August. Then until
        the end of December, there is light rain and sunshine that exceeds the normal condition.
      </Typography>
      <Divider />
    </Box>
  );
};

export { Climate };
