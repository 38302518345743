import Footer from '../Footer';
import Header from '../Header';

const PageWrapper = ({ props, children }) => {
  const hideFooter = props && props.hideFooter;
  return (
    <div>
      <Header />
      {children}
      {!hideFooter && <Footer />}
    </div>
  );
};

export { PageWrapper };
